import React from "react";
import { NavLink } from "react-router-dom";
import { TbLogs } from "react-icons/tb";
function AdminSideBar() {
  return (
    <>
      <div
        className={`col-md-3 col-lg-3 bg-dark position-sticky pl-0 border-top admin_sidebar`}
        id="sidebar"
        role="navigation"
        style={{ height: '100vh' }}
      >
        <nav className="nav flex-column sidebar_admin pl-0 mt-3 text-uppercase">
          <NavLink to="/admin/all-donors" className={({ isActive }) => `d-flex active_link nav-link text-light ${isActive ? "active" : ""}`}>
            <i className="fa-solid fa-list px-2 pt-1"></i>
            <span>Donor List</span>
          </NavLink>
          <NavLink to="/admin/allbloodrequest" className={({ isActive }) => `d-flex active_link nav-link text-light ${isActive ? "active" : ""}`}>
            <i className="fa-solid fa-droplet px-2 pt-1"></i>
            <span>Blood Requirement List</span>
          </NavLink>
          <NavLink to="/admin/all-volunteers" className={({ isActive }) => `d-flex active_link nav-link text-light ${isActive ? "active" : ""}`} >
            <i className="fa-solid fa-address-card px-2 pt-1"></i>
            <span>Volunteer list</span>
          </NavLink>
          <NavLink to="/admin/all-SkilledPersons" className={({ isActive }) => `d-flex active_link nav-link text-light ${isActive ? "active" : ""}`} >
            <i className="fas fa-graduation-cap px-2 pt-1"></i>
            <span>Skilled Persons</span>
          </NavLink>
          <NavLink to="/admin/heroes" className={({ isActive }) => `d-flex active_link nav-link text-light ${isActive ? "active" : ""}`} >
            <i className="fa-sharp fa-solid fa-star-half-stroke px-2 pt-1"></i>
            <span> Nangal Heroes</span>
          </NavLink>
          <NavLink to="/admin/all-events" className={({ isActive }) => `d-flex active_link nav-link text-light ${isActive ? "active" : ""}`} >
            <i className="fa-solid fa-list px-2 pt-1"></i>
            <span>Events List</span>
          </NavLink>
          <NavLink to="/admin/request-event" className={({ isActive }) => `d-flex active_link nav-link text-light ${isActive ? "active" : ""}`} >
            <i className="fa-solid fas fa-tasks px-2 pt-1"></i>
            <span>Pending Events Request</span>
          </NavLink>
          <NavLink to="/admin/allParticipantslist" className={({ isActive }) => `d-flex active_link nav-link text-light ${isActive ? "active" : ""}`} >
            <i className="fa-solid fas fa-users px-2 pt-1"></i>
            <span>Event Participants</span>
          </NavLink>
          <NavLink to="/admin/all-blogs" className={({ isActive }) => `d-flex active_link nav-link text-light ${isActive ? "active" : ""}`} >
            <i className="fa-solid  fa-pen px-2 pt-1"></i>
            <span>Blogs</span>
          </NavLink>
          <NavLink to="/admin/news" className={({ isActive }) => `d-flex active_link nav-link text-light ${isActive ? "active" : ""}`} >
            <i className="fa-solid  fa-newspaper px-2 pt-1"></i>
            <span>News</span>
          </NavLink>
          <NavLink to="/admin/gallery" className={({ isActive }) => `d-flex active_link nav-link text-light ${isActive ? "active" : ""}`} >
            <i className="fa-solid fa-image px-2 pt-1"></i>
            <span>Gallery</span>
          </NavLink>
          <NavLink to="/admin/contact-requests" className={({ isActive }) => `d-flex active_link nav-link text-light ${isActive ? "active" : ""}`} >
            <i className="fa-solid fa-exchange-alt px-2 py-1"></i>
            <span>Contact Requests</span>
          </NavLink>
          <NavLink to="/admin/faqs" className={({ isActive }) => `nav-link text-light ${isActive ? "active" : ""}`} >
            <i className="fa-solid fa-question-circle px-2"></i>
            <span>Faq</span>
          </NavLink>
          <NavLink to="/admin/audit-logs" className={({ isActive }) => `nav-link text-light ${isActive ? "active" : ""}`} >
            <TbLogs />
            <span>{" "}Audit Logs</span>
          </NavLink>
          <NavLink to="/admin/allfeedbackslist" className={({ isActive }) => `d-flex active_link nav-link text-light ${isActive ? "active" : ""}`} >
            <i className="fa-solid fa-comments px-2 py-1"></i>
            <span>FeedBacks</span>
          </NavLink>
        </nav>
      </div >
    </>
  );
}

export default AdminSideBar;
