import React from "react";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import { Link } from "react-router-dom";
import genericImg from "../../../../Assets/Images/Generic-img.png";
import moment from "moment";

const DonorModalView = (props) => {
  const formatDate = (date) => moment(date).format("YYYY-MM-DD");

  return (
    <>
      <Modal
        isOpen={props.isDonorViewModalOpen}
        toggle={() => props.toggleDonorViewModal()}
      >
        <ModalHeader toggle={() => props.toggleDonorViewModal()}>
          Donor View
        </ModalHeader>

        <ModalBody>
          <div className="card p-5">
            <div className="sidebar">
              <aside className="widget author_widget">
                <div className="widget-content text-center">
                  <div className="profile">
                    <figure className="avatar">
                      <Link to="#">
                        <img
                          src={
                            props.selectedDonor?.userProfile
                              ? props.selectedDonor.userProfile
                              : genericImg
                          }
                          alt=""
                        />
                      </Link>
                    </figure>
                    <div className="text-content">
                      <div className="name-title">
                        <h3>
                          <Link to="#">{props.selectedDonor?.name}</Link>
                        </h3>
                      </div>
                      <p>
                        Blood-Type: {props.selectedDonor?.bloodType}
                      </p>
                      <p>
                        contact: {props.selectedDonor?.mobile}
                      </p>
                      <p>
                        Date of Birth: {formatDate(props.selectedDonor?.dob)}
                      </p>
                      <p>
                        Address: {props.selectedDonor?.addressLine1}
                      </p>
                    </div>
                  </div>
                </div>
              </aside>
            </div>
          </div>
        </ModalBody>
      </Modal>
    </>
  );
};

export default DonorModalView;
