import React, { useEffect } from 'react';
import innerBannerImg1 from "../Assets//Images/Event-bg-01-01.jpg";
import { Helmet } from 'react-helmet';
import { useNavigate } from 'react-router-dom';
import { teamMembers } from '../constants';

const Team = () => {
    let navigate = useNavigate();
    const truncateText = (text, maxLength) => {
        return text.length > maxLength ? text.substring(0, maxLength) + '...' : text;
    };

    useEffect(() => {
        let initialState = {};
        document.querySelectorAll('.team_information').forEach((_, index) => {
            initialState[index] = false;
        });
    }, []);

    const members = (id) => {
        let selectedMember = teamMembers.find((member) => member.id === id);
        if (selectedMember) {
            navigate(`/member-details/${id}`, { state: { member: selectedMember } });
        }
    };

    return (
        <main id="content" className="site-main">
            <Helmet>
                <title>Meet Our Expert Team | Passionate Cyclists & Guides</title>
                <meta name="description" content="Discover Nangal By Cycle’s dedicated team of expert cyclists and guides. Our passionate professionals ensure memorable cycling adventures, showcasing the beauty and culture of Nangal. Join us for an unforgettable ride!" />
            </Helmet>
            <section className="inner-banner-wrap" >
                <div
                    className="inner-baner-container"
                    style={{ backgroundImage: `url(${innerBannerImg1})` }}
                >
                    <div className="container">
                        <div className="inner-banner-content">
                            <h1 className="inner-title">Our Team</h1>
                        </div>
                    </div>
                </div>
            </section>
            <section className="our-team mb-5">
                <div className="container">
                    <div className="founder-inner">
                        <div className="row">
                            {teamMembers.map((member, index) => (
                                <div key={index} className="col-lg-4 mb-4">
                                    <article className="event-item event-item-bg founder_box m-0"
                                        style={{
                                            background: 'rgb(54, 79, 117)',
                                            minHeight: 550
                                        }}
                                    >
                                        <div className="founder-content">
                                            <figure className="founder-img circle">
                                                <img src={member?.image} alt={member?.name} />
                                                <svg viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
                                                    <circle cx="50" cy="50" r="40"></circle>
                                                </svg>
                                            </figure>
                                            <div className="text-content text-center">
                                                <div className="name-title">
                                                    <h3 className="mb-0">
                                                        <a href="/">{member?.name}</a>
                                                    </h3>
                                                    <h5 className="mt-2 mb-3">
                                                        <a href="/">{member?.role}</a>
                                                    </h5>
                                                </div>
                                                <p className='team_information'>
                                                    {member?.name === 'Rohit Garg' || member?.name === "Lakshya Garg" || member?.name === "Lavanya Garg" ?
                                                        member?.description
                                                        :
                                                        truncateText(member?.description, 215)}
                                                </p>
                                            </div>
                                            <div className='text-center'>
                                                <button onClick={() => members(member?.id)} className='button-round'>View Details</button>
                                            </div>
                                        </div>
                                        <div className="overlay"></div>
                                    </article>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </section >
        </main >
    );
};

export default Team;