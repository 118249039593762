import React, { useEffect, useState } from "react";
import innerBannerImg1 from "../../Assets/Images/Event-bg-01-01.jpg";
import Slider from "react-slick";
import { useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import Loader from "../../Components/Loader";
import moment from "moment";
import genericImg from "../../Assets/Images/Generic-img.png";
// import * as yup from "yup";
// import { yupResolver } from "@hookform/resolvers/yup";
// import { Controller, useForm } from "react-hook-form";
import { getNewsById } from "../../Slice/news";
// import anacondaAvt from '../../Assets/Images/avatars/anaconda.png';
// import bearAvt from '../../Assets/Images/avatars/bear.png';
// import buddyAvt from '../../Assets/Images/avatars/buddy.png';
// import cowAvt from '../../Assets/Images/avatars/cow.png';
// import dinosaurAvt from '../../Assets/Images/avatars/dinosaur.png';
// import jaguarAvt from '../../Assets/Images/avatars/jaguar.png';
// import pandaAvt from '../../Assets/Images/avatars/panda.png';
// import pelicanAvt from '../../Assets/Images/avatars/pelican.png';
// import porkAvt from '../../Assets/Images/avatars/pork.png';
// import turtleAvt from '../../Assets/Images/avatars/turtle.png';
import { Helmet } from "react-helmet";
import { numberToString, stringToNumber } from "../../utils/encrypt";
import { rewriteUrl } from "../../Components/constant/Constant";
// import { FacebookIcon, FacebookShareButton, LinkedinIcon, LinkedinShareButton, PinterestIcon, PinterestShareButton, TwitterIcon, TwitterShareButton } from "react-share";

// const schema = yup
//   .object({
//     comment: yup.string().required("comment is required").trim(),
//     name: yup.string().required("Name is required").trim(),
//     email: yup.string().required("Email is required").trim(),
//   })
//   .required();

// const avatarList = [
//   anacondaAvt, bearAvt, buddyAvt, cowAvt, dinosaurAvt, jaguarAvt, pandaAvt, pelicanAvt, porkAvt, turtleAvt];

function NewsSingle() {
  const dispatch = useDispatch();
  const { title } = useParams();
  const lastIndex = title.lastIndexOf('-');
  const newsId = title.substring(lastIndex + 1);
  const intID = stringToNumber(newsId);
//   const [replying, setReplying] = useState(false);
  const [imageUrls, setImageUrls] = useState([0]);
  const [textContent, setTextContent] = useState("");
//   const [commentParentId, setCommentParentId] = useState(null);
  const { news, newsList, isLoading } = useSelector((state) => state.news);

  useEffect(() => {
    dispatch(getNewsById(intID));
  }, []);

  useEffect(() => {
    if (news && news?.content) {
      const content = news?.content?.replace(/<img[^>]*>/g, '');
      setTextContent(content);
      const parser = new DOMParser();
      const htmlDoc = parser.parseFromString(news?.content, "text/html");
      const imageElements = htmlDoc.querySelectorAll("img");
      const urls = Array.from(imageElements)?.map((img) => img.src);

      let imageUrls = urls;
      if (news.thumbnail_url != null) {
        imageUrls = [
            news.thumbnail_url,
          ...urls,
        ];
      }
      setImageUrls(imageUrls);
    }
  }, [news]);

//   useEffect(() => {
//     if (news?.news_id) {
//       dispatch(getAllNewsComments(news.news_id));
//     }
//   }, [dispatch, news]);

  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false,
    autoplaySpeed: 3000,
  };

//   const {
//     handleSubmit,
//     control,
//     reset,
//     formState: { errors },
//   } = useForm({
//     resolver: yupResolver(schema),
//   });

  const formatDate = (day) => moment(day).format("MMMM DD, YYYY");

//   const getRandomAvatar = () => {
//     const randomIndex = Math.floor(Math.random() * avatarList.length);
//     return avatarList[randomIndex];
//   };

//   const handleReplyClick = (CommentId) => {
//     setReplying(true);
//     setCommentParentId(CommentId);
//     document.getElementById("replyForm").scrollIntoView({ behavior: "smooth" });
//   };

//   const handleReplyCancel = () => {
//     setCommentParentId(null);
//     setReplying(false);
//   }

//   const onSubmit = (data) => {
//     const commentData = {
//       content: data?.comment,
//       newsId: news?.news_id,
//       parentId: commentParentId || null,
//       authorName: data?.name,
//       authorEmail: data?.email
//     };
//     dispatch(addComment(commentData, reset, setCommentParentId));
//   };

//   const renderComments = (comments) => {
//     const commentMap = {};
//     comments.forEach(comment => {
//       commentMap[comment.comment_id] = { ...comment, replies: [] };
//     });
//     const commentTree = [];
//     comments.forEach(comment => {
//       if (comment.parent_id === null) {
//         commentTree.push(commentMap[comment.comment_id]);
//       } else {
//         commentMap[comment.parent_id].replies.push(commentMap[comment.comment_id]);
//       }
//     });

//     return (
//       <div className="comment-area-inner">
//         <ol>
//           {commentTree.map(comment => (
//             <li key={comment.comment_id}>
//               <figure className="comment-thumb">
//                 <img src={getRandomAvatar()} alt="Avatar" />
//               </figure>
//               <div className="comment-content mb-3">
//                 <div className="comment-header">
//                   <h5 className="author-name">{comment.author_name}</h5>
//                   <span className="post-on">{formatDate(comment.created_at)}</span>
//                 </div>
//                 <p>{comment.content}</p>
//                 {/* <button
//                   className="btn reply"
//                   onClick={() => handleReplyClick(comment.comment_id)}
//                 >
//                   <i className="fas fa-reply"></i>Reply
//                 </button> */}
//               </div>
//               {comment.replies.length > 0 && (
//                 <ol>
//                   {comment.replies.map(reply => (
//                     <li key={reply.comment_id}>
//                       <figure className="comment-thumb">
//                         <img src={getRandomAvatar()} alt="Avatar" />
//                       </figure>
//                       <div className="comment-content">
//                         <div className="comment-header">
//                           <h5 className="author-name">{reply.author_name}</h5>
//                           <span className="post-on">{formatDate(reply.created_at)}</span>
//                         </div>
//                         <p>{reply.content}</p>
//                         {/* <button
//                           className="btn reply"
//                           onClick={() => handleReplyClick(comment.comment_id)}
//                         >
//                           <i className="fas fa-reply"></i>Reply
//                         </button> */}
//                       </div>
//                     </li>
//                   ))}
//                 </ol>
//               )}
//             </li>
//           ))}
//         </ol>
//       </div>
//     );
//   }

//   const currentUrl = encodeURIComponent(window.location.href);
//   const newsTitle = encodeURIComponent(news?.title || "");
//   const newsDescription = encodeURIComponent(textContent.substring(0, 200));

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <main id="content" className="site-main">

          <Helmet>
            <title>{title} | Nangal by Cycle News</title>
            <meta name="description" content="Dive into the vibrant and diverse rural culture of India. Our latest news post explores traditions, lifestyles, and stories from the heart of rural India." />
          </Helmet>
          <section className="inner-banner-wrap">
            <div
              className="inner-baner-container"
              style={{ backgroundImage: `url(${innerBannerImg1})` }}
            >
              <div className="container">
                <div className="inner-banner-content">
                  {news ? (
                    <>
                      <h1 className="inner-title">{news?.title}</h1>
                      <div className="entry-meta" style={{ color: '#151515bf' }}>
                        <span className="byline">
                          <Link style={{ color: '#151515bf' }}  >{news?.author_name}</Link>
                        </span>
                        <span className="posted-on">
                          <Link style={{ color: '#151515bf' }}>{formatDate(news?.publish_date)}</Link>
                        </span>
                      </div>
                    </>
                  ) : null}
                </div>
              </div>
            </div>
          </section>
          <div className="single-post-section">
            <div className="single-post-inner">
              <div className="container">
                <div className="row">
                  <div className="col-lg-8 primary right-sidebar">
                    {imageUrls && imageUrls.length > 0 && (
                      <Slider {...settings}>
                        {imageUrls?.map((imageUrl, index) => (
                          <div key={index}>
                            <img src={imageUrl} alt={`Imag ${index}`} />
                          </div>
                        ))}
                      </Slider>
                    )}
                    <article className="single-text-content">
                      <div className="react_quill_editor" dangerouslySetInnerHTML={{ __html: textContent }} />
                    </article>
                    {/* <div className="post-socail-wrap mt-3">
                      <div className="social-icon-wrap">
                        <div className="social-icon social-facebook">
                          <FacebookShareButton
                            className="facebook_btn"
                            description={newsTitle}
                            media={imageUrls[0]}
                            url={`https://nangalbycycle.com/news/${newsTitle}`}
                          >  <i className="fab fa-facebook"></i>
                            <span>Facebook</span>
                          </FacebookShareButton>
                        </div>

                         <LinkedinShareButton url={currentUrl} title={newsTitle}>
                            <LinkedinIcon size={36} round />
                          </LinkedinShareButton> 

                         <PinterestShareButton url={currentUrl} media={imageUrls[0]} description={newsTitle}>
                            <PinterestIcon size={36} round />
                          </PinterestShareButton> 

                         <FacebookShareButton
                          url={currentUrl}
                          hashtag="#cyclecommunity"
                          quote={"Quote"}
                          description={"desc"}
                        >
                          <FacebookIcon size={36} round />
                        </FacebookShareButton> 
                        <div className="social-icon social-pinterest">
                          <PinterestShareButton
                            className="pinterest_btn"
                            description={newsTitle}
                            media={imageUrls[0]}
                            url={`https://nangalbycycle.com/news/${newsTitle}`}
                          >  <i className="fab fa-pinterest"></i>
                            <span>Pinterest</span>
                          </PinterestShareButton>
                        </div>
                        <div className="social-icon social-linkedin">
                          <LinkedinShareButton
                            className="linkedin_btn"
                            title={news?.title}
                            summary={"test"}
                            source={"test2"}
                            url={`https://nangalbycycle.com/news/${newsTitle}`}
                          >
                            <i className="fab fa-linkedin"></i>
                            <span>Linkedin</span>
                          </LinkedinShareButton>
                        </div>
                        <div className="social-icon social-twitter">
                          <TwitterShareButton
                            className="twitter_btn"
                            title={news?.title}
                            url={`https://nangalbycycle.com/news/${newsTitle}`}
                          >
                            <i className="fab fa-x-twitter"></i>
                            <span>Twitter</span>
                          </TwitterShareButton>
                        </div>

                      </div>
                    </div> */}
                    {/* <div id="commentArea" className="comment-area">
                      {allComments.length > 0 && (
                        <h2 className="comment-title">
                          {allComments.length} Comments
                        </h2>
                      )}
                      {renderComments(allComments)}
                      <div id="content">
                        <div id="replyForm" className="comment-form-wrap volunteer-form">
                          <h2 className="comment-title">
                            {replying ? "Leave a Reply" : "Add a Comment"}
                          </h2>
                          <p>
                            Your email address will not be published. Required
                            fields are marked{" "}
                            <span style={{ color: "red" }}>*</span>
                          </p> */}
                          {/* <form
                            className="comment-form"
                            onSubmit={handleSubmit(onSubmit)}
                          >
                            <p className="form-group">
                              <label>
                                Comment <span style={{ color: "red" }}>*</span>
                              </label>
                              <Controller
                                name="comment"
                                control={control}
                                render={({ field: { value, onChange } }) => (
                                  <textarea
                                    className="form-group input_fixed_width"
                                    type="text"
                                    value={value}
                                    onChange={onChange}
                                    rows={4}
                                  />
                                )}
                                defaultValue=""
                              />
                              {errors?.comment && (
                                <p style={{ color: "red" }}>
                                  {errors?.comment?.message}
                                </p>
                              )}
                            </p>
                            <p>
                              <label>
                                Name <span style={{ color: "red" }}>*</span>
                              </label>
                              <Controller
                                name="name"
                                control={control}
                                render={({ field: { value, onChange } }) => (
                                  <input
                                    className="form-group input_fixed_width"
                                    type="text"
                                    value={value}
                                    onChange={onChange}
                                  />
                                )}
                                defaultValue=""
                              />
                              {errors?.name && (
                                <p style={{ color: "red" }}>
                                  {errors?.name?.message}
                                </p>
                              )}
                            </p>
                            <p>
                              <label>
                                Email <span style={{ color: "red" }}>*</span>
                              </label>
                              <Controller
                                name="email"
                                control={control}
                                render={({ field: { value, onChange } }) => (
                                  <input
                                    className="form-group input_fixed_width"
                                    type="email"
                                    value={value}
                                    onChange={onChange}
                                  />
                                )}
                                defaultValue=""
                              />
                              {errors?.email && (
                                <p style={{ color: "red" }}>
                                  {errors?.email?.message}
                                </p>
                              )}
                            </p>
                            <p className="full-width">
                              {replying && (
                                <button
                                  className="btn btn-warning text-white me-2"
                                  style={{ fontWeight: 600, padding: '12px 30px', borderRadius: 25 }}
                                  type="button"
                                  name="cancel"
                                  onClick={handleReplyCancel}
                                >
                                  Cancel
                                </button>
                              )}
                              <input
                                type="submit"
                                name="submit"
                                value={replying ? "Post Reply" : "Post comment"}
                              />
                            </p>
                          </form> */}
                        {/* </div>
                      </div>
                    </div> */}
                  </div>
                  <div className="col-lg-4 secondary">
                    <div className="sidebar">
                      {news && (
                        <aside className="widget author_widget">
                          <h3 className="widget-title">ABOUT AUTHOR</h3>
                          <div className="widget-content text-center">
                            <div className="profile">
                              <figure className="avatar">
                                <Link to="#">
                                  <img
                                    src={
                                      news?.author_profile_url
                                        ? news.author_profile_url
                                        : genericImg
                                    }
                                    alt="unboundImg12"
                                  />
                                </Link>
                              </figure>
                              <div className="text-content">
                                <div className="name-title">
                                  <h3>
                                    <p>{news?.author_name}</p>
                                  </h3>
                                  <p>{news?.profession}</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </aside>
                      )}
                      {newsList && newsList.length > 0 && (
                        <aside className="widget widget_latest_post widget-post-thumb">
                          <h3 className="widget-title">Recent Post</h3>
                          <ul>
                            {newsList?.filter(news => news.title !== title)?.slice(0, 4)?.map((element) => (
                              <li key={element.news_id}>
                                {element?.thumbnail_url && (
                                  <figure
                                    className="post-thumb"
                                    style={{
                                      maxWidth: "100px",
                                      maxHeight: "67px",
                                      overflow: "hidden",
                                    }}
                                  >
                                    <img
                                      src={element?.thumbnail_url}
                                      alt="unboundImg2"
                                    />
                                  </figure>
                                )}
                                <div className="post-content">
                                  <h5>
                                    <Link to={`/news/${rewriteUrl(element?.title)}-${numberToString(element?.news_id)}`}>
                                      {element?.title}
                                    </Link>
                                  </h5>
                                  <div className="entry-meta">
                                    <span className="posted-on">
                                      <Link
                                        to={`/news/${rewriteUrl(element?.title)}-${numberToString(element?.news_id)}`}
                                      >
                                        {formatDate(element?.publish_date)}
                                      </Link>
                                    </span>
                                  </div>
                                </div>
                              </li>
                            ))}
                          </ul>
                        </aside>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main >
      )
      }
    </>
  );
}

export default NewsSingle;
