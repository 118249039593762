import React, { useEffect, useState } from 'react';
import innerBannerImg1 from "./../Assets/Images/Event-bg-01-01.jpg";
import { useSelector } from "react-redux";
import Loader from '../Components/Loader';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { teamMembers } from '../constants';

function TeamDetails() {
        const location = useLocation();
        const navigate = useNavigate();
        const { id } = useParams();
        const [member, setMember] = useState(null);
        const { isLoading } = useSelector((state) => state.user);

        useEffect(() => {
                if (location.state && location.state.member) {
                        setMember(location.state.member);
                } else {
                        const foundMember = teamMembers.find((member) => member.id === id);
                        if (foundMember) {
                                setMember(foundMember);
                        } else {
                                navigate('/team');
                        }
                }
        }, [location.state, id, navigate]);

        return (
                <>
                        {isLoading ? (
                                <Loader />
                        ) : (
                                <main id="content" className="site-main">
                                        <section className="inner-banner-wrap">
                                                <div className="inner-baner-container" style={{ backgroundImage: `url(${innerBannerImg1})` }}>
                                                        <div className="container">
                                                                <div className="inner-banner-content">
                                                                        <h1 className="inner-title">Team Details</h1>
                                                                </div>
                                                        </div>
                                                </div>
                                        </section>
                                        <section className="py-3 py-md-5 py-xl-2">
                                                <div className="container">
                                                        <div className="row gy-4 gy-lg-0">
                                                                <div className="col-12 col-lg-4 col-xl-3">
                                                                        <div className="row gy-4">
                                                                                <div className="col-12">
                                                                                        <div className="card widget-card border-light shadow-sm">
                                                                                                <div className="card-body">
                                                                                                        <div className="text-center mb-3">
                                                                                                                <img
                                                                                                                        src={member?.image}
                                                                                                                        alt={member?.name}
                                                                                                                        className="rounded-circle img-fluid img-thumbnail avatar-img"
                                                                                                                />
                                                                                                        </div>
                                                                                                        <h3 className="text-center mb-1">{member?.name}</h3>
                                                                                                        <p className="text-center text-secondary mb-4">{member?.role}</p>
                                                                                                        <div className="socialgroup text-center">
                                                                                                                {member?.social_icon && member?.social_link && (
                                                                                                                        <ul className="p-0">
                                                                                                                                {member?.social_link.map((link, i) => (
                                                                                                                                        <li key={i}>
                                                                                                                                                <a
                                                                                                                                                        target="_blank"
                                                                                                                                                        href={link}
                                                                                                                                                        rel="noreferrer"
                                                                                                                                                >
                                                                                                                                                        <i className={member?.social_icon[i]}></i>
                                                                                                                                                </a>
                                                                                                                                        </li>
                                                                                                                                ))}
                                                                                                                        </ul>
                                                                                                                )}
                                                                                                        </div>
                                                                                                </div>
                                                                                        </div>
                                                                                </div>
                                                                        </div>
                                                                </div>
                                                                <div className="col-12 col-lg-8 col-xl-9">
                                                                        <div className="card widget-card border-light shadow-sm p-4">
                                                                                <article className="single-text-content">
                                                                                        <div className="react_quill_editor" dangerouslySetInnerHTML={{ __html: member?.long_description }} />
                                                                                </article>
                                                                        </div>
                                                                </div>
                                                        </div>
                                                </div>
                                        </section>
                                </main>
                        )}
                </>
        );
};

export default TeamDetails;
