import React from 'react'
import Slider from 'react-slick';
import logo2 from '../../Assets/Images/Logo-6.png';
import logo3 from '../../Assets/Images/Logo-5.png';

const settings = {
    dots: false,
    infinite: true,
    speed: 2000,
    arrows: false,
    slidesToShow: 2,
    autoplay: true,
    responsive: [
        { breakpoint: 768, settings: { slidesToShow: 2 } },
        { breakpoint: 479, settings: { slidesToShow: 2 } },
    ],
};

function ClientSlider() {
    return (
        <>
            <Slider {...settings}>
                <div className="client-item">
                    <figure>
                        <img src={logo2} alt="logo1" />
                    </figure>
                </div>
                <div className="client-item">
                    <figure>
                        <img src={logo3} alt="logo2" />
                    </figure>
                </div>
            </Slider>
        </>
    )
}

export default ClientSlider