import React, { useEffect } from "react";
import innerBannerImg1 from '../../Assets/Images/Event-bg-01-01.jpg';
import registerimg from '../../Assets/Images/registerimg.jpg';
import donor_header_img from '../../Assets/Images/donor-header-img.jpeg'
import volunteer_header_img from '../../Assets/Images/Volunteers-working-together-1.jpg'
import both_header_img from '../../Assets/Images/photo.jpg'
import { Link, useLocation } from "react-router-dom";
import AddDonor from "./Components/AddDonor";
import AddVolunteer from "./Components/AddVolunteer";
import { useDispatch, useSelector } from "react-redux";
import { updateRegisterRoleDonor, updateRegisterRoleVolunteer, updateRegisterRoleBoth } from "../../Slice/master";
import { RegisterRoles } from "../../constants";
import RegisterAsBoth from "./Components/RegisterAsBoth";
import { Helmet } from "react-helmet";

const Register = () => {
  const dispatch = useDispatch();
  const { registerRoleCheck } = useSelector(state => state.masterSlice);
  const location = useLocation();
  const role = location?.state?.role;

  useEffect(() => {
    if (role === 'donor') {
      dispatch(updateRegisterRoleDonor());
    } else if (role === 'volunteer') {
      dispatch(updateRegisterRoleVolunteer());
    }
  }, [role, dispatch])

  return (
    <>
      <main id="content" className="site-main">
        <Helmet>
          <title>Register | Join Nangal by Cycle Community</title>
          <meta name="description" content="Create an account with Nangal by Cycle to participate in our events, access exclusive content, and support our mission. Join our community today." />
        </Helmet>
        <section className="inner-banner-wrap pb-0">
          <div className="inner-baner-container"
            style={{ backgroundImage: `url(${innerBannerImg1})` }}
          >
            <div className="container">
              <div className="inner-banner-content">
                <h1 className="inner-title">Register Yourself</h1>
              </div>
            </div>
          </div>
        </section>
        <div className="volunteer-wrap" style={{ backgroundImage: `url(${registerimg})` }}>
          <div className="container">
            <div className="row pt-5">
              <div className="col-lg-8 offset-lg-2">
                <div className="volunteer-contact-form form_padding_rm">
                  <div className="form_header_img position-relative">
                    <img src={registerRoleCheck === RegisterRoles?.Donor ? donor_header_img : registerRoleCheck === RegisterRoles?.Volunteer ? volunteer_header_img : both_header_img} alt="" />
                    <div className="position-absolute header_img_title">
                      <h1 className="main_title text-center">Register As <br />
                        {registerRoleCheck === RegisterRoles?.Donor ? 'DONOR' : registerRoleCheck === RegisterRoles?.Volunteer ? 'VOLUNTEER' : registerRoleCheck === RegisterRoles?.Both ? 'BOTH' : ''}
                      </h1>
                    </div>
                  </div>
                  <div className="form-group header_form_radio mt-5 mb-4 d-flex justify-content-center">
                    <div className="header_wrapper">
                      <div className="form-check form-check-inline no-margin" style={{ background: registerRoleCheck === RegisterRoles?.Donor ? '#F15B43' : "white" }}>
                        <input
                          className="form-check-input"
                          type="radio"
                          name="userType"
                          id="donor"
                          style={{ display: 'none' }}
                          value={RegisterRoles?.Donor}
                          checked={registerRoleCheck === RegisterRoles?.Donor}
                          onChange={(e) => dispatch(updateRegisterRoleDonor())}
                        />
                        <label className="form-check-label" htmlFor="donor" style={{ color: registerRoleCheck === RegisterRoles?.Donor ? '' : 'black' }}>
                          Donor
                        </label>
                      </div>
                      <div className="form-check form-check-inline no-margin" style={{ background: registerRoleCheck === RegisterRoles?.Volunteer ? '#F15B43' : 'white' }} >
                        <input
                          className="form-check-input"
                          type="radio"
                          name="userType"
                          style={{ display: 'none' }}
                          id="volunteer"
                          value={RegisterRoles?.Volunteer}
                          checked={registerRoleCheck === RegisterRoles?.Volunteer}
                          onChange={(e) => dispatch(updateRegisterRoleVolunteer())}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="volunteer"
                          style={{ color: registerRoleCheck === RegisterRoles.Volunteer ? '' : 'black' }}
                        >
                          Volunteer
                        </label>
                      </div>
                      <div className="form-check form-check-inline no-margin" style={{ background: registerRoleCheck === RegisterRoles?.Both ? '#F15B43' : 'white' }}>
                        <input
                          className="form-check-input"
                          type="radio"
                          name="userType"
                          style={{ display: 'none' }}
                          id="registerBoth"
                          value={RegisterRoles?.Both}
                          checked={registerRoleCheck === RegisterRoles?.Both}
                          onChange={(e) => dispatch(updateRegisterRoleBoth())}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="registerBoth"
                          style={{ color: registerRoleCheck === RegisterRoles?.Both ? '' : 'black' }}
                        >
                          As Both
                        </label>
                      </div>
                    </div>
                  </div>
                  {registerRoleCheck === RegisterRoles?.Donor ? < AddDonor /> : registerRoleCheck === RegisterRoles?.Volunteer ? <AddVolunteer /> : registerRoleCheck === RegisterRoles?.Both ? <RegisterAsBoth /> : ""}
                  <p className="py-4">
                    Already have an account?
                    <Link to="/auth/login" style={{ color: '#F15B43' }}>Sign in</Link>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
};

export default Register;