import React from "react";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import { Link } from "react-router-dom";
import genericImg from "../../../Assets/Images/Generic-img.png";

const EventModelView = (props) => {
  // Helper function to sanitize HTML content (use a library like DOMPurify in real-world scenarios)
  const createMarkup = (html) => {
    return { __html: html };
  };

  return (
    <>
      <Modal
        isOpen={props.isEventViewModalOpen}
        toggle={() => props.toggleEventViewModal()}
        size="lg"
      >
        <ModalHeader toggle={() => props.toggleEventViewModal()}>
          Event View
        </ModalHeader>
        <ModalBody>
          <div className="card p-5">
            <div className="text-center mb-4">
              <figure className="avatar mb-4">
                <Link to="#">
                  <img
                    style={{
                      borderRadius: '5px',
                      width: '100%',
                      maxWidth: '400px',
                      height: 'auto',
                      objectFit: 'cover',
                      boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                    }}
                    src={props.selectedEvent?.imageUrl ? props.selectedEvent.imageUrl : genericImg}
                    alt=""
                  />
                </Link>
              </figure>
            </div>

            <div className="text-content">
              <h3 className="mb-3">
                <Link to="#" style={{ textDecoration: 'none', color: '#333' }}>
                  {props.selectedEvent?.name}
                </Link>
              </h3>

              <div className="mb-4">
                <div className="d-flex justify-content-between mb-2">
                  <strong>Organiser Name:</strong>
                  <span>{props.selectedEvent?.organiser}</span>
                </div>
                <div className="d-flex justify-content-between mb-2">
                  <strong>Location:</strong>
                  <span>{props.selectedEvent?.location}</span>
                </div>
                <div className="d-flex justify-content-between mb-2">
                  <strong>Event Start Date/Time:</strong>
                  <span>{props.selectedEvent?.startDateTime}</span>
                </div>
                <div className="d-flex justify-content-between mb-4">
                  <strong>Event End Date/Time:</strong>
                  <span>{props.selectedEvent?.endDateTime}</span>
                </div>
                <div className="mb-4">
                  <strong>Description:</strong>
                  <div
                    className="event-description mt-2"
                    dangerouslySetInnerHTML={createMarkup(props.selectedEvent?.description || '')}
                  />
                </div>
              </div>

              <div className="socialgroup mt-4">
                <ul className="list-inline mb-0">
                  <li className="list-inline-item">
                    <a
                      target="_blank"
                      href="https://www.facebook.com/"
                      rel="noreferrer"
                      aria-label="Facebook"
                    >
                      <i className="fab fa-facebook fa-lg"></i>
                    </a>
                  </li>
                  <li className="list-inline-item">
                    <a
                      target="_blank"
                      href="https://www.google.com/"
                      rel="noreferrer"
                      aria-label="Google"
                    >
                      <i className="fab fa-google fa-lg"></i>
                    </a>
                  </li>
                  <li className="list-inline-item">
                    <a
                      target="_blank"
                      href="https://www.twitter.com/"
                      rel="noreferrer"
                      aria-label="Twitter"
                    >
                      <i className="fab fa-twitter fa-lg"></i>
                    </a>
                  </li>
                  <li className="list-inline-item">
                    <a
                      target="_blank"
                      href="https://www.instagram.com/"
                      rel="noreferrer"
                      aria-label="Instagram"
                    >
                      <i className="fab fa-instagram fa-lg"></i>
                    </a>
                  </li>
                  <li className="list-inline-item">
                    <a
                      target="_blank"
                      href="https://www.pinterest.com/"
                      rel="noreferrer"
                      aria-label="Pinterest"
                    >
                      <i className="fab fa-pinterest fa-lg"></i>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </ModalBody>
      </Modal>
    </>
  );
};

export default EventModelView;
