import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { getUserInfoFromToken } from "../../constant/Constant";
import { getAllCategories } from "../../../Slice/master";
import ReactSelect from "react-select";
import { addPersonSkill } from "../../../Slice/personSpecializedSkill";
import Loader from "../../Loader";
import { useNavigate } from "react-router-dom";

const schema = yup.object({
    category: yup.string().required("Mandatory*").trim(),
    description: yup.string().required("Mandatory*").trim(),
});

const AddSpecializedSkill = () => {

    const [selectedOption, setSelectedOption] = useState(null);
    const userInfo = getUserInfoFromToken();
    const id = userInfo?.userId

    const dispatch = useDispatch();
    const navigate  = useNavigate();

    useEffect(() => {
        dispatch(getAllCategories())
    }, [dispatch]);

    const { categories, isLoading: categoryLoading } = useSelector((state) => state.masterSlice);
    const { isLoading: addFormLoading } = useSelector((state) => state.PersonSkills);

    const CategoriesOptions = categories?.map((category) => ({
        value: category.categoryName,
        label: category.categoryName,
    }));

    CategoriesOptions.push({ value: "other", label: "Other" });

    const handleSelectChange = (selected) => {
        if (selected?.value === "other") {
            setSelectedOption("other");
        } else {
            setSelectedOption(selected?.value);
        }
    };
    const {
        handleSubmit,
        control,
        reset,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(schema),
    });

    const onSubmit = async (data) => {
        const requestData = {
            userId: id,
            category: data?.category,
            description: data?.description,
        };
        dispatch(addPersonSkill(requestData, reset, navigate));
    };

    return (
        <>
            { categoryLoading || addFormLoading ? (
                <Loader />
            ) : (
                <div id="content">
                    <div className="row justify-content-center my-4">
                        <div className="columns col-lg-7 col-md-7">
                            <div className="volunteer-contact-form">
                                <h3>Add Specialized Category:</h3>
                                <form onSubmit={handleSubmit(onSubmit)} className="volunteer-form">
                                    <div className="col-lg-12 col-md-12 col-sm-12 col-12 form-group mt-4">
                                        <label htmlFor="" className="text-left">
                                            Specialized Skills <span style={{ color: "#F15B43" }}>*</span>
                                        </label>
                                        {selectedOption === "other" ? (
                                            <Controller
                                                name="category"
                                                control={control}
                                                render={({ field: { value, onChange } }) => (
                                                    <input
                                                        className="input_fixed_width"
                                                        type="text"
                                                        placeholder="Enter Skill"
                                                        value={value}
                                                        onChange={(e) => onChange(e.target.value)}
                                                    />
                                                )}
                                            />
                                        ) : (
                                            <Controller
                                                name="category"
                                                control={control}
                                                render={({ field: { value, onChange } }) => (
                                                    <ReactSelect
                                                        className="selectcustom"
                                                        placeholder="Select Skill"
                                                        options={CategoriesOptions}
                                                        value={CategoriesOptions.find(
                                                            (option) => option.value === value
                                                        )}
                                                        onChange={(selected) => {
                                                            if (selected?.value !== "other") {
                                                                onChange(selected?.value);
                                                            } else {
                                                                onChange("");
                                                            }
                                                            handleSelectChange(selected);
                                                        }}
                                                        isClearable
                                                        isSearchable
                                                        styles={{
                                                            control: (provided, state) => ({
                                                                ...provided,
                                                                border: errors?.village
                                                                    ? "1px solid red"
                                                                    : "1px solid #B8BDC9",
                                                                backgroundColor: "white",
                                                                minHeight: 45,
                                                                height: 45,
                                                                boxShadow: state.isFocused
                                                                    ? "0 0 0 2px transparent"
                                                                    : provided.boxShadow,
                                                                "&:hover": {
                                                                    border: errors?.village
                                                                        ? "1px solid red"
                                                                        : "1px solid #B8BDC9",
                                                                },
                                                                display: "flex",
                                                            }),
                                                            valueContainer: (provided) => ({
                                                                ...provided,
                                                                height: 45,
                                                                display: "flex",
                                                                alignItems: "center",
                                                                padding: "0 15px",
                                                            }),
                                                            input: (provided) => ({
                                                                ...provided,
                                                                margin: 0,
                                                                padding: 0,
                                                            }),
                                                            indicatorsContainer: (provided) => ({
                                                                ...provided,
                                                                height: 45,
                                                                display: "flex",
                                                                alignItems: "center",
                                                            }),
                                                            placeholder: (provided) => ({
                                                                ...provided,
                                                                display: "flex",
                                                                alignItems: "center",
                                                            }),
                                                        }}
                                                    />
                                                )}
                                                defaultValue=""
                                            />
                                        )}
                                        {errors.category && (
                                            <p style={{ color: "red", textAlign: 'left' }}>
                                                {errors.category.message}
                                            </p>
                                        )}
                                    </div>
                                    <div className="col-lg-12 col-md-12 col-sm-12 col-12 form-group">
                                        <label className="text-left">Skill description <span style={{ color: "#F15B43" }}>*</span></label>
                                        <Controller
                                            name="description"
                                            control={control}
                                            render={({ field: { value, onChange } }) => (
                                                <textarea
                                                    type="text"
                                                    value={value}
                                                    className={`input_fixed_width line_height_textarea ${errors?.description ? 'valid_error' : ''}`}
                                                    onChange={onChange}
                                                />
                                            )}
                                            defaultValue=""
                                        />
                                        {errors?.description && (
                                            <div style={{ color: "red" }} className="text-left">
                                                {errors?.description?.message}
                                            </div>
                                        )}
                                    </div>
                                    <div className="submit-area col-lg-12 col-12">
                                        <button type="submit" className="button-round">
                                            Add
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    )
}

export default AddSpecializedSkill;