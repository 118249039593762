import moment from 'moment';
import React, { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import ReactQuill from 'react-quill';
import { Link, useNavigate } from 'react-router-dom';
import { addNews } from '../../../Slice/news';
import { useDispatch } from 'react-redux';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import { ROLES, getUserInfoFromToken } from '../../../Components/constant/Constant';
import 'react-quill/dist/quill.snow.css';

const schema = yup.object({
  title: yup.string().required("Title is required").trim(),
  content: yup.string().required("Content is required").trim(),
  publish_date: yup.string().required("Publish date of News is required"),
  thumbnail: yup.mixed()
    .required("Thumbnail is required")
    .test("fileType", "Invalid file type", (value) => {
      if (!value) return true;
      return ["image/jpeg", "image/png", "image/jpg"].includes(value.type);
    }),
}).required();

const AddNews = () => {

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const userInfo = getUserInfoFromToken();
  const [thumbnailUrl, setThumbnailUrl] = useState("");

  const {
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmit = (data) => {
    const formData = new FormData();
    formData.append("title", data?.title);
    formData.append("content", data?.content);
    formData.append("author", userInfo.userId);
    formData.append("publish_date", data?.publish_date);
    formData.append("thumbnail", data?.thumbnail);
    dispatch(addNews(formData, navigate, reset, userInfo, setThumbnailUrl));
  };

  return (
    <div id="content">
      <div className="container-fluid mt-2">
        <div className="row text-center mb-2">
          <h1 className="h2">Add News</h1>
        </div>
        <div className="row">
          <div className="volunteer-contact-form">
            <form onSubmit={handleSubmit(onSubmit)} className="volunteer-form">
              <div className="row">
                <div className="col-lg-12 col-md-12 col-sm-12 col-12 form-group">
                  <label className="text-left">News Title <span style={{ color: '#F15B43' }}>*</span></label>
                  <Controller
                    name="title"
                    control={control}
                    render={({ field: { value, onChange } }) => (
                      <input
                        className={`input_fixed_width`}
                        type="text"
                        value={value}
                        onChange={onChange}
                        autoComplete="false"
                      />
                    )}
                    defaultValue=""
                  />
                  {errors?.title && (
                    <p style={{ color: "red", textAlign: 'left' }}>{errors?.title?.message}</p>
                  )}
                </div>
                <div className="col-lg-6 col-md-6 col-sm-6 col-12 form-group">
                  <label className="text-left">Publish Date <span style={{ color: '#F15B43' }}>*</span></label>
                  <Controller
                    name="publish_date"
                    control={control}
                    render={({ field: { value, onChange } }) => (
                      <input
                        className="input_fixed_width"
                        type="date"
                        value={value}
                        onChange={e => onChange(e.target.value)}
                        min={moment().format('YYYY-MM-DD')}
                      />
                    )}
                    defaultValue=""
                  />
                  {errors.publish_date && (
                    <p style={{ color: "red", textAlign: 'left' }}>
                      {errors?.publish_date?.message}
                    </p>
                  )}
                </div>
                <div className="col-lg-6 col-md-6 col-sm-6 col-12 form-group form-group-file">
                  <div className="mb-2 profile-photo-class">Thumbnail <span style={{ color: 'red' }}>*</span></div>
                  <label htmlFor="thumbnail" className="input_fixed_width" style={{ lineHeight: 3 }}>Upload Photo</label>
                  <Controller
                    name="thumbnail"
                    control={control}
                    render={({ field: { onChange, onBlur } }) => (
                      <>
                        <input
                          id="thumbnail"
                          type="file"
                          onChange={(e) => {
                            const file = e.target.files[0];
                            if (file && file.type.startsWith("image/")) {
                              const thumbnailUrl = URL.createObjectURL(file);
                              setThumbnailUrl(thumbnailUrl);
                              onChange(file);
                            }
                          }}
                          onBlur={onBlur}
                          accept=".jpg,.png,.jpeg"
                        />
                        {thumbnailUrl && (
                          <div className="preview-image-container">
                            <img
                              className="preview-image"
                              src={thumbnailUrl}
                              alt="Preview"
                            />
                          </div>
                        )}
                      </>
                    )}
                  />
                  {errors?.thumbnail && (
                    <p style={{ color: "red", textAlign: 'left' }}>
                      {errors?.thumbnail?.message}
                    </p>
                  )}
                </div>
                <div className="form-group">
                  <label className="text-left">News Content <span style={{ color: '#F15B43' }}>*</span></label>
                  <Controller
                    name="content"
                    control={control}
                    render={({ field: { value, onChange } }) => (
                      <ReactQuill
                        className="react_quill_editor"
                        modules={{
                          toolbar: {
                            container: [
                              [
                                { header: "1" },
                                { header: "2" },
                                { header: [3, 4, 5, 6] },
                                { font: [] },
                              ],
                              [{ size: [] }],
                              [
                                "bold",
                                "italic",
                                "underline",
                                "strike",
                                "blockquote",
                              ],
                              [{ list: "ordered" }, { list: "bullet" }],
                              ["link", "video"],
                              ["link", "image", "video"],
                              ["clean"],
                              ["code-block"],
                            ],
                            handlers: {},
                          },
                        }}
                        value={value}
                        onChange={onChange}
                      />
                    )}
                    defaultValue=""
                  />
                  {errors?.content && (
                    <p style={{ color: "red", textAlign: 'left' }}>
                      {errors?.content?.message}
                    </p>
                  )}
                </div>
                <div className="submit-area col-lg-12 col-12">
                  <Link to={userInfo.roleName.includes(ROLES.Admin) ? "/admin/news" : "/user/news"} className="button-round button-back">
                    Back to List
                  </Link>
                  <button type="submit" className="button-round">
                    Add News
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AddNews