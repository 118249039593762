import React from 'react'
import { Carousel, Col, Container, Row } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import unboundImg1 from '../../Assets/Images/unbound-img1.jpg';
import unboundImg2 from '../../Assets/Images/HeroBG-3.jpg';
import unboundImg3 from '../../Assets/Images/HeroBG-4.jpg';

function HomeBannerSlider() {
    return (
        <>
            <section className="banner-banner" style={{ position: 'relative' }}>
                <Carousel controls={false} indicators={false} interval={9000} pause={false} >
                    <Carousel.Item>
                        <section
                            className="home-banner"
                            style={{ backgroundImage: `url(${unboundImg1})`, backgroundPosition: `bottom` }}
                        >
                            <div className="overlay"></div>
                        </section>
                    </Carousel.Item>
                    <Carousel.Item>
                        <section
                            className="home-banner"
                            style={{ backgroundImage: `url(${unboundImg2})`, backgroundPosition: `bottom` }}
                        >
                            <div className="overlay"></div>
                        </section>
                    </Carousel.Item>
                    <Carousel.Item>
                        <section
                            className="home-banner"
                            style={{ backgroundImage: `url(${unboundImg3})`, backgroundPosition: `bottom` }}
                        >
                            <div className="overlay"></div>
                        </section>
                    </Carousel.Item>
                </Carousel>
                <div className='row w-75 landing_content' style={{ position: 'absolute', top: 200, left: 100, zIndex: 10 }}>
                    <div className='col'>
                        <div>
                            <div className="banner-content">
                                <div className="sub-title" data-aos="fade-right" data-aos-duration="1000">Welcome to Our</div>
                                <h2 className="banner-title" data-aos="fade-right" data-aos-duration="1500">
                                    Nangal by Cycle Community!
                                </h2>
                                <div className="banner-text">
                                    <p data-aos="fade-up" data-aos-anchor-placement="top-bottom" data-aos-duration="2000">
                                        Welcome to{" "}
                                        <Link to="#" style={{ color: "#fff" }}>
                                            <b>www.nangalbycycle.com</b>
                                        </Link>{" "}
                                        your gateway to your own community empowerment and support, We are dedicated to fostering strong sense of community across 100+ villages in our area. Whether you're seeking volunteer opportunities, looking to contribute as blood donor, sharing local insights through blogs, or accessing vital services, you've come to the right place. Our volunteer platform serves as central hub for connecting individuals, organizations, and resources to make a positive impact. Here's what you'll find on our website:
                                    </p>
                                </div>
                                <div className="banner-button">
                                    <Link to="/become-volunteer" className="button-round">
                                        Become a Volunteer
                                    </Link>
                                    <Link to="/about" className="outline-round outline-round-white">
                                        Learn More
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default HomeBannerSlider