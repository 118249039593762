import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { getUserInfoFromToken } from "../../constant/Constant";
import { updatePassword } from "../../../Slice/authLogin";

const schema = yup.object({
    Oldpassword: yup.string().required("Password is required").trim(),
    password: yup.string().required("Password is required").trim(),
    cpassword: yup
        .string()
        .required("Confirm Password is required")
        .oneOf([yup.ref("password"), null], "Passwords must match"),
});

const ChangePassword = () => {
    const [eye, seteye] = useState(true);
    const [ceye, setceye] = useState(true);
    const userInfo = getUserInfoFromToken();
    const id = userInfo?.userId

    const onEyeClick = () => seteye(!eye);
    const onCEyeClick = () => setceye(!ceye);

    const {
        handleSubmit,
        control,
        reset,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(schema),
    });

    const dispatch = useDispatch();

    const onSubmit = async (data) => {
        const requestData = {
            userId: id,
            oldPassword: data?.Oldpassword,
            password: data?.password,
        };
        dispatch(updatePassword(requestData, id, reset));
    };

    return (
        <>
            <div id="content">
                <div className="row justify-content-center my-4">
                    <div className="columns col-lg-7 col-md-7">
                        <div className="volunteer-contact-form">
                            <h3>Change Password:</h3>
                            <form onSubmit={handleSubmit(onSubmit)} className="volunteer-form">
                                <div className="col-lg-12 col-md-12 col-sm-12 col-12 form-group mt-2">
                                    <label className="text-left">Old Password <span style={{ color: '#F15B43' }}>*</span></label>
                                    <Controller
                                        name="Oldpassword"
                                        control={control}
                                        render={({ field: { value, onChange } }) => (
                                            <input
                                                className={`input_fixed_width ${errors?.Oldpassword ? "valid_error" : ""}`}
                                                type="text"
                                                value={value}
                                                onChange={onChange}
                                            />
                                        )}
                                        defaultValue=""
                                    />
                                    {errors?.password && (
                                        <p style={{ color: "red", textAlign: 'left' }}>
                                            {errors?.Oldpassword?.message}
                                        </p>
                                    )}
                                </div>
                                <div className="col-lg-12 col-md-12 col-sm-12 col-12 form-group">
                                    <label className="text-left">New Password <span style={{ color: '#F15B43' }}>*</span></label>
                                    <div style={{ position: "relative" }}>
                                        <Controller
                                            name="password"
                                            control={control}
                                            render={({ field: { value, onChange } }) => (
                                                <div className="pass-group">
                                                    <input
                                                        className={`input_fixed_width ${errors?.password ? "valid_error" : ""}`}
                                                        type={eye ? "password" : "text"}
                                                        value={value}
                                                        onChange={onChange}
                                                        autoComplete="false"
                                                    />
                                                    <span
                                                        onClick={onEyeClick}
                                                        className={`fa toggle-password ${eye ? "fa-eye-slash" : "fa-eye"}`}
                                                        style={{ position: 'absolute', right: '10px', top: '50%', transform: 'translateY(-50%)' }}
                                                    />
                                                </div>
                                            )}
                                            defaultValue=""
                                        />
                                    </div>
                                    <div className={`text-left ${errors?.password ? "invalid_col" : ""}`} >
                                        {errors?.password?.message}
                                    </div>
                                </div>
                                <div className="col-lg-12 col-md-12 col-sm-12 col-12 form-group">
                                    <label className="text-left">Confirm New Password <span style={{ color: '#F15B43' }}>*</span></label>
                                    <div style={{ position: "relative" }}>
                                        <Controller
                                            name="cpassword"
                                            control={control}
                                            render={({ field: { value, onChange } }) => (
                                                <div className="pass-group">
                                                    <input
                                                        className={`input_fixed_width ${errors?.cpassword ? "valid_error" : ""}`}
                                                        type={ceye ? "password" : "text"}
                                                        value={value}
                                                        onChange={onChange}
                                                    />
                                                    <span
                                                        onClick={onCEyeClick}
                                                        className={`fa toggle-password ${ceye ? "fa-eye-slash" : "fa-eye"}`}
                                                        style={{ position: 'absolute', right: '10px', top: '50%', transform: 'translateY(-50%)' }}
                                                    />
                                                </div>
                                            )}
                                            defaultValue=""
                                        />
                                    </div>
                                    {errors?.cpassword && (
                                        <p style={{ color: "red", textAlign: 'left' }}>
                                            {errors?.cpassword?.message}
                                        </p>
                                    )}
                                </div>
                                <div className="submit-area col-lg-12 col-12">
                                    <button type="submit" className="button-round">
                                        Update
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ChangePassword