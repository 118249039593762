import React from "react";
import { useDispatch } from "react-redux";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, useForm } from "react-hook-form";
import { addFaq } from "../../../Slice/faq";
import { Link, useNavigate } from "react-router-dom";
import { getUserInfoFromToken } from "../../constant/Constant";

const schema = yup
  .object({
    question: yup.string().required("Event title is required").trim(),
    answer: yup.string().required("Name is required").trim(),
  })
  .required();

const AddFaq = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const {
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmit = async (data) => {
    var userInfo = getUserInfoFromToken();
    const requestData = {
      question: data?.question,
      answer: data?.answer,
      createdBy: userInfo?.userId
    };
    dispatch(addFaq(requestData, navigate, reset));
  };

  return (
    <div id="content">
      <div className="row">
        <div className="col-lg-12 col-md-8">
          <div className="row my-4">
            <div className="text-center">
              <h3>Add Faq</h3>
            </div>
            <div className="card-body pt-0">
              <div className="volunteer-contact-form">
                <form
                  onSubmit={handleSubmit(onSubmit)}
                  className="volunteer-form"
                >
                  <div className="row">
                    <div className="form-group">
                      <label htmlFor="" className="text-left">Question <span style={{ color: 'red' }}>*</span></label>
                      <Controller
                        name="question"
                        control={control}
                        render={({ field: { value, onChange } }) => (
                          <input
                            placeholder="Add Question"
                            type="text"
                            value={value}
                            onChange={onChange}
                            autoComplete="false"
                            className={`input_fixed_width ${errors?.question ? 'valid_error' : ''}`}
                          />
                        )}
                        defaultValue=""
                      />
                      {errors?.question && (
                        <div style={{ color: "red" }} className="text-left">
                          {errors?.question?.message}
                        </div>
                      )}
                    </div>
                    <div className="form-group">
                      <label htmlFor="" className="text-left">Answer <span style={{ color: 'red' }}>*</span></label>
                      <Controller
                        name="answer"
                        control={control}
                        render={({ field: { value, onChange } }) => (
                          <textarea
                            placeholder="Add Answer"
                            type="text"
                            value={value}
                            onChange={onChange}
                            className={`input_fixed_width ${errors?.answer ? 'valid_error' : ''}`}
                          />
                        )}
                        defaultValue=""
                      />
                      {errors?.answer && (
                        <div style={{ color: "red" }} className="text-left">
                          {errors?.answer?.message}
                        </div>
                      )}
                    </div>
                    <div className="submit-area col-lg-12 col-12">
                      <Link
                        to={"/admin/faqs"}
                        className="button-round button-back"
                      >
                        Back to List
                      </Link>
                      <button type="submit" className="button-round">
                        Add fAQ
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddFaq;
