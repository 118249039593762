import React from "react";
import { useState } from "react";
import { useDispatch } from "react-redux";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, useForm } from "react-hook-form";
import { addEvent } from "../../../Slice/events";
import Loader from "../../Loader";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import PhoneInput from "react-phone-input-2";
import { ROLES, getUserInfoFromToken } from "../../constant/Constant";
import ReactQuill from "react-quill";
import { IoClose } from "react-icons/io5";

const schema = yup
  .object({
    title: yup.string().required("Event title is required").trim(),
    organiserName: yup.string().required("Name is required").trim(),
    description: yup.string().required("Event Description is required").trim(),
    location: yup.string().required("Event Location is required").trim(),
    startDate: yup.string().required("Start date of event is required"),
    startTime: yup.string().required("Start time of event is required"),
    endDate: yup.string().required("End date of event is required")
      .test(
        "is-greater",
        "End date cannot be before start date",
        function (endDate) {
          const { startDate } = this.parent;
          return !startDate || !endDate || new Date(startDate) <= new Date(endDate);
        }
      ),
    endTime: yup.string().required("End time of event is required"),
    contact: yup.string(),
    thumbnail: yup.mixed().required("Thumbnail is required")
      .test("fileType", "Invalid file type", (value) => {
        if (!value) return true;
        return ["image/jpeg", "image/png", "image/jpg"].includes(value.type);
      }),
    additionalThumbnail: yup.array().of(
      yup.mixed()
        .test("fileType", "Invalid file type", (value) => {
          if (!value) return true;
          return ["image/jpeg", "image/jpg", "image/png"].includes(value.type);
        })
    )
  })
  .required();

const AddEvent = () => {
  const {
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const userInfo = getUserInfoFromToken();
  const userId = userInfo ? userInfo.userId : null;
  const isAdmin = userInfo?.roleName?.includes(ROLES.Admin);
  const backPath = isAdmin ? "/admin/all-events" : "/user/events";
  const [previewUrl, setPreviewUrl] = useState("");
  const [additionalThumbnail, setAdditionalThumbnail] = useState([]);
  const [cloneAdditionalThumbnail, setCloneAdditionalThumbnail] = useState([]);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleAdditionalThumbnail = (e, onChange) => {
    const newImages = Array.from(e.target.files);
    const imgUrl = newImages.map((image) => URL.createObjectURL(image));
    setAdditionalThumbnail(prev => [...prev, ...imgUrl]);
    setCloneAdditionalThumbnail(prev => [...prev, ...newImages]);
    onChange(newImages);
  }

  const removeImageHandle = (index) => {
    const img = [...additionalThumbnail];
    const cloneImg = [...cloneAdditionalThumbnail];
    img.splice(index, 1);
    cloneImg.splice(index, 1);
    setAdditionalThumbnail(img);
    setCloneAdditionalThumbnail(cloneImg);
  }

  const onSubmit = (data) => {
    const startDateTime = `${data?.startDate} ${data?.startTime}`;
    const endDateTime = `${data?.endDate} ${data?.endTime}`;
    const formData = new FormData();
    formData.append("title", data?.title);
    formData.append("organiser", data?.organiserName);
    formData.append("description", data?.description);
    formData.append("contact", data?.contact);
    formData.append("startDateTime", startDateTime);
    formData.append("endDateTime", endDateTime);
    formData.append("thumbnail", data?.thumbnail);
    formData.append("location", data?.location);
    formData.append("userId", userId);
    for (let i = 0; i < cloneAdditionalThumbnail.length; i++) {
      formData.append('additionalThumbnail', cloneAdditionalThumbnail[i]);
    }
    dispatch(addEvent(formData,
       navigate, 
       reset
      ));
  };

  const { isLoading } = useSelector((state) => state.event);
  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <div id="content">
            <div className="row">
              <div className="col-lg-12 col-md-8">
                <div className="row my-4">
                  <div className="text-center">
                    <h3>Add Event</h3>
                  </div>
                  <div className="card-body pt-0">
                    <div className="volunteer-contact-form">
                      <form onSubmit={handleSubmit(onSubmit)} className="volunteer-form">
                        <div className="row">
                          <div className="col-lg-6 col-md-6 col-sm-6 col-12 form-group">
                            <label className="text-left">Organiser Name <span style={{ color: '#F15B43' }}>*</span></label>
                            <Controller
                              name="organiserName"
                              control={control}
                              render={({ field: { value, onChange } }) => (
                                <input
                                  type="text"
                                  className={`input_fixed_width`}
                                  value={value}
                                  onChange={onChange}
                                  autoComplete="false"
                                />
                              )}
                              defaultValue=""
                            />
                            {errors?.organiserName && (
                              <div style={{ color: "red", textAlign: 'left' }} className="text-left">
                                {errors?.organiserName?.message}
                              </div>
                            )}
                          </div>
                          <div className="col-lg-6 col-md-6 col-sm-6 col-6 form-group">
                            <label className="text-left">Event Title <span style={{ color: '#F15B43' }}>*</span></label>
                            <Controller
                              name="title"
                              control={control}
                              render={({ field: { value, onChange } }) => (
                                <input
                                  className={`input_fixed_width`}
                                  type="text"
                                  value={value}
                                  onChange={onChange}
                                  autoComplete="false"
                                  data-bs-toggle="tooltip"
                                  data-bs-placement="right"
                                  title="As per Aadhar card or passport"
                                />
                              )}
                              defaultValue=""
                            />
                            {errors?.title && (
                              <p style={{ color: "red", textAlign: 'left' }}>
                                {errors?.title?.message}
                              </p>
                            )}
                          </div>
                          <div className="col-lg-6 col-md-6 col-sm-6 col-12 form-group">
                            <label className="text-left">Start Date <span style={{ color: '#F15B43' }}>*</span></label>
                            <Controller
                              name="startDate"
                              control={control}
                              render={({ field: { value, onChange } }) => (
                                <input
                                  className="input_fixed_width"
                                  type="date"
                                  value={value}
                                  onChange={onChange}
                                  min={isAdmin ? "1900-01-01" : new Date().toISOString().split("T")[0]} // Admin can select any date
                                />
                              )}
                              defaultValue=""
                            />
                            {errors.startDate && (
                              <p style={{ color: "red", textAlign: 'left' }}>
                                {errors?.startDate?.message}
                              </p>
                            )}
                          </div>
                          <div className="col-lg-6 col-md-6 col-sm-6 col-12 form-group">
                            <label className="text-left">Start Time <span style={{ color: '#F15B43' }}>*</span></label>
                            <Controller
                              name="startTime"
                              control={control}
                              render={({ field: { value, onChange } }) => (
                                <input
                                  className="input_fixed_width"
                                  type="time"
                                  value={value}
                                  onChange={onChange}
                                />
                              )}
                              defaultValue=""
                            />
                            {errors.startTime && (
                              <p style={{ color: "red", textAlign: 'left' }}>
                                {errors?.startTime?.message}
                              </p>
                            )}
                          </div>
                          <div className="col-lg-6 col-md-6 col-sm-6 col-12 form-group">
                            <label className="text-left">End Date <span style={{ color: '#F15B43' }}>*</span></label>
                            <Controller
                              name="endDate"
                              control={control}
                              render={({ field: { value, onChange } }) => (
                                <input
                                  className="input_fixed_width"
                                  type="date"
                                  value={value}
                                  onChange={onChange}
                                  min={isAdmin ? "1900-01-01" : new Date().toISOString().split("T")[0]} // Admin can select any date
                                />
                              )}
                              defaultValue=""
                            />
                            {errors.endDate && (
                              <p style={{ color: "red", textAlign: 'left' }}>
                                {errors?.endDate?.message}
                              </p>
                            )}
                          </div>

                          <div className="col-lg-6 col-md-6 col-sm-6 col-12 form-group">
                            <label className="text-left">End Time <span style={{ color: '#F15B43' }}>*</span></label>
                            <Controller
                              name="endTime"
                              control={control}
                              render={({ field: { value, onChange } }) => (
                                <input
                                  className="input_fixed_width"
                                  type="time"
                                  value={value}
                                  onChange={onChange}
                                />
                              )}
                              defaultValue=""
                            />
                            {errors.endTime && (
                              <p style={{ color: "red", textAlign: 'left' }}>
                                {errors?.endTime?.message}
                              </p>
                            )}
                          </div>
                          <div className="col-lg-6 col-md-6 col-sm-6 col-12 form-group">
                            <label className="text-left">Phone Number </label>
                            <Controller
                              name="contact"
                              control={control}
                              render={({ field: { value, onChange } }) => (
                                <PhoneInput
                                  country={"in"}
                                  value={value}
                                  onChange={(phone) => onChange(phone)}
                                />
                              )}
                              defaultValue=""
                            />
                          </div>
                          <div className="col-lg-6 col-md-6 col-sm-6 col-12 form-group">
                            <label className="text-left">Event Location <span style={{ color: '#F15B43' }}>*</span></label>
                            <Controller
                              name="location"
                              control={control}
                              render={({ field: { value, onChange } }) => (
                                <textarea
                                  className="input_fixed_width"
                                  type="text"
                                  value={value}
                                  onChange={onChange}
                                />
                              )}
                              defaultValue=""
                            />
                            {errors?.location && (
                              <p style={{ color: "red", textAlign: 'left' }}>
                                {errors?.location?.message}
                              </p>
                            )}
                          </div>
                          <div className="col-lg-6 col-md-6 col-sm-6 col-12 form-group form-group-file">
                            <div className="mb-2 profile-photo-class">Additional Photo</div>
                            <label htmlFor="additionalThumbnail" className="input_fixed_width" style={{ lineHeight: 3 }}>Additional Photo</label>
                            <Controller
                              name="additionalThumbnail"
                              control={control}
                              render={({ field: { onChange, onBlur } }) => (
                                <>
                                  <input
                                    id="additionalThumbnail"
                                    type="file"
                                    multiple
                                    onChange={(e) => handleAdditionalThumbnail(e, onChange)}
                                    onBlur={onBlur}
                                    accept=".jpg,.jpeg,.png"
                                    className="form-control form-control-lg"
                                  />
                                  {additionalThumbnail.length > 0 && (
                                    <div className="preview-image-container">
                                      {additionalThumbnail.map((thumbnail, index) => (
                                        <div key={index} className="d-flex justify-content-between align-items-center mt-2 rounded w-100 bg-light pe-2" style={{ backgroundColor: 'rgb(220 220 220)', overflow: 'hidden', border: '1px solid #cdcdcd' }}>
                                          <img
                                            style={{ objectFit: 'cover', width: 80, height: 50 }}
                                            className="preview-image"
                                            src={thumbnail}
                                            alt={`Additional Thumbnail ${index + 1}`}
                                          />
                                          <IoClose className="h5 mt-2" style={{ cursor: 'pointer' }} onClick={() => removeImageHandle(index)} />
                                        </div>
                                      ))}
                                    </div>
                                  )}
                                </>
                              )}
                            />
                          </div>
                          <div className="col-lg-6 col-md-6 col-sm-6 col-12 form-group form-group-file">
                            <div className="mb-2 profile-photo-class">Thumbnail <span style={{ color: '#F15B43' }}>*</span></div>
                            <label htmlFor="thumbnail" className="input_fixed_width" style={{ lineHeight: 3 }}>Upload Photo</label>
                            <Controller
                              name="thumbnail"
                              control={control}
                              render={({
                                field: { value, onChange, onBlur },
                              }) => (
                                <>
                                  <input
                                    id="thumbnail"
                                    type="file"
                                    onChange={(e) => {
                                      const file = e.target.files[0];
                                      if (
                                        file &&
                                        file.type.startsWith("image/")
                                      ) {
                                        const previewUrl =
                                          URL.createObjectURL(file);
                                        setPreviewUrl(previewUrl);
                                        onChange(file);
                                      }
                                    }}
                                    onBlur={onBlur}
                                    accept=".jpeg,.jpg,.png"
                                    className="form-control form-control-lg"
                                  />
                                  {previewUrl && (
                                    <div className="preview-image-container">
                                      <img
                                        className="preview-image"
                                        src={previewUrl}
                                        alt="Preview"
                                      />
                                    </div>
                                  )}
                                </>
                              )}
                            />
                            {errors?.thumbnail && (
                              <span
                                className="error-message"
                                style={{ color: "red", textAlign: 'left' }}
                              >
                                {errors?.thumbnail?.message}
                              </span>
                            )}
                          </div>
                          <div className="form-group">
                            <label className="text-left">Event Description <span style={{ color: '#F15B43' }}>*</span></label>
                            <Controller
                              name="description"
                              control={control}
                              render={({ field: { value, onChange } }) => (
                                <ReactQuill
                                  className="react_quill_editor"
                                  modules={{
                                    toolbar: {
                                      container: [
                                        [{ header: [1, 2, 3, 4, 5, 6] }],
                                        [{ font: [] }],
                                        [{ size: [] }],
                                        ["bold", "italic", "underline"],
                                        [{ list: "ordered" }, { list: "bullet" }],
                                      ],
                                      handlers: {},
                                    },
                                  }}
                                  value={value}
                                  onChange={onChange}
                                />
                              )}
                              defaultValue=""
                            />
                            {errors?.description && (
                              <p style={{ color: "red", textAlign: 'left' }}>
                                {errors?.description?.message}
                              </p>
                            )}
                          </div>
                          <div className="submit-area col-lg-12 col-12">
                            <Link to={backPath} className="button-round button-back">
                              Back to List
                            </Link>
                            <button type="submit" className="button-round">
                              Add Event
                            </button>
                          </div>
                          <div className="clearfix"></div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div >
        </>
      )}
    </>
  );
};

export default AddEvent;
