import React from "react";
import { Outlet } from "react-router-dom";
import UserHeader from "../Components/user/UserHeader";
import UserSiderBar from "../Components/user/UserSiderBar";

const UserLayout = () => {
  return (
    <>
      <UserHeader />
      <div className="container" id="main">
        <div className="row">
          <div className="col-md-3 volunteer-contact-form mt-3 rounded-3 p-3" id="sidebar" role="navigation">
            <UserSiderBar />
          </div>
          <div className="col-md-9 main mt-3">
            <Outlet />
          </div>
        </div>
      </div>
    </>
  );
};


export default UserLayout;
