import React, { useEffect } from 'react';
import innerBanner1Img from '../../Assets/Images/Event-bg-01-01.jpg';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { updateRegisterRoleDonor } from '../../Slice/master';
import { Helmet } from 'react-helmet';

function BecomeDonor() {
    const dispatch = useDispatch();
    const handleClick = () => dispatch(updateRegisterRoleDonor());

    return (
        <>
            <main id="content" className="site-main">
                <Helmet>
                    <title>Support Nangal By Cycle | Donate for Sustainable Tourism</title>
                    <meta name="description" content="Contribute to Nangal By Cycle's mission. Your donation helps promote eco-friendly tourism, maintain cycling trails, and support community initiatives." />
                </Helmet>
                <section className="inner-banner-wrap pb-0">
                    <div className="inner-baner-container" style={{ backgroundImage: `url(${innerBanner1Img})` }}>
                        <div className="container">
                            <div className="inner-banner-content">
                                <h1 className="inner-title">Become a Blood Donor</h1>
                            </div>
                        </div>
                    </div>
                </section>
                <div className="volunteer-wrap">
                    <div className="container">
                        <div className="section-head mt-5 mb-3">
                            <div className="row align-items-end">
                                <div className="col-lg-12">
                                    <div className="sub-title mb-2">Ready to Help Save a Life?</div>
                                    <h2>Be a Nangal Hero: Donate Blood, Save Lives</h2>
                                </div>
                                <div className="col-lg-12">
                                    <div className="section-disc">
                                        <p>Are you ready to be a hero in someone’s life? Imagine having the power to save lives with just a single act of kindness. That’s the incredible opportunity you have when you become a blood donor. Every day, countless lives are saved because of generous donors like you. Whether it’s a patient undergoing surgery, a cancer patient receiving treatment, or a trauma victim in need of immediate care, your blood donation can make all the difference.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="event-content">
                            <h3>But the benefits of donating blood go beyond just saving lives. As an active blood donor, you’re also contributing to the well-being of your community and society as a whole. Here’s how:</h3>
                            <ul style={{ listStyleType: "square" }} className="m-0">
                                <li className="mb-3"><b>Saving Lives : </b> Your blood donation can be the lifeline for someone in their darkest hour. It’s a simple yet powerful way to give back to society and make a tangible difference in someone’s life.</li>
                                <li className="mb-3"><b>Health Benefits : </b> Did you know that donating blood has health benefits for the donor too? Regular blood donation helps reduce the risk of heart disease, lowers cholesterol levels, and even helps in the prevention of certain types of cancers. Plus, it stimulates the production of new blood cells, keeping you healthy and rejuvenated.</li>
                                <li className="mb-3"><b>Community Impact : </b> By donating blood, you’re actively participating in community service. You’re showing compassion and solidarity with those in need, fostering a sense of unity and goodwill within your community.</li>
                                <li className="mb-3"><b>Emergency Preparedness : </b> Your blood donation ensures that there’s an adequate supply of blood available for emergencies. Whether it’s a natural disaster, a mass casualty event, or a medical emergency, your contribution helps ensure that blood is readily available to those who need it most.</li>
                                <li className="mb-3"><b>Personal Fulfillment : </b> There’s a profound sense of fulfillment that comes from knowing you’ve made a difference in someone’s life. As a blood donor, you have the power to bring hope and healing to those who are suffering, leaving a lasting impact on both the recipients and their loved ones.</li>
                            </ul>
                            <div className="section-disc">
                                <p>So why wait? Take the first step towards becoming a Nangal hero today by registering as a blood donor. Your generosity has the power to transform lives and inspire others to join this noble cause. Together, we can make a difference, one donation at a time. Join us in our mission to save lives and create a healthier, happier world for all.</p>
                            </div>
                            <div className="text-center">
                                <Link to="/auth/register" className="button-round" onClick={handleClick}>Register as a Blood Donor</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        </>
    )
}

export default BecomeDonor
