import React, { useState } from "react";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, useForm } from "react-hook-form";
import { getEvent, updateEvent } from "../../../Slice/events";
import { useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import moment from "moment";
import PhoneInput from "react-phone-input-2";
import ReactQuill from "react-quill";
import { IoClose } from "react-icons/io5";
import Loader from "../../Loader";
import { ROLES, getUserInfoFromToken } from "../../constant/Constant";

const schema = yup
  .object({
    title: yup.string().required("Event title is required").trim(),
    organiserName: yup.string().required("Name is required").trim(),
    description: yup.string().required("Event Description is required ").trim(),
    location: yup.string().required("Event Location is required").trim(),
    startDate: yup.string().required("Start date of event is required"),
    startTime: yup.string().required("Start time of event is required"),
    thumbnail: yup.mixed().required('Thumbnail is required')
      .test("fileType", "Invalid file type", (value) => {
        if (!value) return true;
        return ["image/jpeg", "image/jpg", "image/png"].includes(value.type);
      }),
    endDate: yup.string().required("End date of event is required")
      .test(
        "is-greater",
        "End date cannot be before start date",
        function (endDate) {
          const { startDate } = this.parent;
          return !startDate || !endDate || new Date(startDate) <= new Date(endDate)
        }),
    endTime: yup.string().required("End time of event is required"),
    contact: yup.string(),
  })
  .required();

const extractNameFromUrl = (url) => {
  const parts = url.split('/');
  const filename = parts[parts.length - 1];
  const nameParts = filename.split('-');
  if (nameParts.length > 1) {
    return nameParts.slice(1).join('-').split('.')[0];
  }
  return filename.split('.')[0];
};

const UpdateEvent = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { event, isLoading } = useSelector((state) => state.event);
  const [thumbnailUrl, setThumbnailUrl] = useState("");
  const [file, setFile] = useState(null);
  const [isDelay, setIsDelay] = useState(true);
  const [additionalThumbnail, setAdditionalThumbnail] = useState([]);
  const [cloneAdditionalThumbnail, setCloneAdditionalThumbnail] = useState([]);

  useEffect(() => {
    dispatch(getEvent(id));
  }, []);

  useEffect(() => {
    if (event) {
      setThumbnailUrl(event.imageUrl);
      let imagesArray = event.images.map((image) => image.imageUrl);
      setAdditionalThumbnail(imagesArray);

      fetch(event.imageUrl)
        .then((res) => res.blob())
        .then((blob) => {
          const file = new File([blob], "thumbnail.jpg", { type: blob.type });
          setFile(file);
        });

      let imageFileArrayPromises = event.images.map((image) => {
        return fetch(image.imageUrl)
          .then((res) => res.blob())
          .then((blob) => {
            return new File([blob], extractNameFromUrl(image.imageUrl), { type: blob.type });
          });
      });

      Promise.all(imageFileArrayPromises).then((files) => {
        setCloneAdditionalThumbnail(files);
      });
    }
  }, [event]);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsDelay(false);
    }, 1000);

    return () => clearTimeout(timer);
  }, []);

  const handleAdditionalThumbnail = (e, onChange) => {
    const newImages = Array.from(e.target.files);
    const imgUrl = newImages.map((image) => URL.createObjectURL(image));
    setAdditionalThumbnail(prev => [...prev, ...imgUrl]);
    setCloneAdditionalThumbnail(prev => [...prev, ...newImages]);
    onChange(newImages);
  }

  const removeImageHandle = (index) => {
    const img = [...additionalThumbnail];
    const cloneImg = [...cloneAdditionalThumbnail];
    img.splice(index, 1);
    cloneImg.splice(index, 1);
    setAdditionalThumbnail(img);
    setCloneAdditionalThumbnail(cloneImg);
  }

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmit = (data) => {
    const startDateTime = `${data?.startDate} ${data?.startTime}`;
    const endDateTime = `${data?.endDate} ${data?.endTime}`;
    const formData = new FormData();
    formData.append("title", data?.title);
    formData.append("organiser", data?.organiserName);
    formData.append("description", data?.description);
    formData.append("contact", data?.contact);
    formData.append("startDateTime", startDateTime);
    formData.append("endDateTime", endDateTime);
    formData.append("thumbnail", data?.thumbnail);
    formData.append("location", data?.location);
    for (let i = 0; i < cloneAdditionalThumbnail.length; i++) {
      formData.append('additionalThumbnail', cloneAdditionalThumbnail[i]);
    }
    dispatch(updateEvent(id, formData, navigate));
    console.log(data)
  };

  const formatDate = (date) => moment(date).format("YYYY-MM-DD");
  const formatTime = (date) => moment(date).format("HH:mm");

  return (
    <>
      {
        isLoading || isDelay ? (
          <Loader />
        ) : (
          <div id="content">
            <div className="row">
              <div className="col-lg-12 col-md-8">
                <div className="row my-4">
                  <div className="text-center">
                    <h3>Update Event</h3>
                  </div>
                  <div className="card-body pt-0"></div>
                  <div className="volunteer-contact-form">
                    <form onSubmit={handleSubmit(onSubmit)} className="volunteer-form">
                      <div className="row">
                        <div className="col-lg-6 col-md-6 col-sm-6 col-12 form-group">
                          <label className="text-left">Organiser Name <span style={{ color: '#F15B43' }}>*</span></label>
                          <Controller
                            name="organiserName"
                            control={control}
                            render={({ field: { value, onChange } }) => (
                              <input
                                className="input_fixed_width"
                                type="text"
                                value={value}
                                onChange={onChange}
                                autoComplete="false"
                                data-bs-toggle="tooltip"
                                data-bs-placement="right"
                                title="As per Aadhar card or passport"
                              />
                            )}
                            defaultValue={event?.organiser}
                          />
                          {errors?.organiserName && (
                            <p style={{ color: "red", textAlign: 'left' }}>
                              {errors?.organiserName?.message}
                            </p>
                          )}
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-6 col-12 form-group">
                          <label className="text-left">Event Title <span style={{ color: '#F15B43' }}>*</span></label>
                          <Controller
                            name="title"
                            control={control}
                            render={({ field: { value, onChange } }) => (
                              <input
                                className="input_fixed_width"
                                type="text"
                                value={value}
                                onChange={onChange}
                                autoComplete="false"
                                data-bs-toggle="tooltip"
                                data-bs-placement="right"
                                title="As per Aadhar card or passport"
                              />
                            )}
                            defaultValue={event?.title}
                          />
                          {errors?.title && (
                            <p style={{ color: "red", textAlign: 'left' }}>
                              {errors?.title?.message}
                            </p>
                          )}
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-6 col-12 form-group">
                          <label className="text-left">Start Date <span style={{ color: '#F15B43' }}>*</span></label>
                          <Controller
                            name="startDate"
                            control={control}
                            render={({ field: { value, onChange } }) => (
                              <input
                                className="input_fixed_width"
                                type="date"
                                value={value}
                                onChange={onChange}
                              />
                            )}
                            defaultValue={formatDate(event?.startDateTime)}
                          />
                          {errors.startDate && (
                            <p style={{ color: "red", textAlign: 'left' }}>
                              {errors?.startDate?.message}
                            </p>
                          )}
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-6 col-12 form-group">
                          <label className="text-left">Start Time <span style={{ color: '#F15B43' }}>*</span></label>
                          <Controller
                            name="startTime"
                            control={control}
                            render={({ field: { value, onChange } }) => (
                              <input
                                className="input_fixed_width"
                                type="time"
                                value={value}
                                onChange={onChange}
                              />
                            )}
                            defaultValue={formatTime(event?.startDateTime)}
                          />
                          {errors.startTime && (
                            <p style={{ color: "red", textAlign: 'left' }}>
                              {errors?.startTime?.message}
                            </p>
                          )}
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-6 col-12 form-group">
                          <label className="text-left">End Date <span style={{ color: '#F15B43' }}>*</span></label>
                          <Controller
                            name="endDate"
                            control={control}
                            render={({ field: { value, onChange } }) => (
                              <input
                                className="input_fixed_width"
                                type="date"
                                value={value}
                                onChange={onChange}
                              />
                            )}
                            defaultValue={formatDate(event?.endDateTime)}
                          />
                          {errors.endDate && (
                            <p style={{ color: "red", textAlign: 'left' }}>
                              {errors?.endDate?.message}
                            </p>
                          )}
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-6 col-12 form-group">
                          <label className="text-left">End Time <span style={{ color: '#F15B43' }}>*</span></label>
                          <Controller
                            name="endTime"
                            control={control}
                            render={({ field: { value, onChange } }) => (
                              <input
                                className="input_fixed_width"
                                type="time"
                                value={value}
                                onChange={onChange}
                              />
                            )}
                            defaultValue={formatTime(event?.endDateTime)}
                          />
                          {errors.endTime && (
                            <p style={{ color: "red", textAlign: 'left' }}>
                              {errors?.endTime?.message}
                            </p>
                          )}
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-6 col-12 form-group">
                          <label className="text-left">Phone Number </label>
                          <Controller
                            name="contact"
                            control={control}
                            render={({ field: { value, onChange } }) => (
                              <PhoneInput
                                country={"in"}
                                value={value}
                                onChange={(phone) => onChange(phone)}
                              />
                            )}
                            defaultValue={event?.contact}
                          />
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-6 col-12 form-group">
                          <label className="text-left">Event Location <span style={{ color: '#F15B43' }}>*</span></label>
                          <Controller
                            name="location"
                            control={control}
                            render={({ field: { value, onChange } }) => (
                              <textarea
                                className="input_fixed_width"
                                type="text"
                                value={value}
                                onChange={onChange}
                              />
                            )}
                            defaultValue={event?.location}
                          />
                          {errors?.location && (
                            <p style={{ color: "red", textAlign: 'left' }}>
                              {errors?.location?.message}
                            </p>
                          )}
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-6 col-12 form-group form-group-file">
                          <div className="mb-2 profile-photo-class">Additional Photo</div>
                          <label htmlFor="additionalThumbnail" className="input_fixed_width" style={{ lineHeight: 3 }}>Additional Photo</label>
                          <Controller
                            name="additionalThumbnail"
                            control={control}
                            render={({
                              field: { value, onChange, onBlur },
                            }) => (
                              <>
                                <input
                                  id="additionalThumbnail"
                                  type="file"
                                  multiple
                                  onChange={(e) => handleAdditionalThumbnail(e, onChange)}
                                  onBlur={onBlur}
                                  accept=".jpg,.jpeg,.png"
                                  className="form-control form-control-lg"
                                />
                                {additionalThumbnail.length > 0 && (
                                  <div className="preview-image-container">
                                    {additionalThumbnail.map((thumbnail, index) => (
                                      <div key={index} className="d-flex justify-content-between align-items-center mt-2 rounded w-100 bg-light pe-2" style={{ backgroundColor: 'rgb(220 220 220)', overflow: 'hidden', border: '1px solid #cdcdcd' }}>
                                        <img
                                          style={{ objectFit: 'cover', width: 80, height: 50 }}
                                          className="preview-image"
                                          src={thumbnail}
                                          alt={`Additional Thumbnail ${index + 1}`}
                                        />
                                        <IoClose className="h5 mt-2" style={{ cursor: 'pointer' }} onClick={() => removeImageHandle(index)} />
                                      </div>
                                    ))}
                                  </div>
                                )}
                              </>
                            )}
                            defaultValue={cloneAdditionalThumbnail}
                          />
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-6 col-12 form-group form-group-file">
                          <div className="mb-2 profile-photo-class">Thumbnail <span style={{ color: '#F15B43' }}>*</span></div>
                          <label htmlFor="thumbnail" className="input_fixed_width" style={{ lineHeight: 3 }}>Upload Photo</label>
                          <Controller
                            name="thumbnail"
                            control={control}
                            render={({
                              field: { value, onChange, onBlur },
                            }) => (
                              <>
                                <input
                                  id="thumbnail"
                                  type="file"
                                  onChange={(e) => {
                                    const file = e.target.files[0];
                                    if (
                                      file &&
                                      file.type.startsWith("image/")
                                    ) {
                                      const previewUrl =
                                        URL.createObjectURL(file);
                                      setThumbnailUrl(previewUrl);
                                      onChange(file);
                                    }
                                  }}
                                  onBlur={onBlur}
                                  accept=".png,.jpg,.jpeg"
                                  className="form-control form-control-lg"
                                />
                              </>
                            )}
                            defaultValue={file}
                          />
                          {thumbnailUrl && (
                            <div className="preview-image-container">
                              <img
                                className="preview-image"
                                src={thumbnailUrl}
                                alt="Preview"
                              />
                            </div>
                          )}
                          {errors?.thumbnail && (
                            <span
                              className="error-message"
                              style={{ color: "red", textAlign: 'left' }}
                            >
                              {errors?.thumbnail?.message}
                            </span>
                          )}
                        </div>
                        <div className="form-group">
                          <label className="text-left">Event Description <span style={{ color: '#F15B43' }}>*</span></label>
                          <Controller
                            name="description"
                            control={control}
                            render={({ field: { value, onChange } }) => (
                              <ReactQuill
                                className="react_quill_editor"
                                modules={{
                                  toolbar: {
                                    container: [
                                      [{ header: [1, 2, 3, 4, 5, 6] }],
                                      [{ font: [] }],
                                      [{ size: [] }],
                                      ["bold", "italic", "underline"],
                                      [{ list: "ordered" }, { list: "bullet" }],
                                    ],
                                    handlers: {},
                                  },
                                }}
                                value={value}
                                onChange={onChange}
                              />
                            )}
                            defaultValue={event?.description}
                          />
                          {errors?.description && (
                            <p style={{ color: "red", textAlign: 'left' }}>
                              {errors?.description?.message}
                            </p>
                          )}
                        </div>
                        <div className="submit-area col-lg-12 col-12">
                          <Link to={"/admin/all-events"} className="button-round button-back">
                            Back to List
                          </Link>
                          <button type="submit" className="button-round">
                            Update Event
                          </button>
                        </div>
                        <div className="clearfix"></div>
                      </div>
                    </form >
                  </div >
                </div>
              </div>
            </div>
          </div>
        )}
    </>
  );
};
export default UpdateEvent  