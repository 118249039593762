import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { AgGridReact } from "ag-grid-react";
import { useSelector, useDispatch } from "react-redux";
import { useMemo } from "react";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import { deleteEvent, getEvent, userEvents } from "../../../Slice/events";
import Loader from "../../Loader";
import { confirmAlert } from "react-confirm-alert";
import EventModelView from "../../admin/events/EventModelView";
import { getUserInfoFromToken } from "../../constant/Constant";
import { Table } from "antd";

const UserEventList = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const { userId } = getUserInfoFromToken();
    const id = userId;

    const [state, setState] = useState({
        search: "",
        page: 1,
        pageSize: 5,
    });

    const [selectedEvent, setSelectedEvent] = useState(null);
    const [isEventViewModalOpen, setEventViewModalOpen] = useState(false);
    const toggleEventViewModal = () =>
        setEventViewModalOpen(!isEventViewModalOpen);

    useEffect(() => {
        const reloadData = async () => {
            dispatch(userEvents(state.search, state.page, state.pageSize, id));
        };
        reloadData();
    }, [dispatch, state.search, state.page, state.pageSize, id]);

    const formatDate = (date) => {
        return moment(date).format("YYYY-MM-DD HH:mm:ss");
    };

    const handleViewClick = (event) => {
        setSelectedEvent(event);
        toggleEventViewModal(event);
      };
    
    //   const onShowSizeChange = (current, pageSize) => {
    //     setState({ ...state, page: 1, pagesize: pageSize });
    //   };

    // const itemRender = (current, type, originalElement) => {
    //     if (type === 'prev') {
    //       return <button className="btn btn-sm btn-primary">Previous</button>;
    //     }
    //     if (type === 'next') {
    //       return <button className="btn btn-sm btn-primary">Next</button>;
    //     }
    //     return originalElement;
    //   };
    
    //   const columns = [
    //     {
    //       title: '#',
    //       render: (text, record, index) => (
    //         index + 1
    //       ),
    //     },
    //     {
    //       title: 'Event Title',
    //       dataIndex: 'title',
    //       sorter: (a, b) => a.title.length - b.title.length,
    //     },
    //     {
    //       title: 'Organiser Name',
    //       dataIndex: 'organiser',
    //       sorter: (a, b) => a.organiser.length - b.organiser.length,
    //     },
    //     {
    //         title: 'Phone Number',
    //         dataIndex: 'contact',
    //         render: (text) => (
    //           text ? text : 'XXXXXXXXXX'
    //         ),
    //         sorter: (a, b) => (a.contact || '').length - (b.contact || '').length,
    //       },
    //     {
    //       title: 'Start Date/Time',
    //       dataIndex: 'startDateTime',
    //       render: (text, record, index) => (
    //         formatDate(text)
    //       )
    //     },
    //     {
    //       title: 'End Date/Time',
    //       dataIndex: 'endDateTime',
    //       render: (text, record, index) => (
    //         formatDate(text)
    //       )
    //     },
    //     {
    //       title: 'Location',
    //       dataIndex: 'location',
    //       sorter: (a, b) => a.location.length - b.location.length,
    //     },
    //     {
    //       title: "Action",
    //       render: (text, record) => (
    //         <div className="d-flex justify-content-around" data-popper-placement="bottom-end">
    //           {/* <Link to="#"
    //             className="dropdown-item px-2 text-warning" onClick={() => {
    //               handleUpdateClick(record.eventId)
    //             }}>
    //             <i className="fa fa-pencil m-r-5" />
    //           </Link> */}
    //           <Link
    //             to={`#`}
    //             title="View Profile"
    //             className="dropdown-item px-2 text-primary"
    //             onClick={() => handleViewClick(record)}
    //           >
    //             <i className={`fa fa-user`}></i>
    //           </Link>
    //           <Link className="dropdown-item px-2 text-danger" to="#" onClick={() => {
    //             handleDeleteClick(record.eventId);
    //           }}>
    //             <i className="fa fa-trash m-r-5" />
    //           </Link>
    //         </div>
    //       ),
    //     },
    //   ]
    
    const handleDeleteClick = (id) => {
        confirmAlert({
            title: "Confirm to delete",
            message: "Are you sure to do this.",
            buttons: [
                {
                    label: "Yes",
                    onClick: () => handleDelete(id),
                },
                {
                    label: "No",
                },
            ],
        });
    };

    const handleUpdateClick = (id) => {
        dispatch(getEvent(id));
        navigate(`/admin/update-event/${id}`);
    };

    const handleDelete = (id) => {
        dispatch(deleteEvent(id));
    };

    const paginationPageSizeSelector = [5, 10, 15];

    // const handleViewClick = (event) => {
    //     setSelectedEvent(event);
    //     toggleEventViewModal(event);
    // };

    const actionsRenderer = (params) => {
        const event = params.data;
        return (
            <div>
                <Link
                    to={`#`}
                    title="View Profile"
                    className={`btn btn-link btn-xs`}
                    onClick={() => handleViewClick(event)}
                >
                    <i className={`fa fa-user`}></i>
                </Link>
                <Link to={"#"} title="Delete" className={`btn btn-link btn-xs`}>
                    <i
                        className={`fa fa-trash`}
                        style={{ color: "red" }}
                        onClick={() => handleDeleteClick(event.actions)}
                    ></i>
                </Link>
                <Link to={`#`} className={`btn btn-link btn-xs`}>
                    <i
                        className={`fa fa-pencil`}
                        onClick={() => handleUpdateClick(event.actions)}
                    ></i>
                </Link>
            </div>
        );
    };

    const ThumbnailRenderer = ({ value }) => {
        return (
            <img
                src={value}
                alt="Thumbnail"
                style={{ maxWidth: "100px", maxHeight: "100px" }}
            />
        );
    };

    const columnDefs = [
        { headerName: "#", field: "id" },
        { headerName: "Event Name", field: "name" },
        { headerName: "Organiser Name", field: "organiserName" },
        { headerName: "Contact", field: "contact" },
        { headerName: "Start Date/Time", field: "startDateTime" },
        { headerName: "End Date/Time", field: "endDateTime" },
        { headerName: "Location", field: "location" },
        {
            headerName: "Actions",
            sortable: false,
            field: "actions",
            cellRenderer: actionsRenderer,
        },
    ];

    const { userEvent, isLoading } = useSelector((state) => state.event);

    const rowData = userEvent?.map((event, index) => ({
        id: index + 1,
        thumbnail: event.thumbnail
            ? `${process.env.REACT_APP_API_URL}/public/uploads/thumbnail/${event.thumbnail}`
            : null,
        name: event?.title,
        organiserName: event?.organiser,
        contact: event?.contact ? event.contact : "XXXXXXXXXX",
        startDateTime: formatDate(event?.startDateTime),
        endDateTime: formatDate(event?.endDateTime),
        location: event?.location,
        actions: event.eventId,
    }));

    const defaultColDef = useMemo(() => {
        return {
            resizable: false,
            rowDrag: false,
            flex: 1,
            minWidth: 100,
        };
    }, []);
    return (
        <>
            <div className={`content`}>
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-sm-12">
                            <div className='row justify-content-between align-items-center all_donor_header mb-2'>
                                <div className='col-auto'>
                                    <h1 className='h2'>User Event List</h1>
                                </div>
                                <div className="col-auto">
                                    <Link to={"/event/add-event"} className={`button-round border_radius`} type="button">
                                        <i className={`fa fa-plus`} aria-hidden="true"></i> Add Event
                                    </Link>
                                </div>
                            </div>
                            <div className="col-md-3 col-lg-3 col-sm-3 mb-2 mb-lg-2">
                                <input type="text" className="form-control" placeholder="Search" value={state.search} onChange={(e) => setState({ ...state, search: e.target.value })} />
                            </div>
                            {isLoading ? (
                                <Loader />
                            ) : (
                                <div className={` card table-with-links`}>
                                    <div className={` card-header`}>
                                        <h4 className={`card-title`}>Event List with Details</h4>
                                    </div>
                                    <div className={`card-body table-full-width`}>
                                        <div
                                            className="ag-theme-quartz"
                                            style={{ height: "100%", width: "100%" }}
                                        >
                                            <AgGridReact
                                                columnDefs={columnDefs}
                                                defaultColDef={defaultColDef}
                                                rowData={rowData}
                                                pagination={true}
                                                domLayout="autoHeight"
                                                paginationPageSizeSelector={paginationPageSizeSelector}
                                                frameworkComponents={{
                                                    actionsRenderer: actionsRenderer,
                                                    thumbnailRenderer: ThumbnailRenderer,
                                                }}
                                            />
                                        </div>
                                    </div>
                                </div>

                            //     <Table
                            //     pagination={{
                            //       current: state.page,
                            //       pageSize: state.pageSize,
                            //     //   total: totalCount,
                            //       showTotal: (total, range) =>
                            //         `Showing ${range[0]} to ${range[1]} of ${total} entries`,
                            //       showSizeChanger: true,
                            //       onShowSizeChange: onShowSizeChange,
                            //       itemRender: itemRender,
                            //       onChange: (page, pageSize) =>
                            //         setState({ ...state, page, pagesize: pageSize }),
                            //     }}
                            //     bordered
                            //     style={{ overflowX: "auto" }}
                            //     columns={columns}
                            //     dataSource={userEvent}
                            //     rowKey={(record) => record.eventId}
                            //   />
                            )}
                            <EventModelView
                                isEventViewModalOpen={isEventViewModalOpen}
                                toggleEventViewModal={toggleEventViewModal}
                                selectedEvent={selectedEvent}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default UserEventList