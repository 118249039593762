import React from "react";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import { Link } from "react-router-dom";
import genericImg from "../../../Assets/Images/Generic-img.png";

const SkilledPersonModalView = (props) => {
  return (
    <>
<Modal
        isOpen={props.isSkilledPersonViewModalOpen}
        toggle={() => props.toggleSkilledPersonViewModal()}
      >
        <ModalHeader toggle={() => props.toggleSkilledPersonViewModal()}>
          Skilled Person View
        </ModalHeader>
        <ModalBody>
          <div className="card p-5">
            <div className="sidebar">
              <aside className="widget author_widget">
                <div className="widget-content text-center">
                  <div className="profile">
                  <figure className="avatar">
                      <Link to="#">
                        <img
                          src={
                            props.selectedSkilledPerson?.userProfile
                              ? props.selectedSkilledPerson.userProfile
                              : genericImg
                          }
                          alt=""
                        />
                      </Link>
                    </figure>
                    <div className="text-content">
                      <div className="name-title">
                        <h3>
                          <Link to="#">{props.selectedSkilledPerson?.name}</Link>
                        </h3>
                      </div>
                      <p>
                      Email: {props.selectedSkilledPerson?.email}
                      </p>
                      <p>
                      contact: {props.selectedSkilledPerson?.mobile}
                      </p>
                      <p>
                      Date of Birth: {props.selectedSkilledPerson?.dob}
                      </p>
                    </div>
                    <div className="socialgroup">
                      <ul>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.facebook.com/"
                            rel="noreferrer"
                          >
                            <i className="fab fa-facebook"></i>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.google.com/"
                            rel="noreferrer"
                          >
                            <i className="fab fa-google"></i>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.twitter.com/"
                            rel="noreferrer"
                          >
                            <i className="fab fa-x-twitter"></i>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.instagram.com/"
                            rel="noreferrer"
                          >
                            <i className="fab fa-instagram"></i>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.pinterest.com/"
                            rel="noreferrer"
                          >
                            <i className="fab fa-pinterest"></i>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </aside>
            </div>
          </div>
        </ModalBody>
      </Modal>
    </>
  )
}

export default SkilledPersonModalView;