import React from "react";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import { Link } from "react-router-dom";
import moment from "moment";

const ViewFeedBackModel = (props) => {

    const formatDate = (date) => moment(date).format("YYYY-MM-DD");

    return (
        <Modal
            isOpen={props.isFeedBackViewModalOpen}
            toggle={() => props.toggleFeedBackViewModal()}
            size="lg" 
        >
            <ModalHeader toggle={() => props.toggleFeedBackViewModal()}>
                Feedback View
            </ModalHeader>

            <ModalBody style={{ padding: '20px' }}>
                <div style={{ textAlign: 'left' }}>
                    <div style={{ marginBottom: '15px' }}>
                        <h3>
                            <Link to="#" style={{ color: '#007bff', textDecoration: 'none' }}>
                                {props?.selectedFeedBack?.name}
                            </Link>
                        </h3>
                    </div>
                    <p style={{ marginBottom: '10px' }}>
                        <strong style={{ display: 'inline', marginRight: '10px' }}>Email:</strong>
                        {props?.selectedFeedBack?.email}
                    </p>
                    <p style={{ marginBottom: '10px' }}>
                        <strong style={{ display: 'inline', marginRight: '10px' }}>Contact:</strong>
                        {props?.selectedFeedBack?.contact}
                    </p>
                    <p style={{ marginBottom: '10px' }}>
                        <strong style={{ display: 'inline', marginRight: '10px' }}>Added On:</strong>
                        {formatDate(props?.selectedFeedBack?.addedOn)}
                    </p>
                    <p>
                        <strong style={{ display: 'inline', marginRight: '10px' }}>Feedback:</strong>
                        {props?.selectedFeedBack?.description}
                    </p>
                </div>
            </ModalBody>
        </Modal>
    );
}

export default ViewFeedBackModel;
