import { createBrowserRouter } from "react-router-dom";
import Layout from "./Layouts/Layout";
import NotFound from "./Pages/NotFound";
import Home from "./Pages/Home";
import EventArchive from "./Pages/Events/EventArchive";
import Volunteers from "./Pages/Volunteer/Volunteers";
import BecomeVolunteer from "./Pages/Volunteer/BecomeVolunteer";
import Donors from "./Pages/Donor/Donors";
import About from "./Pages/About";
import Contact from "./Pages/Contact";
import BlogArchive from "./Pages/Blog/BlogArchive";
import Gallery from "./Pages/Gallery";
import AuthLayout from "./Layouts/AuthLayout";
import Login from "./Pages/Auth/Login";
import Register from "./Pages/Auth/Register";
import ForgotPassword from "./Pages/Auth/ForgotPassword";
import ResetPassword from "./Pages/Auth/ResetPassword";
import EventSingle from "./Pages/Events/EventSingle";
import BlogSingle from "./Pages/Blog/BlogSingle";
import FAQ from "./Pages/FAQ";
import AuthGuard from "./Components/AuthGuard";
import AdminLayout from "./Layouts/AdminLayout";
import UserDashboard from "./Pages/Main-Pages/UserDashboard";
import AllDonorList from "./Components/admin/donors/AllDonorList";
import AddDonors from "./Components/admin/donors/AddDonors";
import AllVolunteerList from "./Components/admin/Volunteer/AllVolunteerList";
import UserProfile from "./Components/admin/profile/UserProfile";
import DonorCard from "./Pages/Donor/DonorCard";
import { ROLES } from "./Components/constant/Constant";
import AllRequestsList from "./Components/admin/ContactRequest/AllRequestsList";
import AllEventsList from "./Components/admin/events/AllEventsList";
import AddEvent from "./Components/admin/events/AddEvent";
import UpdateEvent from "./Components/admin/events/UpdateEvent";
import UpdateDonor from "./Components/admin/donors/UpdateDonor";
import BecomeDonor from "./Pages/Donor/BecomeDonor";
import AddVolunteers from "./Components/admin/Volunteer/AddVolunteers";
import UpdateVolunteer from "./Components/admin/Volunteer/UpdateVolunteer";
import AddPerson from "./Components/admin/Skilled Person/AddPerson";
import SkilledPersonList from "./Components/admin/Skilled Person/SkilledPersonList";
import UpdateSkilledPerson from "./Components/admin/Skilled Person/UpdateSkilledPerson";
import AddSkilledPerson from "./Pages/Auth/Components/AddSkilledPerson";
import SkilledPerson from "./Pages/SkilledPerson";
import UnAuthorized from "./Pages/UnAuthorized";
import AllFaqList from "./Components/admin/faq/AllFaqList";
import AddFaq from "./Components/admin/faq/AddFaq";
import UpdateFaq from "./Components/admin/faq/UpdateFaq";
import UserLayout from "./Layouts/UserLayout";
import RequestList from "./Components/admin/events/RequestList";
import UserEventList from "./Components/user/events/UserEventList";
import ChangePassword from "./Components/admin/profile/ChangePassword";
import UpdateContactRequest from "./Components/admin/ContactRequest/UpdateContactRequest";
import SocialMediaLinks from "./Components/user/socialMedia/SocialMediaLinks";
import ScrollToTop from "./utils/hooks/ScrollToTop";
import ValidateAccount from "./Pages/Auth/ValidateAccount";
import AllGallery from "./views/gallery/AllGallery";
import AllBlogs from "./views/blog/AllBlogs";
import AddBlog from "./views/blog/AddBlog";
import UpdateBlog from "./views/blog/UpdateBlog";
import AuditLogs from "./views/audit/AuditLogs";
import AllHeroes from "./views/nangalHeroes/AllHeroes";
import AddHero from "./views/nangalHeroes/AddHero";
import UpdateHero from "./views/nangalHeroes/UpdateHero";
import NangalHeroes from "./views/nangalHeroes/NangalHeroes";
import HeroDetails from "./views/nangalHeroes/HeroDetails";
import TermAndConditions from "./Pages/TermAndConditions";
import VolunteerDetail from "./views/volunteer/VolunteerDetail";
import Team from "./Pages/Team";
import TeamDetails from "./Pages/TeamDetails";
import BecomeAnNBCMMember from "./Pages/Volunteer/BecomeAnNBCMMember";
import RegisterPage from "./Pages/Auth/RegisterPage";
import NewsList from "./Components/admin/news/NewsList";
import AddNews from "./Components/admin/news/AddNews";
import UpdateNews from "./Components/admin/news/UpdateNews";
import NewsArchive from "./Pages/News/NewsArchive";
import NewsSingle from "./Pages/News/NewsSingle";
import BloodRequirementForm from "./Pages/BloodRequirementForm";
import BloodRequirementCreate from "./Components/admin/bloodRequirement/BloodRequirementCreate";
import BloodRequirementList from "./Components/admin/bloodRequirement/BloodRequirementList";
import UpdateRequirement from "./Components/admin/bloodRequirement/UpdateRequirement";
import Thankyou from "./Pages/Thankyou";
import FeedBackWidget from "./Pages/FeedBackWidget";
import AllFeedBackList from "./Components/admin/feedbacks/AllFeedBackList";
import EventParticipationForm from "./Pages/Events/EventParticipationForm";
import EventParticipantsList from "./Components/admin/eventParticipants.js/EventParticipantsList";
import AddEventParticipants from "./Components/admin/eventParticipants.js/AddEventParticipants";
import UpdateEventParticipant from "./Components/admin/eventParticipants.js/UpdateEventParticipant";
import AddSpecializedSkill from "./Components/user/specializedSkill/AddSpecializedSkill";
import UserSpecializedSkills from "./Components/user/specializedSkill/UserSpecializedSkills";
import SpeicalizedSkilledPersons from "./Pages/SpeicalizedSkilledPersons";
import UpdateSpecializedSkill from "./Components/user/specializedSkill/UpdateSpecializedSkill";
import CompetetionPictures from "./Pages/CompetetionPictures";

export const router = createBrowserRouter([
  {
    path: "/",
    element: (
      <ScrollToTop>
        <Layout />
      </ScrollToTop>
    ),
    errorElement: <NotFound />,
    children: [
      {
        path: "",
        element: <Home />,
      },
      {
        path: "home",
        element: <Home />,
      },
      {
        path: "events",
        element: <EventArchive />,
      },
      {
        path: "event/participation/:id",
        element: <EventParticipationForm />,
      },
      {
        path: "event/:title",
        element: <EventSingle />,
      },
      {
        path: "volunteers",
        element: <Volunteers />,
      },
      {
        path: "become-volunteer",
        element: <BecomeVolunteer />,
      },
      {
        path: "become-nbc-member",
        element: <BecomeAnNBCMMember />,
      },
      {
        path: "term-conditions",
        element: <TermAndConditions />,
      },
      {
        path: "donor",
        element: <Donors />,
      },
      {
        path: "contact",
        element: <Contact />,
      },
      {
        path: "/donor/:name/:id",
        element: <DonorCard />,
      },
      {
        path: "about",
        element: <About />,
      },
      {
        path: "blogs",
        element: <BlogArchive />,
      },
      {
        path: "Newsarchive",
        element: <NewsArchive />,
      },
      {
        path: "news/:title",
        element: <NewsSingle />,
      },
      {
        path: "gallery",
        element: <Gallery />,
      },
      {
        path: "blog/:title",
        element: <BlogSingle />,
      },
      {
        path: "faq",
        element: <FAQ />,
      },
      {
        path: "become-donor",
        element: <BecomeDonor />,
      },
      {
        path: "skilled-persons",
        element: <SkilledPerson />,
      },
      {
        path: "Un-Authorized",
        element: <UnAuthorized />,
      },
      {
        path: "nangal-heroes",
        element: <NangalHeroes />,
      },
      {
        path: "hero/:id",
        element: <HeroDetails />,
      },
      {
        path: "vol-details/:id",
        element: <VolunteerDetail />,
      },
      {
        path: "team",
        element: <Team />,
      },
      {
        path: "member-details/:id",
        element: <TeamDetails />,
      },
      {
        path: "Bloodrequirement",
        element: <BloodRequirementForm />,
      },
      {
        path: "/thankyou",
        element: <Thankyou />,
      },
      {
        path: "/feedback",
        element: <FeedBackWidget />,
      },
      {
        path: "/specializedskill",
        element: <SpeicalizedSkilledPersons />,
      },
      {
        path: "/artgallery",
        element: <CompetetionPictures />,
      },
    ],
  },
  {
    path: "auth",
    element: (
      <ScrollToTop>
        <AuthLayout />
      </ScrollToTop>
    ),
    children: [
      {
        path: "login",
        element: <Login />,
      },
      {
        path: "registermember",
        element: <RegisterPage />,
      },
      {
        path: "Skills",
        element: <AddSkilledPerson />,
      },
      {
        path: "register",
        element: <Register />,
      },
      {
        path: "forgot-password",
        element: <ForgotPassword />,
      },
      {
        path: "reset-password/:id",
        element: <ResetPassword />,
      },
      {
        path: "verify-account/:id",
        element: <ValidateAccount />,
      },
    ],
  },
  {
    path: "admin",
    element: (
      <AuthGuard allowedRoles={[ROLES.Admin]}>
        <ScrollToTop>
          <AdminLayout />
        </ScrollToTop>
      </AuthGuard>
    ),
    errorElement: <UnAuthorized />,
    children: [
      {
        path: "user-profile",
        element: <UserProfile />,
      },
      {
        path: "all-events",
        element: <AllEventsList />,
      },
      {
        path: "add-event",
        element: <AddEvent />,
      },
      {
        path: "request-event",
        element: <RequestList />,
      },
      {
        path: "update-event/:id",
        element: <UpdateEvent />,
      },
      {
        path: "add-donor",
        element: <AddDonors />,
      },
      {
        path: "all-donors",
        element: <AllDonorList />,
      },
      {
        path: "update-donor/:id",
        element: <UpdateDonor />,
      },
      {
        path: "add-volunteer",
        element: <AddVolunteers />,
      },
      {
        path: "all-volunteers",
        element: <AllVolunteerList />,
      },
      {
        path: "update-volunteer/:id",
        element: <UpdateVolunteer />,
      },
      {
        path: "add-SkilledPerson",
        element: <AddPerson />,
      },
      {
        path: "all-SkilledPersons",
        element: <SkilledPersonList />,
      },
      {
        path: "update-SkilledPerson/:id",
        element: <UpdateSkilledPerson />,
      },
      {
        path: "contact-requests",
        element: <AllRequestsList />,
      },
      {
        path: "update-requests/:id",
        element: <UpdateContactRequest />,
      },
      {
        path: "gallery",
        element: <AllGallery />,
      },
      {
        path: "add-faq",
        element: <AddFaq />,
      },
      {
        path: "faqs",
        element: <AllFaqList />,
      },
      {
        path: "update-faq/:id",
        element: <UpdateFaq />,
      },
      {
        path: "add-blog",
        element: <AddBlog />,
      },
      {
        path: "all-blogs",
        element: <AllBlogs />,
      },
      {
        path: "update-blog/:blogId",
        element: <UpdateBlog />,
      },
      {
        path: "addnews",
        element: <AddNews />,
      },
      {
        path: "news",
        element: <NewsList />,
      },
      {
        path: "updatenews/:newsId",
        element: <UpdateNews />,
      },
      {
        path: "audit-logs",
        element: <AuditLogs />,
      },
      {
        path: "heroes",
        element: <AllHeroes />,
      },
      {
        path: "add-hero",
        element: <AddHero />,
      },
      {
        path: "update-hero/:heroId",
        element: <UpdateHero />,
      },
      {
        path: "addbloodrequest",
        element: <BloodRequirementCreate />,
      },
      {
        path: "allbloodrequest",
        element: <BloodRequirementList />,
      },
      {
        path: "updatebloodrequest/:id",
        element: <UpdateRequirement />,
      },
      {
        path: "allfeedbackslist",
        element: <AllFeedBackList />
      },
      {
        path: "addparticipant",
        element: <AddEventParticipants />
      },
      {
        path: "allParticipantslist",
        element: <EventParticipantsList />
      },
      {
        path: "updateparticipant/:id",
        element: <UpdateEventParticipant />
      }
    ],
  },
  {
    path: "user",
    element: (
      <AuthGuard allowedRoles={[ROLES.Volunteer, ROLES.SkilledPerson]}>
        <ScrollToTop>
          <UserLayout />
        </ScrollToTop>
      </AuthGuard>
    ),
    errorElement: <UnAuthorized />,
    children: [
      {
        path: "edit-profile",
        element: <UserDashboard />,
      },
      {
        path: "events",
        element: <UserEventList />,
      },
      {
        path: "socialMedia-links",
        element: <SocialMediaLinks />,
      },
      {
        path: "gallery",
        element: <AllGallery />,
      },
      {
        path: "blogs",
        element: <AllBlogs />,
      },
      {
        path: "add-blog",
        element: <AddBlog />,
      },
      {
        path: "update-blog/:blogId",
        element: <UpdateBlog />,
      },
      {
        path: "addnews",
        element: <AddNews />,
      },
      {
        path: "news",
        element: <NewsList />,
      },
      {
        path: "updatenews/:newsId",
        element: <UpdateNews />,
      },
      // {
      //   path: "add/specializedskill",
      //   element: <AddSpecializedSkill />,
      // },
      // {
      //   path: "my/specializedskill",
      //   element: <UserSpecializedSkills />,
      // },
      // {
      //   path: "update/specializedskill/:id",
      //   element: <UpdateSpecializedSkill />,
      // },
    ],
  },
  {
    path: "event",
    element: (
      <AuthGuard allowedRoles={[ROLES.Admin, ROLES.Volunteer, ROLES.SkilledPerson]}>
        <ScrollToTop>
          <UserLayout />
        </ScrollToTop>
      </AuthGuard>
    ),
    errorElement: <UnAuthorized />,
    children: [
      {
        path: "add-event",
        element: <AddEvent />,
      },
    ],
  },
  {
    path: "password",
    element: (
      <AuthGuard allowedRoles={[ROLES.Admin, ROLES.Volunteer, ROLES.SkilledPerson]}>
        <ScrollToTop>
          <UserLayout />
        </ScrollToTop>
      </AuthGuard>
    ),
    errorElement: <UnAuthorized />,
    children: [
      {
        path: "change-password",
        element: <ChangePassword />,
      },
    ],
  },

  {
    path: "/member",
    element: (
      <AuthGuard allowedRoles={[ROLES.SkilledPerson]}>
        <ScrollToTop>
          <UserLayout />
        </ScrollToTop>
      </AuthGuard>
    ),
    errorElement: <UnAuthorized />,
    children: [
      {
        path: "add/specializedskill",
        element: <AddSpecializedSkill />,
      },
      {
        path: "my/specializedskill",
        element: <UserSpecializedSkills />,
      },
      {
        path: "update/specializedskill/:id",
        element: <UpdateSpecializedSkill />,
      },
    ],
  },

]);
