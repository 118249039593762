import React, { useEffect, useState } from 'react'
import unboundLogo1 from '../../Assets/Images/unbound-logo1.png';
import { Link, NavLink } from 'react-router-dom';
import { confirmAlert } from 'react-confirm-alert';
import RoleUpgradeModel from './roleUpgrade/RoleUpgradeModel';

const UserSiderBar = () => {
    const [user, setUser] = useState(JSON.parse(localStorage.getItem('user')));
    const [isModalOpen, setIsModalOpen] = useState(false);

    const toggleModal = () => {
        setIsModalOpen(!isModalOpen);
    };

    const updateRolesFromStorage = () => {
        const user = JSON.parse(localStorage.getItem('user'));
        setUser(user);
    };

    useEffect(() => {
        updateRolesFromStorage();
        window.addEventListener('storage', updateRolesFromStorage);
        return () => window.removeEventListener('storage', updateRolesFromStorage);
    }, []);

    const isDonor = user?.roleName?.includes('donor');

    const handleUpgradeClick = (id) => {
        confirmAlert({
            title: <div style={{ fontSize: '50px' }}>Confirm to become Blood Donor </div>,
            message: "Are you sure you want to become a Blood donor?",
            buttons: [
                {
                    label: "Yes",
                    onClick: () => toggleModal(),
                },
                {
                    label: "No",
                },
            ],
        });
    };

    return (
        <>
            <div className="site-identity">
                <h1 className="site-title">
                    <Link to="/">
                        <img className="white-logo" src={unboundLogo1} alt="logo" />
                    </Link>
                </h1>
            </div>
            <hr />
            <nav className="nav flex-column sticky-top pl-0 mt-3 text-uppercase align-items-start">
                <NavLink
                    className={({ isActive }) =>
                        `${isActive ? "current-menu-item" : ""} nav-link text-dark fw-bold`
                    }
                    to="/user/events"
                >
                    <i className="fa-solid fa-list px-2"></i>
                    Events
                </NavLink>
                <NavLink
                    className={({ isActive }) =>
                        `${isActive ? "current-menu-item" : ""} nav-link text-dark fw-bold`
                    }
                    to="/user/blogs"
                >
                    <i className="fa-solid fa-list px-2"></i>
                    Blogs
                </NavLink>
                <NavLink
                    className={({ isActive }) =>
                        `${isActive ? "current-menu-item" : ""} nav-link text-dark fw-bold`
                    }
                    to="/user/news"
                >
                    <i className="fa-solid fa-list px-2"></i>
                    News
                </NavLink>
                <NavLink
                    className={({ isActive }) =>
                        `${isActive ? "current-menu-item" : ""} nav-link text-dark fw-bold`
                    }
                    to="/user/gallery"
                >
                    <i className="fa-solid fa-list px-2"></i>
                    Gallery
                </NavLink>
                <br />
                {!isDonor && (
                    <div className="header-btn d-inline-block">
                        <NavLink to={"#"} className="button-round" onClick={handleUpgradeClick}>
                            Become Donor
                        </NavLink>
                    </div>
                )}
            </nav>
            <RoleUpgradeModel isOpen={isModalOpen} toggleModal={toggleModal} />
        </>
    )
}

export default UserSiderBar