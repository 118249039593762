import { jwtDecode as jwt_decode } from 'jwt-decode';
;

export const ROLES = {
  Admin: "admin",
  Volunteer: "volunteer",
  SkilledPerson: "skilled person"
};

export const emailrgx =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,6}))$/;

export const pinCodergx = /^[1-9][0-9]{5}$/

export const getUserInfoFromToken = () => {
  const data = JSON.parse(localStorage.getItem("user"));
  const token = data?.token
  if (token) {
    const decodedToken = jwt_decode(token)
    const userId = decodedToken.userId;
    const roleName = data.roleName;
    const email = decodedToken.email;
    const expirationTimeInSeconds = decodedToken.exp;
    const expirationDate = new Date(expirationTimeInSeconds * 1000); 
    const expirationTime = expirationDate.toLocaleString();
    return { userId, roleName, email, expirationTime };
  }
  return { userId: null, roleName: null, email: null, expirationTime: null };
};

export const linkedinrgx =  /([\w]+\.)?linkedin\.com\/in\/[A-z0-9_-]+\/?/;
export const facebookrgx = /(?:https?:\/\/)?(?:www\.)?facebook\.com\/(?:(?:\w)*#!\/)?(?:pages\/)?(?:[?\w-]*\/)?(?:profile\.php\?id=(\d.*))?([\w-]*)?/;
export const youtubergx = /^https?:\/\/(www\.)?youtube\.com\/(channel\/[a-zA-Z0-9_-]+|c\/[a-zA-Z0-9_-]+|user\/[a-zA-Z0-9_-]+|@[a-zA-Z0-9_-]+)(\/.*)?$/;
export const instagramrgx = /\bhttps?:\/\/(?:www\.)?instagram\.com\/(?:[a-zA-Z0-9_]+\/?)\b/;
export const twitterrgx = /^https?:\/\/(www\.)?x\.com\/([a-zA-Z0-9_]{1,15})\/?$/;

export const rewriteUrl = (url) => {
  // Mapping of special characters to their replacements
  const charMap = {
    ' ': '-20',
    '!': '-21',
    '"': '-22',
    '#': '-23',
    '$': '-24',
    '%': '-25',
    '&': '-26',
    '\'': '-27',
    '(': '-28',
    ')': '-29',
    '*': '-2A',
    '+': '-2B',
    ',': '-2C',
    '/': '-2F',
    ':': '-3A',
    ';': '-3B',
    '<': '-3C',
    '=': '-3D',
    '>': '-3E',
    '?': '-3F',
    '@': '-40',
    '[': '-5B',
    '\\': '-5C',
    ']': '-5D',
    '^': '-5E',
    '_': '-5F',
    '`': '-60',
    '{': '-7B',
    '|': '-7C',
    '}': '-7D',
    '~': '-7E'
  };

  // Replace special characters
  return url.split('').map(char => charMap[char] || char).join('');
};