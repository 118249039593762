import React from "react";
import { Link, useNavigate } from "react-router-dom";
import * as yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { forgotPassword } from "../../Slice/authLogin";
import innerBannerImg1 from '../../Assets/Images/Event-bg-01-01.jpg';
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { emailrgx } from "../../Components/constant/Constant";
import Loader from "../../Components/Loader";
import { Helmet } from "react-helmet";

const schema = yup
    .object({
        email: yup
            .string()
            .matches(emailrgx, "Invalid Email")
            .required("Email is required")
            .trim(),
    })
    .required();

const ForgotPassword = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { isLoading } = useSelector((state) => state.userLogin);

    const {
        handleSubmit,
        control,
        reset,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(schema),
    });

    const onSubmit = async (data) => {
        const requestData = {
            email: data?.email
        };
        dispatch(forgotPassword(requestData, reset, navigate));
    };

    return (
        <>
            {isLoading ? <Loader /> : (
                <main id="content" className="site-main">
                    <Helmet>
                        <title>Forgot Password | Nangal by Cycle Account Recovery</title>
                        <meta name="description" content="Reset your Nangal by Cycle account password easily. Follow the steps to recover access to your account and continue supporting our mission." />
                    </Helmet>
                    <section className="inner-banner-wrap pb-0">
                        <div className="inner-baner-container" style={{ backgroundImage: `url(${innerBannerImg1})` }}>
                            <div className="container">
                                <div className="inner-banner-content">
                                    <h1 className="inner-title">Forgot Password</h1>
                                </div>
                            </div>
                        </div>
                    </section>
                    <div className="volunteer-wrap">
                        <div className="container">
                            <div className="row mt-5">
                                <div className="col-lg-8 offset-lg-2">
                                    <div className="volunteer-contact-form">
                                        <form onSubmit={handleSubmit(onSubmit)} className="volunteer-form">
                                            <div className="row">
                                                <div className="col-lg-12 col-md-12 col-sm-12 col-12 form-group">
                                                    <label className="text-left">Email Address <span style={{ color: '#F15B43' }}>*</span></label>
                                                    <Controller
                                                        name="email"
                                                        control={control}
                                                        render={({ field: { value, onChange } }) => (
                                                            <input
                                                                className="input_fixed_width"
                                                                type="text"
                                                                value={value}
                                                                onChange={onChange}
                                                            />
                                                        )}
                                                        defaultValue=""
                                                    />
                                                    {errors?.email && (
                                                        <p style={{ color: "red" }}> {errors?.email.message}</p>
                                                    )}
                                                </div>
                                                <div className="submit-area col-lg-12 col-12">
                                                    <Link to={"/auth/login"} style={{ borderRadius: 6 }} className='button-back button-round' type="submit"> Cancel </Link>
                                                    <button style={{ borderRadius: 6 }} className='button-round' type="submit"> Send OTP </button>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </main>
            )}
        </>
    );
};

export default ForgotPassword;
