import React, { useEffect } from "react";
import Masonry from "masonry-layout";
import { Link } from "react-router-dom";
import innerBannerImg1 from "../Assets/Images/Event-bg-01-01.jpg";
import Loader from "../Components/Loader";
import Fancybox from "../Components/FancyBox";
import { useDispatch } from "react-redux";
import { getAllImages } from "../Slice/gallery";
import { useSelector } from "react-redux";
import { Helmet } from "react-helmet";

function Gallery() {
  const dispatch = useDispatch();
  const { allImages, isLoading } = useSelector((state) => state.image);

  useEffect(() => {
    dispatch(getAllImages());
  }, [dispatch]);

  useEffect(() => {
    function masonryLayout() {
      const grid = document.querySelector(".grid");
      new Masonry(grid, {
        itemSelector: ".grid-item",
      });
    }
    const delay = 3000;
    const timeoutId = setTimeout(masonryLayout, delay);
    return () => clearTimeout(timeoutId);
  }, []);

  const buttonStyle = {
    display: "inline-block",
    backgroundColor: "#f15b43",
    color: "#fff",
    border: "none",
    padding: "10px 20px",
    fontSize: "16px",
    borderRadius: "5px",
    textDecoration: "none",
    textAlign: "center",
    transition: "background-color 0.3s ease",
    marginTop: "20px", // Add some margin to space it from the title
    cursor: "pointer",
  };

  const buttonHoverStyle = {
    backgroundColor: "#c04a35",
  };

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <main id="content" className="site-main">
          <Helmet>
            <title>Nangal By Cycle Photo Gallery | Captured Moments on Two Wheels</title>
            <meta
              name="description"
              content="Browse our gallery of breathtaking views, happy cyclists, and memorable moments from Nangal By Cycle tours and adventures."
            />
          </Helmet>
          <section className="inner-banner-wrap">
            <div
              className="inner-baner-container"
              style={{ backgroundImage: `url(${innerBannerImg1})` }}
            >
              <div className="container">
                <div className="inner-banner-content">
                  <h1 className="inner-title">Gallery</h1>
                </div>
              </div>
            </div>
          </section>
          <div className="gallery-section">
            <div className="container">
              <div className="gallery-button-wrap" style={{ textAlign: "center" }}>
                <Link
                  to="/artgallery"
                  style={buttonStyle}
                  onMouseOver={(e) =>
                    (e.currentTarget.style.backgroundColor = buttonHoverStyle.backgroundColor)
                  }
                  onMouseOut={(e) =>
                    (e.currentTarget.style.backgroundColor = buttonStyle.backgroundColor)
                  }
                >
                  View Art Gallery Pictures
                </Link>
              </div>
              <div className="gallery-outer-wrap" style={{ marginTop: "30px" }}>
                <div className="gallery-inner-wrap gallery-container grid">
                  <Fancybox
                    options={{
                      Carousel: {
                        infinite: false,
                      },
                    }}
                  >
                    {allImages ? (
                      allImages?.map((image) => (
                        <div className="single-gallery grid-item" key={image?.image_id}>
                          <figure className="gallery-img">
                            <Link
                              target="_blank"
                              to={image.image_url}
                              data-fancybox="gallery"
                              data-caption={`Credit To: ${image.uploaded_by}`}
                            >
                              <img src={image.image_url} alt={image?.title} />
                            </Link>
                          </figure>
                        </div>
                      ))
                    ) : (
                      <div>Loading images...</div>
                    )}
                  </Fancybox>
                </div>
              </div>
            </div>
          </div>
        </main>
      )}
    </>
  );
}

export default Gallery;
