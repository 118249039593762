import React, { useEffect, useState } from 'react'
import unboundLogo from '../../Assets/Images/unbound-logo.png';
import { Link, NavLink, useNavigate } from 'react-router-dom';
import TranslateComponent from '../TranslateComponent';
import { Dropdown } from 'react-bootstrap';
import genericImg from '../../Assets/Images/Generic-img.png';
import { useDispatch } from 'react-redux';
import { logoutUser } from '../../Slice/authLogin';
import { getUserInfoFromToken } from '../constant/Constant';

const UserHeader = ({ mobiletoggleNav }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [user, setUser] = useState(JSON.parse(localStorage.getItem('user')));
    const [isNavVisible, setIsNavVisible] = useState(false);
    const toggleNav = () => setIsNavVisible(!isNavVisible);
    const handleLogout = () => dispatch(logoutUser(navigate));

    const [showCommunity, setShowCommunity] = useState(false);
    const [showMedia, setShowMedia] = useState(false);
    const [showDiscover, setShowDiscover] = useState(false);

    const toggleCommunity = () => setShowCommunity(!showCommunity);
    const toggleMedia = () => setShowMedia(!showMedia);
    const toggleDiscover = () => setShowDiscover(!showDiscover);

    const updateProfileFromStorage = () => {
        const user = JSON.parse(localStorage.getItem('user'));
        setUser(user);
    };

    useEffect(() => {
        updateProfileFromStorage();
        window.addEventListener('storage', updateProfileFromStorage);
        return () => window.removeEventListener('storage', updateProfileFromStorage);
    }, []);

    const userInfo = getUserInfoFromToken();
    const role = userInfo?.roleName

    const hasSkilledPersonRole = Array.isArray(role) ? role.includes('skilled person') : role === 'skilled person';

    return (
        <>
            <header id="masthead" className="container-fluid site-header position-relative site-header-transparent" style={{ background: "#121213" }}>
                <div className="top-header">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-8 d-none d-lg-block">
                                <div className="header-contact-info">
                                    <ul className='d-none'>
                                        <li>
                                            <Link to="tel:+01-977-2599-12"><i className="fas fa-phone-alt"></i> +01 (977) 2599 12</Link>
                                        </li>
                                        <li>
                                            <Link to="#"><i className="fas fa-envelope"></i> <span className="__cf_email__" data-cfemail="#">[email&nbsp;protected]</span></Link>
                                        </li>
                                        <li>
                                            <i className="fas fa-map-marker-alt"></i> 3146 Koontz Lane, California
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-lg-4 d-flex justify-content-lg-end justify-content-between">
                                <div className="header-social social-links">
                                    <ul>
                                        <li>
                                            <Link to="https://www.facebook.com/nangalbycycle" target="_blank">
                                                <i className="fab fa-facebook-f" aria-hidden="true"></i>
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to="https://x.com/nangalbycycle" target="_blank">
                                                <i className="fab fa-x-twitter" aria-hidden="true"></i>
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to="https://www.youtube.com/@Nangalbycycle" target="_blank">
                                                <i className="fab fa-youtube" aria-hidden="true"></i>
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to="https://www.instagram.com/nangalbycycle" target="_blank">
                                                <i className="fab fa-instagram" aria-hidden="true"></i>
                                            </Link>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <TranslateComponent />
                <div className="bottom-header">
                    <div className="container d-flex justify-content-between align-items-center">
                        <div className="site-identity">
                            <h1 className="site-title">
                                <Link to="/">
                                    <img className="white-logo" src={unboundLogo} alt="logo" />
                                </Link>
                            </h1>
                        </div>
                        <div className="main-navigation">
                            <nav id="navigation" className="navigation d-none d-lg-inline-block">
                                <ul>
                                    <li>
                                        <NavLink
                                            className={({ isActive }) =>
                                                `${isActive ? "current-menu-item" : ""}`
                                            }
                                            to="/home"
                                        >
                                            Home
                                        </NavLink>
                                    </li>
                                    <li className="menu-item-has-children">
                                        <NavLink to="#">Community Members</NavLink>
                                        <ul>
                                            <li>
                                                <NavLink
                                                    className={({ isActive }) =>
                                                        isActive ? "current-menu-item" : ""
                                                    }
                                                    to="/volunteers"
                                                >
                                                    Volunteers
                                                </NavLink>
                                            </li>
                                            <li>
                                                <NavLink
                                                    className={({ isActive }) =>
                                                        isActive ? "current-menu-item" : ""
                                                    }
                                                    to="/donor"
                                                >
                                                    Blood Donors
                                                </NavLink>
                                            </li>
                                            <li><NavLink to="/skilled-persons">NBC Members</NavLink></li>
                                            <li><NavLink to="/nangal-heroes">Nangal Heroes</NavLink></li>
                                            <li><NavLink to="/become-volunteer">Become a Volunteer</NavLink></li>
                                            <li><NavLink to="/become-donor">Become a Blood Donor</NavLink></li>
                                            <li><NavLink to="/become-nbc-member">Become an NBC Member</NavLink></li>
                                        </ul>
                                    </li>
                                    <li className="menu-item-has-children">
                                        <NavLink to="#">Media & Events</NavLink>
                                        <ul>
                                            <li>
                                                <NavLink
                                                    className={({ isActive }) =>
                                                        isActive ? "current-menu-item" : ""
                                                    }
                                                    to="/event-archive"
                                                >
                                                    Events
                                                </NavLink>
                                            </li>
                                            <li>
                                                <NavLink
                                                    className={({ isActive }) => (isActive ? "current-menu-item" : "")}
                                                    to="/blog-archive"
                                                >
                                                    Blogs
                                                </NavLink>
                                            </li>
                                            <li>
                                                <NavLink
                                                    className={({ isActive }) =>
                                                        isActive ? "current-menu-item" : ""
                                                    }
                                                    to="/gallery"
                                                >
                                                    Gallery
                                                </NavLink>
                                            </li>
                                        </ul>
                                    </li>
                                    <li className="menu-item-has-children">
                                        <NavLink to="#">Discover Us</NavLink>
                                        <ul>
                                            <li>
                                                <Link to={"/about"}>
                                                    <span className="semi-bold">Who We Are</span>
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to={"/team"}>
                                                    <span className="semi-bold">Team</span>
                                                </Link>
                                            </li>

                                        </ul>
                                    </li>
                                </ul>
                            </nav>
                        </div>
                        <div className="header-btn d-inline-block">
                            <NavLink to="/contact" className="button-round">
                                Contact Us
                            </NavLink>
                        </div>
                        <Dropdown >
                            <Dropdown.Toggle id="dropdown-basic" className='btn-dark'>
                                <img src={user?.userProfile ? user?.userProfile : genericImg} alt='profiile' width="40" height="40" className="user_profile_image" />
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                                <Dropdown.Item><Link to="/user/edit-profile">
                                    <i className="fa-solid fa-user px-2"></i>
                                    Edit Profile
                                </Link>
                                </Dropdown.Item>
                                <Dropdown.Item><Link to="/user/socialMedia-links">
                                    <i className="fa-solid fa-users px-2"></i>
                                    Social Media</Link>
                                </Dropdown.Item>
                                {hasSkilledPersonRole && (
                                    <Dropdown.Item>
                                        <Link to="/member/my/specializedskill">
                                            <i className="fa-solid fa-lightbulb px-2"></i>
                                            Specialized Skill
                                        </Link>
                                    </Dropdown.Item>
                                )}
                                <Dropdown.Item><Link to="/password/change-password">
                                    <i className="fa-solid fa-key px-2"></i>
                                    Change Password</Link>
                                </Dropdown.Item>
                                <Dropdown.Item onClick={handleLogout} style={{ color: 'red' }}>
                                    <i className="fa-solid fa-arrow-right-from-bracket px-2"></i>
                                    Log Out
                                </Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                    </div>
                </div>
                <div className="mobile-menu-container">
                    <div className="slicknav_menu">
                        <Link onClick={toggleNav} to="#" aria-haspopup="true" role="button" tabIndex="0" className="slicknav_btn slicknav_collapsed" style={{ outline: "none" }}>
                            <span className="slicknav_menutxt">Menu</span>
                            <span className="slicknav_icon">
                                <span className="slicknav_icon-bar"></span>
                                <span className="slicknav_icon-bar"></span>
                                <span className="slicknav_icon-bar"></span>
                            </span>
                        </Link>
                        <nav id="navigation" className={`slicknav_nav navigation ${isNavVisible ? "d-block" : "d-none"} d-lg-inline-block`}>
                            <ul>
                                <li>
                                    <NavLink
                                        className={({ isActive }) =>
                                            `${isActive ? "current-menu-item" : ""}`
                                        }
                                        to="/home"
                                    >
                                        Home
                                    </NavLink>
                                </li>
                                <li className="menu-item-has-children slicknav_parent slicknav_collapsed">
                                    <span className="slicknav_parent-link slicknav_row">
                                        <NavLink to="#">Community Members</NavLink>
                                        <Link onClick={toggleCommunity} to="#" role="menuitem" aria-haspopup="true" tabIndex="-1" className="slicknav_item" style={{ outline: "none" }}>
                                            <span className="slicknav_arrow">
                                                {!showCommunity ? (
                                                    <i className="fas fa-plus"></i>
                                                ) : (
                                                    <i className="fas fa-minus"></i>
                                                )}
                                            </span>
                                        </Link>
                                    </span>
                                    <ul role="menu" className="slicknav_hidden" aria-hidden="true" style={{
                                        height: showCommunity ? '315px' : '0px',
                                        overflow: 'hidden',
                                        transition: 'height 1s ease'
                                    }} >
                                        <li>
                                            <NavLink
                                                className={({ isActive }) =>
                                                    isActive ? "current-menu-item" : ""
                                                }
                                                to="/volunteers"
                                                onClick={mobiletoggleNav}
                                            >
                                                Volunteers
                                            </NavLink>
                                        </li>
                                        <li>
                                            <NavLink
                                                className={({ isActive }) =>
                                                    isActive ? "current-menu-item" : ""
                                                }
                                                to="/donor"
                                                onClick={mobiletoggleNav}
                                            >
                                                Donors
                                            </NavLink>
                                        </li>
                                        <li><NavLink to="/skilled-persons" onClick={mobiletoggleNav}>NBC Members</NavLink></li>
                                        <li><NavLink to="/nangal-heroes" onClick={mobiletoggleNav}>Nangal Heroes</NavLink></li>
                                        <li><NavLink to="/become-volunteer" onClick={mobiletoggleNav}>Become a volunteer</NavLink></li>
                                        <li><NavLink to="/become-donor" onClick={mobiletoggleNav}>Become a Blood Donor</NavLink></li>
                                        <li><NavLink to="/become-nbc-member" onClick={mobiletoggleNav}>Become an NBC Member</NavLink></li>
                                    </ul>
                                </li>
                                <li className="menu-item-has-children slicknav_parent slicknav_collapsed">
                                    <span className="slicknav_parent-link slicknav_row">
                                        <NavLink to="#">Media & Events</NavLink>
                                        <Link onClick={toggleMedia} to="#" role="menuitem" aria-haspopup="true" tabIndex="1" className="slicknav_item" style={{ outline: "none" }}>
                                            <span className="slicknav_arrow">
                                                {!showMedia ? (
                                                    <i className="fas fa-plus"></i>
                                                ) : (
                                                    <i className="fas fa-minus"></i>
                                                )}
                                            </span>
                                        </Link>
                                    </span>
                                    <ul role="menu" className="slicknav_hidden" aria-hidden="true" style={{
                                        height: showMedia ? '135px' : '0px',
                                        overflow: 'hidden',
                                        transition: 'height 1s ease'
                                    }} >
                                        <li>
                                            <NavLink
                                                className={({ isActive }) =>
                                                    isActive ? "current-menu-item" : ""
                                                }
                                                to="/event-archive"
                                                onClick={mobiletoggleNav}
                                            >
                                                Events
                                            </NavLink>
                                        </li>
                                        <li>
                                            <NavLink
                                                className={({ isActive }) => (isActive ? "current-menu-item" : "")}
                                                to="/blog-archive"
                                                onClick={mobiletoggleNav}
                                            >
                                                Blogs
                                            </NavLink>
                                        </li>
                                        <li>
                                            <NavLink
                                                className={({ isActive }) =>
                                                    isActive ? "current-menu-item" : ""
                                                }
                                                to="/gallery"
                                                onClick={mobiletoggleNav}
                                            >
                                                Gallery
                                            </NavLink>
                                        </li>
                                    </ul>
                                </li>
                                <li className="menu-item-has-children slicknav_parent slicknav_collapsed">
                                    <span className="slicknav_parent-link slicknav_row">
                                        <NavLink to="#">Discover Us</NavLink>
                                        <Link onClick={toggleDiscover} to="#" role="menuitem" aria-haspopup="true" tabIndex="1" className="slicknav_item" style={{ outline: "none" }}>
                                            <span className="slicknav_arrow">
                                                {!showDiscover ? (
                                                    <i className="fas fa-plus"></i>
                                                ) : (
                                                    <i className="fas fa-minus"></i>
                                                )}
                                            </span>
                                        </Link>
                                    </span>
                                    <ul role="menu" className="slicknav_hidden" aria-hidden="true" style={{
                                        height: showDiscover ? '135px' : '0px',
                                        overflow: 'hidden',
                                        transition: 'height 1s ease'
                                    }} >
                                        <li>
                                            <NavLink
                                                className={({ isActive }) =>
                                                    isActive ? "current-menu-item" : ""
                                                }
                                                to="/about"
                                                onClick={toggleNav}
                                            >
                                                Who We Are
                                            </NavLink>
                                        </li><li>
                                            <NavLink
                                                className={({ isActive }) =>
                                                    isActive ? "current-menu-item" : ""
                                                }
                                                to="/team"
                                                onClick={toggleNav}
                                            >
                                                Team
                                            </NavLink>
                                        </li>
                                        <li>
                                            <NavLink
                                                className={({ isActive }) =>
                                                    isActive ? "current-menu-item" : ""
                                                }
                                                to="/contact"
                                                onClick={toggleNav}
                                            >
                                                Contact Us
                                            </NavLink>
                                        </li>
                                    </ul>
                                </li>
                            </ul>
                        </nav>
                    </div>
                </div>
            </header>
        </>
    )
}

export default UserHeader