import React from "react";
import * as yup from "yup";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import innerBannerImg1 from '../../Assets/Images/Event-bg-01-01.jpg';
import { useDispatch, useSelector } from "react-redux";
import { resetPassword } from "../../Slice/authLogin";
import { Link, useNavigate, useParams } from "react-router-dom";
import Loader from "../../Components/Loader";

const schema = yup
    .object({
        otp: yup
            .number()
            .typeError("OTP must be a number")
            .required("Otp is required"),
        password: yup.string().required("Password is required").trim(),
        cpassword: yup
            .string()
            .oneOf([yup.ref("password"), null], "Password must match")
            .required("ConfirmPassword is required")
            .trim(),
    })
    .required();

const ResetPassword = () => {
    const { id } = useParams();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { isLoading } = useSelector((state) => state.userLogin);

    const {
        handleSubmit,
        control,
        reset,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(schema),
    });

    const onSubmit = async (data) => {
        const otp = data?.otp
        const requestData = {
            userId: id,
            otp: parseInt(otp, 10),
            password: data?.password,
        };
        dispatch(resetPassword(requestData, reset, navigate));
    };

    return (
        <>
            {isLoading ? <Loader /> : (
                <main id="content" className="site-main">
                    <section className="inner-banner-wrap pb-0">
                        <div className="inner-baner-container" style={{ backgroundImage: `url(${innerBannerImg1})` }}>
                            <div className="container">
                                <div className="inner-banner-content">
                                    <h1 className="inner-title">Reset Password</h1>
                                </div>
                            </div>
                        </div>
                    </section>
                    <div className="volunteer-wrap">
                        <div className="container">
                            <div className="row mt-5">
                                <div className="col-lg-8 offset-lg-2">
                                    <div className="volunteer-contact-form">
                                        <p className={`fs-3 fst-normal font-monospace`}>Reset your Password</p>
                                        <form onSubmit={handleSubmit(onSubmit)} className="volunteer-form">
                                            <div className="row">
                                                <div className="col-lg-12 col-md-12 col-sm-12 col-12 form-group">
                                                    <label className="text-left">OTP <span style={{ color: '#F15B43' }}>*</span></label>
                                                    <Controller
                                                        name="otp"
                                                        control={control}
                                                        render={({ field: { value, onChange } }) => (
                                                            <input
                                                                className="input_fixed_width"
                                                                type="text"
                                                                value={value}
                                                                onChange={onChange}
                                                            />
                                                        )}
                                                        defaultValue=""
                                                    />
                                                    {errors?.otp && (
                                                        <p style={{ color: "red" }}> {errors?.otp?.message}</p>
                                                    )}
                                                </div>
                                                <div className="col-lg-12 col-md-12 col-sm-12 col-12 form-group">
                                                    <label className="text-left">Password <span style={{ color: '#F15B43' }}>*</span></label>
                                                    <Controller
                                                        name="password"
                                                        control={control}
                                                        render={({ field: { value, onChange } }) => (
                                                            <input
                                                                className="input_fixed_width"
                                                                type="password"
                                                                value={value}
                                                                onChange={onChange}
                                                            />
                                                        )}
                                                        defaultValue=""
                                                    />
                                                    {errors?.password && (
                                                        <p style={{ color: "red" }}> {errors?.password?.message}</p>
                                                    )}
                                                </div>
                                                <div className="col-lg-12 col-md-12 col-sm-12 col-12 form-group">
                                                    <label className="text-left">Confirm Password <span style={{ color: '#F15B43' }}>*</span></label>
                                                    <Controller
                                                        name="cpassword"
                                                        control={control}
                                                        render={({ field: { value, onChange } }) => (
                                                            <input
                                                                className="input_fixed_width"
                                                                type="password"
                                                                value={value}
                                                                onChange={onChange}
                                                            />
                                                        )}
                                                        defaultValue=""
                                                    />
                                                    {errors?.cpassword && (
                                                        <p style={{ color: "red" }}> {errors?.cpassword?.message}</p>
                                                    )}
                                                </div>
                                                <div className="submit-area col-lg-12 col-12">
                                                    <Link to={"/auth/login"} style={{ borderRadius: 6 }} className='button-back button-round' type="submit"> Cancel </Link>
                                                    <button style={{ borderRadius: 6 }} className='button-round' type="submit"> Update Password </button>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </main>
            )}
        </>
    );
};

export default ResetPassword;
