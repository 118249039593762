import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAllAuditLogs } from "../../Slice/master";
import Loader from "../../Components/Loader";
import { Table } from "antd";
import moment from "moment";
import { getUserInfoFromToken } from "../../Components/constant/Constant";

const AuditLogs = () => {
    const user = getUserInfoFromToken();
    const dispatch = useDispatch();
    const { isLoading, auditLogs, auditLogsCount } = useSelector((state) => state.masterSlice);

    const [state, setState] = useState({
        search: "",
        page: 1,
        pagesize: 10,
    });

    useEffect(() => {
        dispatch(getAllAuditLogs(state.page, state.pagesize, state.search, user.userId));
    }, [dispatch, state.search, state.page, state.pagesize, user.userId]);

    const onShowSizeChange = (current, pageSize) => {
        setState((prevState) => ({ ...prevState, page: 1, pagesize: pageSize }));
    };

    const itemRender = (current, type, originalElement) => {
        if (type === "prev") return <button className="btn btn-sm btn-primary">Previous</button>;
        if (type === "next") return <button className="btn btn-sm btn-primary">Next</button>;
        return originalElement;
    };

    const formatDate = (day) => moment(day).format("MMMM Do, YYYY, h:mm:ss a");

    // Function to compare old and new data and return the differences
    const getUpdatedData = (record) => {
        const oldData = JSON.parse(record.old_data);
        const newData = JSON.parse(record.new_data);
        const updatedColumns = [];
        const oldValues = [];
        const newValues = [];

        for (const key in oldData) {
            if (oldData[key] !== newData[key]) {
                updatedColumns.push(key);

                let oldValue = oldData[key];

                if (typeof oldValue === 'object')
                    oldValues.push(oldValue ? oldValue : 'null');

                else
                    oldValues.push(oldValue);

                newValues.push(newData[key]);
            }
        }

        return {
            updatedColumns: updatedColumns.length ? updatedColumns.join(', ') : null,
            oldValues: oldValues.length ? oldValues.join(', ') : null,
            newValues: newValues.length ? newValues.join(', ') : null,
        };
    };

    const columns = [
        {
            title: "#",
            width: 50,
            render: (text, record, index) => index + 1,
        },
        {
            title: "Action",
            width: 100,
            dataIndex: 'action',
            sorter: (a, b) => a.action.length - b.action.length,
        },
        {
            title: "Table Name",
            width: 100,
            dataIndex: 'table_name',
            sorter: (a, b) => a.table_name.length - b.table_name.length,
        },
        {
            title: "Record Id",
            width: 100,
            dataIndex: 'record_id',
            sorter: (a, b) => a.record_id.length - b.record_id.length,
        },
        {
            title: "Entry Time",
            render: (text, record) => (
                formatDate(record.timestamp)
            ),
            width: 150,
        },
        {
            title: "Updated Fields",
            width: 200,
            render: (text, record) => {
                const { updatedColumns } = getUpdatedData(record);
                return updatedColumns;
            },
        },
        {
            title: "Old Values",
            width: 250,
            render: (text, record) => {
                const { oldValues } = getUpdatedData(record);
                return oldValues;
            },
        },
        {
            title: "New Values",
            width: 250,
            render: (text, record) => {
                const { newValues } = getUpdatedData(record);
                return newValues;
            },
        },
    ];

    return (
        <div className="container-fluid mt-2">
            <div className="row justify-content-between align-items-center all_donor_header mb-2">
                <div className="col-auto">
                    <h1 className="h2">Audit Logs</h1>
                </div>
            </div>
            <div className="row">
                <div className="col-md-3 col-lg-3 col-sm-3 mb-2 mb-lg-2">
                    <input
                        type="text"
                        className="form-control"
                        placeholder="Search"
                        value={state.search}
                        onChange={(e) => setState({ ...state, search: e.target.value })}
                    />
                </div>
                {isLoading ? (
                    <Loader />
                ) : (
                    <Table
                        pagination={{
                            current: state.page,
                            pageSize: state.pagesize,
                            total: auditLogsCount,
                            showTotal: (total, range) => `Showing ${range[0]} to ${range[1]} of ${total} entries`,
                            showSizeChanger: true,
                            onShowSizeChange: onShowSizeChange,
                            itemRender: itemRender,
                            onChange: (page, pageSize) => setState({ ...state, page, pagesize: pageSize }),
                        }}
                        bordered
                        style={{ overflowX: "auto" }}
                        columns={columns}
                        dataSource={auditLogs}
                        rowKey={(record) => record.id}
                        scroll={{ y: 500 }}
                    />
                )}
            </div>
        </div>
    );
};

export default AuditLogs;
