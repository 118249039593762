import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import innerBannerImg1 from "../../Assets/Images/Event-bg-01-01.jpg";
import { getAllVolunteers } from "../../Slice/volunteers";
import genericImg from "../../Assets/Images/Generic-img.png";
import Loader from "../../Components/Loader";
import { getAllVillages } from "../../Slice/master";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import { numberToString } from "../../utils/encrypt";

const Volunteers = () => {
  const isActive = true;
  const dispatch = useDispatch();
  const { volunteers, isLoading, totalCount } = useSelector((state) => state.user);
  const { villages } = useSelector((state) => state.masterSlice);
  const [state, setState] = useState({
    search: "",
    selectedVillage: "",
    pageSize: 8,
    page: 1,
  });

  const villageOptions = villages?.map((village) => ({
    value: village.villageId,
    label: village.villageName,
  }));
  villageOptions.unshift({ value: "null", label: "Select Village" });

  useEffect(() => {
    dispatch(getAllVolunteers(state.search, state.page, state.pageSize, state.selectedVillage, isActive));
    dispatch(getAllVillages());
  }, [dispatch, state.page, state.pageSize]);


  const handleSearch = () => {
    setState({ ...state, page: 1 });
    dispatch(getAllVolunteers(state.search, state.page, state.pageSize, state.selectedVillage, isActive));
  };

  const handleViewMore = () => {
    setState((prevState) => ({
      ...prevState,
      pageSize: prevState.pageSize + state.pageSize,
    }));
  };

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <main id="content" className="site-main bg-light-grey">
          <Helmet>
            <title>Volunteer with Nangal By Cycle | Join Our Eco-Friendly Cycling Community</title>
            <meta name="description" content="Make a difference by volunteering with Nangal By Cycle. Help promote sustainable tourism, lead tours, or support our community initiatives. Join our team today!" />
          </Helmet>
          <section className="inner-banner-wrap">
            <div
              className="inner-baner-container"
              style={{ backgroundImage: `url(${innerBannerImg1})` }}
            >
              <div className="container">
                <div className="inner-banner-content">
                  <h1 className="inner-title">Volunteer</h1>
                </div>
              </div>
            </div>
          </section>
          <form className="container main-contact-info-item">
            <div className="row">
              <div className="col-lg-4 col-sm-4">
                <div className="form-group mb-2">
                  <select
                    className="form-select form-control"
                    aria-label="Default select example"
                    value={state.selectedVillage}
                    onChange={(e) =>
                      setState({
                        ...state,
                        selectedVillage: e.target.value === "null" ? "" : e.target.value,
                      })
                    }
                  >
                    {villageOptions?.map((option) => (
                      <option
                        key={option.value}
                        value={option.value}
                        disabled={option.isDisabled}
                      >
                        {option.label}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              <div className="col-lg-4 col-sm-4">
                <div className="form-group mb-2">
                  <input
                    type="text"
                    className="form-control bg-white"
                    value={state.search}
                    onChange={(e) =>
                      setState({ ...state, search: e.target.value })
                    }
                    placeholder="Search Name"
                  />
                </div>
              </div>
              <div className="col-lg-4 col-sm-4">
                <div className="form-group mb-2">
                  <button
                    type="button"
                    className="btn btn-primary w-100 form-control btn-lg btn-block"
                    style={{ background: "#f15b44" }}
                    onClick={handleSearch}
                  >
                    Search Volunteer
                  </button>
                </div>
              </div>
            </div>
          </form>
          <div className="team-page-section">
            <div className="container">
              <div className="row">
                {volunteers.length > 0 ? (
                  volunteers?.map((element, index) => (
                    <div className="col-lg-6" key={index}>
                      <article className="team-item volunteer_card">
                        <figure className="team-img">
                          <img
                            src={
                              element.userProfile
                                ? element.userProfile
                                : genericImg
                            }
                            alt=""
                          />
                        </figure>
                        <div className="team-content">
                          <h3>{element?.name}</h3>
                          <span>{element?.profession}</span>
                          <span>
                            <i className="me-1 fas fa-map-marker-alt" style={{ color: '#CB4B36' }}></i>
                            {element?.village}</span>
                          <>
                            <span>
                              <div className="d-flex">
                                <i
                                  className="me-1 fas fa-envelope"
                                  style={{ color: '#CB4B36' }}
                                ></i>
                                <Link to="#" className="text-break">
                                  {element?.email}
                                </Link>
                              </div>
                            </span>
                          </>
                          <div className="social-links">
                            <Link
                              to={`/vol-details/${numberToString(element?.volunteerId)}`}
                              className="button-round px-4 py-3 text-white"
                            >
                              View Details
                            </Link>
                          </div>
                        </div>
                      </article>
                    </div>
                  ))
                ) : (
                  <div className="text-center">
                    <h3>No items found</h3>
                  </div>
                )}
                {((state.page * state.pageSize) < totalCount) && (
                  <div div className="text-center" >
                    <button onClick={handleViewMore} className="button-round">View More</button>
                  </div>
                )}
              </div>
            </div>
          </div>

        </main >
      )}
    </>
  );
};

export default Volunteers;
