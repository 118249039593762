import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import * as yup from "yup";
import { loginUser } from "../../Slice/authLogin";
import innerBannerImg1 from "../../Assets/Images/Event-bg-01-01.jpg";
import Loginbg from "../../Assets/Images/Loginbg.jpg";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { emailrgx } from "../../Components/constant/Constant";
import Loader from "../../Components/Loader";
import { Helmet } from "react-helmet";

const schema = yup
  .object({
    email: yup.string().required("Email is required").matches(emailrgx, "Invalid Email").trim(),
    password: yup.string().required("Password is required").trim(),
  })
  .required();

const Login = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [eye, seteye] = useState(true);
  const onEyeClick = () => seteye(!eye);
  const { isLoading } = useSelector((state) => state.userLogin);

  const {
    handleSubmit,
    register,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmit = (data) => {
    dispatch(loginUser(data, navigate, reset));
  };

  return (
    <>
      {isLoading ? <Loader /> : (
        <main id="content" className="site-main">
          <Helmet>
            <title>Nangal By Cycle Member Login | Access Your Account</title>
            <meta name="description" content="Secure login page for Nangal By Cycle members. Access your account to manage bookings, update your profile, or contribute to our community." />
          </Helmet>
          <section className="inner-banner-wrap pb-0">
            <div
              className="inner-baner-container"
              style={{ backgroundImage: `url(${innerBannerImg1})` }}
            >
              <div className="container">
                <div className="inner-banner-content">
                  <h1 className="inner-title">Sign into your account</h1>
                </div>
              </div>
            </div>
          </section>
          <div className="volunteer-wrap" style={{ backgroundImage: `url(${Loginbg})` }}>
            <div className="container">
              <div className="row pt-5">
                <div className="col-lg-8 offset-lg-2">
                  <div className="volunteer-contact-form">
                    <form
                      onSubmit={handleSubmit(onSubmit)}
                      className="volunteer-form"
                    >
                      <div className="row">
                        <div className="col-lg-12 col-md-12 col-sm-12 col-12 form-group">
                          <label className="text-left">Email Address <span style={{ color: "#F15B43" }}>*</span></label>
                          <input
                            name="email"
                            className={`input_fixed_width ${errors?.email ? "valid_error" : ""}`}
                            type="text"
                            {...register("email", { required: true })}
                            autoComplete="false"
                          />
                          <div className={`text-left ${errors?.email ? "invalid_col" : ""}`}>
                            {errors?.email?.message}
                          </div>
                        </div>
                        <div className="col-lg-12 col-md-12 col-sm-12 col-12 form-group">
                          <label className="text-left">Password <span style={{ color: "#F15B43" }}>*</span></label>
                          <div style={{ position: "relative" }}>
                            <div className="pass-group">
                              <input
                                name="password"
                                className={`input_fixed_width ${errors?.password ? "valid_error" : ""}`}
                                type={eye ? "password" : "text"}
                                {...register("password", { required: true })}
                                autoComplete="false"
                              />
                              <span
                                onClick={onEyeClick}
                                className={`fa toggle-password ${eye ? "fa-eye-slash" : "fa-eye"}`}
                                style={{ position: "absolute", right: "10px", top: "50%", transform: "translateY(-50%)" }}
                              />
                            </div>
                          </div>
                          <div className={`text-left ${errors?.password ? "invalid_col" : ""}`}>
                            {errors?.password?.message}
                          </div>
                        </div>
                        <div className="submit-area col-lg-12 col-12">
                          <button style={{ borderRadius: 6 }} className="button-round" type="submit">
                            Login
                          </button>
                        </div>
                      </div>
                    </form>
                    <Link to="/auth/forgot-password">Forgotten password?</Link>
                    <p>
                      Don't have an account?{" "}
                      <Link to="/auth/register">Register here</Link>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      )}
    </>
  );
};

export default Login;
