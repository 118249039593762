import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import { getFaq, updateFaq } from "../../../Slice/faq";
import Loader from "../../Loader";

const schema = yup
  .object({
    question: yup.string().required("question is required").trim(),
    answer: yup.string().required("Answer is required").trim(),
  })
  .required();

const UpdateFaq = () => {
  const navigate = useNavigate();
  const { faq, isLoading } = useSelector((state) => state.faq);

  const { id } = useParams();

  const dispatch = useDispatch();

  useEffect(() => {
    const reloadData = async () => {
      dispatch(getFaq(id));
    };
    reloadData();
  }, []);

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmit = async (data) => {
    const requestData = {
      question: data?.question,
      answer: data?.answer,
    };
    dispatch(updateFaq(id, requestData, navigate));
  };

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <div id="content">
          <div className="row">
            <div className="col-lg-12 col-md-8">
              <div className="row my-4">
                <div className="text-center">
                  <h3>Update Faq</h3>
                </div>
                <div className="card-body pt-0">
                  <div className="volunteer-contact-form">
                    <form
                      onSubmit={handleSubmit(onSubmit)}
                      className="volunteer-form"
                    >
                      <div className="form-group">
                        <label htmlFor="" className="text-left">Question <span style={{ color: 'red' }}>*</span></label>
                        <Controller
                          name="question"
                          control={control}
                          render={({ field: { value, onChange } }) => (
                            <input
                              placeholder="Add Question*"
                              type="text"
                              value={value}
                              onChange={onChange}
                              autoComplete="false"
                              className={`input_fixed_width ${errors?.answer ? 'valid_error' : ''}`}
                            />
                          )}
                          defaultValue={faq?.question}
                        />
                        {errors?.question && (
                          <p style={{ color: "red", textAlign: 'left' }}>
                            {errors?.question?.message}
                          </p>
                        )}
                      </div>
                      <div className="form-group">
                        <label htmlFor="" className="text-left">Answer <span style={{ color: 'red' }}>*</span></label>
                        <Controller
                          name="answer"
                          control={control}
                          render={({ field: { value, onChange } }) => (
                            <textarea
                              placeholder="Add Anwer*"
                              type="text"
                              value={value}
                              onChange={onChange}
                              className={`input_fixed_width ${errors?.answer ? 'valid_error' : ''}`}
                            />
                          )}
                          defaultValue={faq?.answer}
                        />
                        {errors?.answer && (
                          <p style={{ color: "red", textAlign: 'left' }}>
                            {errors?.answer?.message}
                          </p>
                        )}
                      </div>
                      <div className="submit-area col-lg-12 col-12">
                        <Link to={"/admin/faqs"} className="button-round button-back">
                          Back to List
                        </Link>
                        <button type="submit" className="button-round">
                          Update faq
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default UpdateFaq;
