import React from "react";
import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import * as yup from "yup";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useSelector } from "react-redux";
import { StatesAndUnionTerritories } from "../../../constants/index";
import Loader from "../../Loader";
import ReactSelect from "react-select";
import {
  getAllIntrests,
  getAllProfessions,
  getAllVillages,
} from "../../../Slice/master";
import { addSkilledPerson } from "../../../Slice/skilledPerson";
import { Link, useNavigate } from "react-router-dom";
import { emailrgx, pinCodergx } from "../../constant/Constant";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { DatePicker } from 'antd';
import dayjs from "dayjs";
import moment from "moment";

const schema = yup.object({
  fullName: yup.string().required("Name is required").trim(),
  dob: yup
    .date()
    .typeError("Invalid Date")
    .required("Date of Birth is required")
    .max(new Date(), "Date of Birth cannot be in the future")
    .test('is-at-least-18', 'Age must be at least 18 years old', function (value) {
      const eighteenYearsAgo = new Date();
      eighteenYearsAgo.setFullYear(eighteenYearsAgo.getFullYear() - 18);
      return value <= eighteenYearsAgo;
    }),
  gender: yup.string().required("Gender is required").trim(),
  profession: yup.string().required("Current Profession is required"),
  password: yup.string().required("Password is required").trim(),
  cpassword: yup
    .string()
    .required("Confirm Password is required")
    .oneOf([yup.ref("password"), null], "Passwords must match"),
  email: yup
    .string()
    .required("Email is required")
    .matches(emailrgx, "Invalid Email")
    .trim(),
  contact: yup.string().required("Phone Number is required").max(12).min(10),
  addressLine1: yup.string().required("Field is required").trim(),
  addressLine2: yup.string(),
  village: yup.string().required("Village/City is required"),
  pincode: yup.string().required("Pin Code is required").matches(pinCodergx, "Pin Code must be 6 digits number"),
  state: yup.string().required("State is required"),
  preferredContact: yup
    .string()
    .required("Please select the Contact Preference"),
  userProfile: yup
    .mixed().required('Profile Photo is required')
    .test("fileType", "Invalid file type", (value) => {
      if (!value) return true;
      return ["image/jpeg", "image/png", "image/jpg"].includes(value.type);
    })
    .required(),
});

const AddPerson = () => {
  const dispatch = useDispatch();
  const [previewUrl, setPreviewUrl] = useState("");
  const { villages, interests, professions, isLoading } = useSelector(
    (state) => state.masterSlice
  );
  const { isLoading: personLoading } = useSelector(
    (state) => state.person
  );
  useEffect(() => {
    dispatch(getAllProfessions());
    dispatch(getAllVillages());
    dispatch(getAllIntrests());
  }, [dispatch]);

  const ProfessionsOptions = professions?.map((profession) => ({
    value: profession.professionName,
    label: profession.professionName,
  }));
  ProfessionsOptions.push({ value: "other", label: "Other" });

  const villageOptions = villages?.map((village) => ({
    value: village.villageName,
    label: village.villageName,
  }));
  villageOptions.push({ value: "other", label: "Other" });

  const InterestsOptions = interests?.map((interest) => ({
    value: interest.name,
    label: interest.name,
  }));
  InterestsOptions.push({ value: "other", label: "Other" });

  const [selectedOption, setSelectedOption] = useState(null);
  const [selectedProfessionOption, setSelectedProfessionOption] =
    useState(null);
  const [selectedIntrestOption, setSelectedIntrestOption] = useState(null);

  const handleSelectIntrestChange = (selected) => {
    if (selected?.value === "other") {
      setSelectedIntrestOption("other");
    } else {
      setSelectedIntrestOption(selected?.value);
    }
  };

  const handleSelectProfessionChange = (selected) => {
    if (selected?.value === "other") {
      setSelectedProfessionOption("other");
    } else {
      setSelectedProfessionOption(selected?.value);
    }
  };

  const handleSelectChange = (selected) => {
    if (selected?.value === "other") {
      setSelectedOption("other");
    } else {
      setSelectedOption(selected?.value);
    }
  };

  const {
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const formatDate = (date) => {
    return moment(date).format("YYYY-MM-DD");
  };

  const navigate = useNavigate();

  const onSubmit = (data) => {
    const formData = new FormData();
    formData.append("fullName", data?.fullName);
    formData.append("email", data?.email);
    formData.append("password", data?.password);
    formData.append("mobile", data?.contact);
    formData.append("dob", formatDate(data?.date));
    formData.append("gender", data?.gender);
    formData.append("contactMode", data?.preferredContact);
    formData.append("addressLine1", data?.addressLine1);
    formData.append("addressLine2", data?.addressLine2);
    formData.append("village", data?.village);
    formData.append("pincode", data?.pincode);
    formData.append("state", data?.state);
    formData.append("profession", data?.profession);
    formData.append("userProfile", data?.userProfile);
    dispatch(addSkilledPerson(formData, navigate, reset, setPreviewUrl));
  };
  const dateFormat = 'YYYY/MM/DD';

  return (
    <div id="content">
      {isLoading || personLoading ? (
        <Loader />
      ) : (
        <div className="row justify-content-center">
          <div className="col-lg-12 col-md-10">
            <div className="row my-4">
              <div className="text-center">
                <h3>Add Skilled Person</h3>
              </div>
              <div className="card-body pt-0">
                <div className="volunteer-contact-form">
                  <form
                    onSubmit={handleSubmit(onSubmit)}
                    className="volunteer-form"
                  >
                    <div className="row">
                      <div className="col-lg-6 col-md-6 col-sm-6 col-12 form-group">
                        <label htmlFor="" className="text-left">Full Name <span style={{ color: 'red' }}>*</span></label>
                        <Controller
                          name="fullName"
                          control={control}
                          render={({ field: { value, onChange } }) => (
                            <input
                              placeholder="Full Name"
                              type="text"
                              value={value}
                              onChange={onChange}
                              autoComplete="false"
                              className={`input_fixed_width ${errors?.fullName ? 'valid_error' : ''}`}
                            />
                          )}
                          defaultValue=""
                        />
                        {errors?.fullName && (
                          <div style={{ color: "red" }} className="text-left">
                            {errors?.fullName?.message}
                          </div>
                        )}
                      </div>
                      <div className="col-lg-6 col-md-6 col-sm-6 col-12 form-group">
                        <label htmlFor="" className="text-left">Email Address <span style={{ color: 'red' }}>*</span></label>
                        <Controller
                          name="email"
                          control={control}
                          render={({ field: { value, onChange } }) => (
                            <input
                              placeholder="Email Address"
                              type="text"
                              value={value}
                              onChange={onChange}
                              className={`input_fixed_width ${errors?.email ? 'valid_error' : ''}`}
                            />
                          )}
                          defaultValue=""
                        />
                        {errors?.email && (
                          <div style={{ color: "red" }} className="text-left">
                            {errors?.email?.message}
                          </div>
                        )}
                      </div>
                      <div className="col-lg-6 col-md-6 col-sm-6 col-12 form-group">
                        <label htmlFor="" className="text-left">Date Of Birth <span style={{ color: 'red' }}>*</span></label>
                        <Controller
                          name="dob"
                          control={control}
                          render={({ field: { value, onChange } }) => (
                            <DatePicker
                              className={`w-100 input_fixed_width ${errors?.dob ? 'valid_error' : ''}`}
                              value={value}
                              placeholder="Date Of Birth"
                              selected={value}
                              onChange={onChange}
                              defaultPickerValue={dayjs('2000/01/01', dateFormat)}
                              disabledDate={current => current && current > moment().endOf('day')}
                            />
                          )}
                          defaultValue=""
                        />
                        {errors?.dob && (
                          <div style={{ color: "red" }} className="text-left">{errors?.dob.message}</div>
                        )}
                      </div>
                      <div className="col-lg-6 col-md-6 col-sm-6 col-12 form-group">
                        <label htmlFor="" className="text-left">Contact <span style={{ color: 'red' }}>*</span></label>
                        <Controller
                          name="contact"
                          control={control}
                          render={({ field: { value, onChange } }) => (
                            <PhoneInput
                              className={`${errors?.contact ? 'valid_error' : ''}`}
                              country={"in"}
                              value={value}
                              onChange={(phone) => onChange(phone)}
                            />
                          )}
                          defaultValue=""
                        />
                        {errors?.contact && (
                          <div style={{ color: "red" }} className="text-left">
                            {errors?.contact?.message}
                          </div>
                        )}
                      </div>
                      <div className="col-lg-6 col-md-6 col-sm-6 col-12 form-group">
                        <label htmlFor="" className="text-left">Password <span style={{ color: 'red' }}>*</span></label>

                        <Controller
                          name="password"
                          control={control}
                          render={({ field: { value, onChange } }) => (
                            <input
                              placeholder="Password"
                              type="password"
                              value={value}
                              onChange={onChange}
                              className={`w-100 input_fixed_width ${errors?.password ? 'valid_error' : ''}`}
                            />
                          )}
                          defaultValue=""
                        />
                        {errors?.password && (
                          <div style={{ color: "red" }} className="text-left">
                            {" "}
                            {errors?.password?.message}
                          </div>
                        )}
                      </div>
                      <div className="col-lg-6 col-md-6 col-sm-6 col-12 form-group">
                        <label htmlFor="" className="text-left">Confirm Password <span style={{ color: 'red' }}>*</span></label>

                        <Controller
                          name="cpassword"
                          control={control}
                          render={({ field: { value, onChange } }) => (
                            <input
                              placeholder="Confirm Password"
                              type="password"
                              value={value}
                              onChange={onChange}
                              className={`w-100 input_fixed_width ${errors?.password ? 'valid_error' : ''}`}

                            />
                          )}
                          defaultValue=""
                        />
                        {errors?.cpassword && (
                          <div style={{ color: "red" }} className="text-left">
                            {" "}
                            {errors?.cpassword?.message}
                          </div>
                        )}
                      </div>
                      <div className="col-lg-6 col-md-6 col-sm-6 col-12 form-group">
                        <label className="mb-2 text-left">Gender <span style={{ color: 'red' }}>*</span> </label>
                        <div className="d-flex justify-content-start form_radio_wrapper_align_center">
                          <div className="form-check form-check-inline">
                            <Controller
                              name="gender"
                              control={control}
                              render={({ field: { value, onChange } }) => (
                                <input
                                  id="male"
                                  className={`form-check-input`}

                                  style={{ border: errors?.gender ? "1px solid red" : '1px solid #B8BDC9', borderRadius: '1px' }}
                                  type="radio"
                                  value="male"
                                  checked={value === "male"}
                                  onChange={() => onChange("male")}
                                />
                              )}
                            />
                            <label
                              className="form-check-label mr-2"
                              htmlFor="male"
                              style={{ color: errors?.gender ? 'red' : '', fontSize: 12, marginTop: 1, fontWeight: 500 }}
                            >
                              Male
                            </label>
                          </div>
                          <div className="form-check form-check-inline">
                            <Controller
                              name="gender"
                              control={control}
                              render={({ field: { value, onChange } }) => (
                                <input
                                  id="female"
                                  className={`form-check-input`}
                                  style={{ border: errors?.gender ? "1px solid red" : '1px solid #B8BDC9', borderRadius: '1px' }}
                                  type="radio"
                                  value="female"
                                  checked={value === "female"}
                                  onChange={() => onChange("female")}
                                />
                              )}
                            />
                            <label
                              className="form-check-label"
                              htmlFor="female"
                              style={{ color: errors?.gender ? 'red' : '', fontSize: 12, marginTop: 1, fontWeight: 500 }}

                            >
                              Female
                            </label>
                          </div>
                          <div className="form-check form-check-inline">
                            <Controller
                              name="gender"
                              control={control}
                              render={({ field: { value, onChange } }) => (
                                <input
                                  id="others"
                                  className={`form-check-input`}
                                  style={{ border: errors?.gender ? "1px solid red" : '1px solid #B8BDC9', borderRadius: '1px' }}
                                  type="radio"
                                  value="others"
                                  checked={value === "others"}
                                  onChange={() => onChange("others")}
                                />
                              )}
                            />
                            <label
                              className="form-check-label mb-2"
                              htmlFor="others"
                              style={{ color: errors?.gender ? 'red' : '', fontSize: 12, marginTop: 1, fontWeight: 500 }}
                            >
                              Others
                            </label>
                          </div>
                        </div>
                        {errors?.gender && (
                          <div style={{ color: "red" }} className="text-left">
                            {errors?.gender?.message}
                          </div>
                        )}
                      </div>
                      <div className="col-lg-6 col-md-6 col-sm-6 col-12 form-group">
                        <label className="text-left" htmlFor="">Current Profession <span style={{ color: 'red' }}>*</span></label>
                        {selectedProfessionOption === "other" ? (
                          <Controller
                            name="profession"
                            control={control}
                            render={({ field: { value, onChange } }) => (
                              <input
                                type="text"
                                placeholder="Enter Current Profession"
                                className="input_fixed_width"
                                value={value}
                                onChange={(e) => onChange(e.target.value)}
                              />
                            )}
                          />
                        ) : (
                          <Controller
                            name="profession"
                            control={control}
                            render={({ field: { value, onChange } }) => (
                              <ReactSelect
                                className="selectcustom"
                                placeholder="Current Profession"
                                options={ProfessionsOptions}
                                value={ProfessionsOptions.find(
                                  (option) => option.value === value
                                )}
                                onChange={(selected) => {
                                  if (selected?.value !== "other") {
                                    onChange(selected?.value);
                                  } else {
                                    onChange("");
                                  }
                                  handleSelectProfessionChange(selected);
                                }}
                                isClearable
                                isSearchable
                                isFocused={false}
                                styles={{
                                  control: (provided, state) => ({
                                    ...provided,
                                    border: errors?.profession ? "1px solid red" : "1px solid #B8BDC9",
                                    backgroundColor: 'white',
                                    minHeight: 45,
                                    height: 45,
                                    boxShadow: state.isFocused ? '0 0 0 2px transparent' : provided.boxShadow,
                                    '&:hover': {
                                      border: errors?.profession ? "1px solid red" : "1px solid #B8BDC9",
                                    },
                                    display: 'flex',
                                  }),
                                  valueContainer: (provided) => ({
                                    ...provided,
                                    height: 45,
                                    display: 'flex',
                                    alignItems: 'center',
                                    padding: '0 15px',
                                  }),
                                  input: (provided) => ({
                                    ...provided,
                                    margin: 0,
                                    padding: 0,
                                  }),
                                  indicatorsContainer: (provided) => ({
                                    ...provided,
                                    height: 45,
                                    display: 'flex',
                                    alignItems: 'center',
                                  }),
                                  placeholder: (provided) => ({
                                    ...provided,
                                    display: 'flex',
                                    alignItems: 'center',
                                  }),
                                }}
                              />
                            )}
                            defaultValue=""
                          />
                        )}
                        {errors?.profession && (
                          <div style={{ color: "red" }} className="text-left">
                            {errors?.profession?.message}
                          </div>
                        )}
                      </div>
                      <div className="col-lg-6 col-md-6 col-sm-6 col-12 form-group form-group-file">
                        <div style={{ color: '#383838', fontWeight: 500 }} className="mb-2">Profile Picture <span style={{ color: 'red' }}>*</span></div>
                        <label htmlFor="userProfile" className="input_fixed_width"
                          style={{
                            border: errors?.userProfile ? "1px solid red" : "1px solid #B8BDC9",
                          }}
                        >Profile Picture</label>
                        <Controller
                          name="userProfile"
                          control={control}
                          render={({ field: { value, onChange, onBlur } }) => (
                            <>
                              <input
                                id="userProfile"
                                type="file"
                                onChange={(e) => {
                                  const file = e.target.files[0];
                                  if (file && file.type.startsWith("image/")) {
                                    const previewUrl =
                                      URL.createObjectURL(file);
                                    setPreviewUrl(previewUrl);
                                    onChange(file);
                                  }
                                }}
                                onBlur={onBlur}
                                accept=".jpg,.png,.jpeg"
                              />
                              {previewUrl && (
                                <div className="preview-image-container">
                                  <img
                                    className="preview-image"
                                    src={previewUrl}
                                    alt="Preview"
                                  />
                                </div>
                              )}
                            </>
                          )}
                        />
                        {errors?.userProfile && (
                          <p style={{ color: "red" }}>
                            {errors?.userProfile?.message}
                          </p>
                        )}
                      </div>
                      <h3>Address Details:</h3>
                      <div className="col-lg-6 col-md-6 col-sm-6 col-12 form-group">
                        <label className="text-left">Address Line 1 <span style={{ color: 'red' }}>*</span></label>
                        <Controller
                          name="addressLine1"
                          control={control}
                          render={({ field: { value, onChange } }) => (
                            <textarea
                              placeholder="Address Line 1"
                              className={`input_fixed_width ${errors?.addressLine1 ? 'valid_error' : ''}`}
                              type="text"
                              value={value}
                              onChange={onChange}
                            />
                          )}
                          defaultValue=""
                        />
                        {errors?.addressLine1 && (
                          <div style={{ color: "red" }} className="text-left">
                            {errors?.addressLine1?.message}
                          </div>
                        )}
                      </div>
                      <div className="col-lg-6 col-md-6 col-sm-6 col-12 form-group">
                        <label className="text-left">Address Line 2</label>
                        <Controller
                          name="addressLine2"
                          control={control}
                          render={({ field: { value, onChange } }) => (
                            <textarea
                              placeholder="Address Line 2"
                              className={`input_fixed_width`}
                              type="text"
                              value={value}
                              onChange={onChange}
                            />
                          )}
                          defaultValue=""
                        />
                      </div>
                      <div className="col-lg-6 col-md-6 col-sm-6 col-12 form-group">
                        <label className="text-left">Pin Code <span style={{ color: 'red' }}>*</span></label>
                        <Controller
                          name="pincode"
                          control={control}
                          render={({ field: { value, onChange } }) => (
                            <input
                              placeholder="Pin Code"
                              className={`input_fixed_width ${errors?.pincode ? 'valid_error' : ''}`}
                              type="text"
                              value={value}
                              onChange={onChange}
                              maxLength={6}
                            />
                          )}
                          defaultValue=""
                        />
                        {errors.pincode && (
                          <div style={{ color: "red" }} className="text-left">
                            {errors.pincode.message}
                          </div>
                        )}
                      </div>
                      <div className="col-lg-6 col-md-6 col-sm-6 col-12 form-group">
                        <label className="text-left">State <span style={{ color: 'red' }}>*</span></label>
                        <Controller
                          name="state"
                          control={control}
                          render={({ field: { value, onChange } }) => (
                            <ReactSelect
                              className="selectcustom"
                              placeholder="State"
                              options={StatesAndUnionTerritories}
                              value={StatesAndUnionTerritories.find(
                                (option) => option.value === value
                              )}
                              onChange={(selected) => onChange(selected?.value)}
                              isClearable
                              isSearchable
                              isFocused={false}
                              styles={{
                                control: (provided, state) => ({
                                  ...provided,
                                  border: errors?.state ? "1px solid red" : "1px solid #B8BDC9",
                                  backgroundColor: 'white',
                                  minHeight: 45,
                                  height: 45,
                                  boxShadow: state.isFocused ? '0 0 0 2px transparent' : provided.boxShadow,
                                  '&:hover': {
                                    border: errors?.state ? "1px solid red" : "1px solid #B8BDC9",
                                  },
                                  display: 'flex',
                                }),
                                valueContainer: (provided) => ({
                                  ...provided,
                                  height: 45,
                                  display: 'flex',
                                  alignItems: 'center',
                                  padding: '0 15px',
                                }),
                                input: (provided) => ({
                                  ...provided,
                                  margin: 0,
                                  padding: 0,
                                }),
                                indicatorsContainer: (provided) => ({
                                  ...provided,
                                  height: 45,
                                  display: 'flex',
                                  alignItems: 'center',
                                }),
                                placeholder: (provided) => ({
                                  ...provided,
                                  display: 'flex',
                                  alignItems: 'center',
                                }),
                              }}
                            />
                          )}
                          defaultValue=""
                        />
                        {errors.state && (
                          <div style={{ color: "red" }} className="text-left">{errors.state.message}</div>
                        )}
                      </div>
                      <div className="col-lg-6 col-md-6 col-sm-6 col-12 form-group">
                        <label className="text-left">Enter Village/City <span style={{ color: 'red' }}>*</span></label>
                        {selectedOption === "other" ? (
                          <Controller
                            name="village"
                            control={control}
                            render={({ field: { value, onChange } }) => (
                              <input
                                type="text"
                                placeholder="Enter Village/City"
                                className="input_fixed_width"
                                value={value}
                                onChange={(e) => onChange(e.target.value)}
                              />
                            )}
                          />
                        ) : (
                          <Controller
                            name="village"
                            control={control}
                            render={({ field: { value, onChange } }) => (
                              <ReactSelect
                                className="selectcustom"
                                placeholder="Village/City"
                                options={villageOptions}
                                value={villageOptions.find(
                                  (option) => option.value === value
                                )}
                                onChange={(selected) => {
                                  if (selected?.value !== "other") {
                                    onChange(selected?.value);
                                  } else {
                                    onChange("");
                                  }
                                  handleSelectChange(selected);
                                }}
                                isClearable
                                isSearchable
                                isFocused={false}
                                styles={{
                                  control: (provided, state) => ({
                                    ...provided,
                                    border: errors?.village ? "1px solid red" : "1px solid #B8BDC9",
                                    backgroundColor: 'white',
                                    minHeight: 45,
                                    height: 45,
                                    boxShadow: state.isFocused ? '0 0 0 2px transparent' : provided.boxShadow,
                                    '&:hover': {
                                      border: errors?.village ? "1px solid red" : "1px solid #B8BDC9",
                                    },
                                    display: 'flex',
                                  }),
                                  valueContainer: (provided) => ({
                                    ...provided,
                                    height: 45,
                                    display: 'flex',
                                    alignItems: 'center',
                                    padding: '0 15px',
                                  }),
                                  input: (provided) => ({
                                    ...provided,
                                    margin: 0,
                                    padding: 0,
                                  }),
                                  indicatorsContainer: (provided) => ({
                                    ...provided,
                                    height: 45,
                                    display: 'flex',
                                    alignItems: 'center',
                                  }),
                                  placeholder: (provided) => ({
                                    ...provided,
                                    display: 'flex',
                                    alignItems: 'center',
                                  }),
                                }}
                              />
                            )}
                            defaultValue=""
                          />
                        )}
                        {errors?.village && (
                          <div style={{ color: "red" }} className="text-left">
                            {errors?.village?.message}
                          </div>
                        )}
                      </div>
                      <div className="col-lg-6 col-md-6 col-sm-6 col-12 form-group">
                        <label className="mb-2 text-left">Mode of Contact <span style={{ color: 'red' }}>*</span> </label>
                        <div className="d-flex justify-content-start form_radio_wrapper_align_center">
                          <div className="form-check form-check-inline">
                            <Controller
                              name="preferredContact"
                              control={control}
                              render={({ field: { value, onChange } }) => (
                                <input
                                  id="email"
                                  className={`form-check-input`}
                                  type="radio"
                                  value="email"
                                  checked={value === "email"}
                                  onChange={() => onChange("email")}
                                  style={{ border: errors?.preferredContact ? "1px solid red" : '1px solid #B8BDC9', borderRadius: '1px' }}

                                />
                              )}
                            />
                            <label
                              className="form-check-label mr-2"
                              htmlFor="email" // Corrected htmlFor value
                              style={{ color: errors?.preferredContact ? 'red' : '', fontSize: 12, marginTop: 1, fontWeight: 500 }}

                            >
                              E-mail
                            </label>
                          </div>
                          <div className="form-check form-check-inline">
                            <Controller
                              name="preferredContact"
                              control={control}
                              render={({ field: { value, onChange } }) => (
                                <input
                                  id="contact"
                                  className={`form-check-input`}
                                  type="radio"
                                  value="contact"
                                  checked={value === "contact"}
                                  onChange={() => onChange("contact")}
                                  style={{ border: errors?.preferredContact ? "1px solid red" : '1px solid #B8BDC9', borderRadius: '1px' }}

                                />
                              )}
                            />
                            <label
                              className="form-check-label"
                              htmlFor="contact"
                              style={{ color: errors?.preferredContact ? 'red' : '', fontSize: 12, marginTop: 1, fontWeight: 500 }}

                            >
                              Phone
                            </label>
                          </div>
                          <div className="form-check form-check-inline">
                            <Controller
                              name="preferredContact"
                              control={control}
                              render={({ field: { value, onChange } }) => (
                                <input
                                  id="both"
                                  className={`form-check-input`}
                                  type="radio"
                                  value="both"
                                  checked={value === "both"}
                                  onChange={() => onChange("both")}
                                  style={{ border: errors?.preferredContact ? "1px solid red" : '1px solid #B8BDC9', borderRadius: '1px' }}
                                />
                              )}
                            />
                            <label className="form-check-label" htmlFor="both"
                              style={{ color: errors?.preferredContact ? 'red' : '', fontSize: 12, marginTop: 1, fontWeight: 500 }}
                            >
                              Both
                            </label>
                          </div>
                        </div>
                        {errors.preferredContact && (
                          <div style={{ color: "red" }} className="text-left">
                            {errors.preferredContact.message}
                          </div>
                        )}
                      </div>
                      <div className="submit-area col-lg-12 col-12">
                        <Link to={"/admin/all-SkilledPersons"} className="button-round button-back">
                          Back to List
                        </Link>
                        <button type="submit" className="button-round">
                          Add Skilled Person
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default AddPerson;
