import React from 'react';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import 'bootstrap/dist/css/bootstrap.min.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import './Assets/Vendors/elementskit-icon-pack/assets/css/ekiticons.css';
import 'jquery-ui/dist/themes/base/jquery-ui.min.css';
import 'modal-video/css/modal-video.min.css';
import 'fancybox/dist/css/jquery.fancybox.css';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import $ from 'jquery';
import 'jquery/dist/jquery.js';
import 'jquery-ui/dist/jquery-ui.min.js';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import 'bootstrap/dist/js/bootstrap.min.js';
import 'jquery-waypoints/waypoints.min.js';
import 'modal-video/js/jquery-modal-video.min.js';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-quartz.css';
import "react-confirm-alert/src/react-confirm-alert.css";
import App from './App';

window.jQuery = $;
window.jquery = $;

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
