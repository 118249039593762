import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import { confirmAlert } from "react-confirm-alert";
import { Table } from "antd";
import Loader from "../../../Components/Loader";
import { ROLES, getUserInfoFromToken } from "../../../Components/constant/Constant";
import { deletePersonSkill, getPersonSkills } from "../../../Slice/personSpecializedSkill";

const UserSpecializedSkills = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    
    const userInfo = getUserInfoFromToken();
    const id = userInfo?.userId

    const { personSpecializedSkills, isLoading } = useSelector((state) => state.PersonSkills);

    const [state, setState] = useState({
        search: "",
        page: 1,
        pagesize: 5,
    });

    useEffect(() => {
        dispatch(getPersonSkills(id));
    }, [dispatch, id]);

    const formatDate = (date) => moment(date).format("YYYY-MM-DD");

    const handleUpdateClick = (id) => {
        navigate(userInfo.roleName.includes(ROLES.Admin) ? `/admin/update-blog/${id}` : `/user/update-blog/${id}`);
    };

    const handleDelete = (skillid) => {
        dispatch(deletePersonSkill(skillid));
    };

    const handleDeleteClick = (title) => {
        confirmAlert({
            title: "Confirm to delete",
            message: "Are you sure to do this.",
            buttons: [
                {
                    label: "Yes",
                    onClick: () => handleDelete(title),
                },
                {
                    label: "No",
                },
            ],
        });
    };

    const onShowSizeChange = (current, pageSize) => {
        setState({ ...state, page: 1, pagesize: pageSize });
    };

    const itemRender = (current, type, originalElement) => {
        if (type === 'prev') {
            return <button className="btn btn-sm btn-primary">Previous</button>;
        }
        if (type === 'next') {
            return <button className="btn btn-sm btn-primary">Next</button>;
        }
        return originalElement;
    };
;

    const columns = [
        {
            title: '#',
            render: (text, record, index) => (
                index + 1
            )
        },
        {
            title: 'Skill',
            dataIndex: "categoryName",
            sorter: (a, b) => a.title.length - b.title.length,
        },
        {
            title: 'Description',
            dataIndex: 'description',
            sorter: (a, b) => a.author.length - b.author.length,
        },
        {
            title: 'Added On',
            render: (record) => (
                formatDate(record.publish_date)
            ),
            sorter: (a, b) => a.publish_date.length - b.publish_date.length,
        },
        {
            title: "Action",
            render: (text, record) => (
                <div className="d-flex justify-content-around" data-popper-placement="bottom-end">
                    <Link to={`/member/update/specializedskill/${record.id}`}
                        className="dropdown-item px-2 text-warning" onClick={() => {
                            handleUpdateClick(record.donorId)
                        }}>
                        <i className="fa fa-pencil m-r-5" />
                    </Link>
                    <Link className="dropdown-item px-2 text-danger" to="#" onClick={() => {
                        handleDeleteClick(record.id);
                    }}>
                        <i className="fa fa-trash m-r-5" />
                    </Link>
                </div>
            ),
        },
    ]

    return (
        <div className="container-fluid mt-2">
            <div className="row justify-content-between align-items-center all_donor_header mb-2">
                <div className="col-auto">
                    <h1 className="h2">My Skills</h1>
                </div>
                <div className="col-auto">
                    <Link to="/member/add/specializedskill" className={`button-round border_radius`} type="button">
                        <i className={`fa fa-plus`} aria-hidden="true"></i> Add Skill
                    </Link>
                </div>
            </div>
            <div className="row">
                <div className="col-md-3 col-lg-3 col-sm-3 mb-2 mb-lg-2">
                    <input type="text" className="form-control" placeholder="Search" value={state.search} onChange={(e) => setState({ ...state, search: e.target.value })} />
                </div>
                {isLoading ? (
                    <Loader />
                ) : (
                    <Table
                        pagination={{
                            current: state.page,
                            pageSize: state.pagesize,
                            // total: blogsCount,
                            showTotal: (total, range) =>
                                `Showing ${range[0]} to ${range[1]} of ${total} entries`,
                            showSizeChanger: true,
                            onShowSizeChange: onShowSizeChange,
                            itemRender: itemRender,
                            onChange: (page, pageSize) =>
                                setState({ ...state, page, pagesize: pageSize }),
                        }}
                        bordered
                        style={{ overflowX: "auto" }}
                        columns={columns}
                        dataSource={personSpecializedSkills}
                        rowKey={(record) => record.blog_id}
                    />
                )}
            </div>
        </div>
    );
}

export default UserSpecializedSkills;