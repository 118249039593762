import React from "react";
import * as yup from "yup";
// import { addrequest } from "../Slice/contactRequest";
import { useDispatch } from "react-redux";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

const schema = yup
  .object({
    name: yup.string().required("Name is required").trim(),
    email: yup.string().required("Email is required").trim(),
    subject: yup.string().required("Subject is required").trim(),
    phone: yup
      .number()
      .typeError("contact must be a number")
      .required("Phone Number is required"),
    description: yup.string().required("Description is required").trim(),
  })
  .required();

const UpdateContactRequest = () => {

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const dispatch = useDispatch();
  const onSubmit = async (data) => {
    const requestData = {
      name: data?.name,
      email: data?.email,
      subject: data?.subject,
      phone: data?.phone,
      description: data?.description,
    };
    console.log(data);
    // dispatch(addrequest(requestData));
  };

  return (
    <>
      <div className="row">
        <div className="col-lg-12 col-md-8">
          <div className="row my-4">
            <div className="text-center">
              <h3>Update Contact Request</h3>
            </div>
            <div className="card-body pt-0">
              <div className="volunteer-contact-form">
                <form onSubmit={handleSubmit(onSubmit)} className="volunteer-form">
                  <div className="row">
                    <div className="col-lg-6 col-md-6 col-sm-6 col-12 form-group">
                      <Controller
                        name="name"
                        control={control}
                        render={({ field: { value, onChange } }) => (
                          <input
                            placeholder="Your Name*"
                            type="text"
                            value={value}
                            onChange={onChange}
                            autoComplete="off"
                          />
                        )}
                        defaultValue=""
                      />
                      {errors?.name && <p style={{ color: "red", whiteSpace: "nowrap" }}>{errors?.name?.message}</p>}
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-6 col-12 form-group">
                      <Controller
                        name="email"
                        control={control}
                        render={({ field: { value, onChange } }) => (
                          <input
                            placeholder="Your Email*"
                            type="email"
                            value={value}
                            onChange={onChange}
                            autoComplete="off"
                          />
                        )}
                        defaultValue=""
                      />
                      {errors?.email && <p style={{ color: "red", whiteSpace: "nowrap" }}>{errors?.email?.message}</p>}
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-6 col-12 form-group">
                      <Controller
                        name="subject"
                        control={control}
                        render={({ field: { value, onChange } }) => (
                          <input
                            placeholder="Subject*"
                            type="text"
                            value={value}
                            onChange={onChange}
                            autoComplete="off"
                          />
                        )}
                        defaultValue=""
                      />
                      {errors?.subject && <p style={{ color: "red", whiteSpace: "nowrap" }}>{errors?.subject?.message}</p>}
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-6 col-12 form-group">
                      <Controller
                        name="phone"
                        control={control}
                        render={({ field: { value, onChange } }) => (
                          <input
                            placeholder="Your Phone*"
                            type="text"
                            value={value}
                            onChange={onChange}
                            autoComplete="off"
                          />
                        )}
                        defaultValue=""
                      />
                      {errors?.phone && <p style={{ color: "red", whiteSpace: "nowrap" }}>{errors?.phone?.message}</p>}
                    </div>
                    <div className="col-lg-12 col-md-12 col-sm-12 col-12 form-group">
                      <Controller
                        name="description"
                        control={control}
                        render={({ field: { value, onChange } }) => (
                          <textarea
                            rows={8}
                            placeholder="Enter Your Message*"
                            type="text"
                            value={value}
                            onChange={onChange}
                          />
                        )}
                        defaultValue=""
                      />
                      {errors?.description && (
                        <p style={{ color: "red", whiteSpace: "nowrap" }}>{errors?.description?.message}</p>
                      )}
                    </div>
                    <div className="submit-area col-lg-12 col-12">
                      <button type="submit" className="button-round">
                        Update Blog
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default UpdateContactRequest;
