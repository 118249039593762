import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import * as yup from 'yup';
import { verifyOtp } from '../../Slice/authRegister';
import innerBannerImg1 from '../../Assets/Images/inner-banner-img-1.jpg';
import { useNavigate, useParams } from 'react-router-dom';
import Loader from '../../Components/Loader';
import { getUserDataById, resendOtp } from '../../Slice/authLogin';

const schema = yup.object({
    otp: yup.number().required("OTP is required"),
});

function ValidateAccount() {
    const { id } = useParams();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [formData, setFormData] = useState({ otp: "" });
    const [errors, setErrors] = useState({});
    const { isLoading } = useSelector((state) => state.userLogin);
    const { isLoading: resendOtpLoder } = useSelector((state) => state.userRegister);


    useEffect(() => {
        dispatch(getUserDataById(id));
    }, [dispatch]);

    const handleInputChange = async (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
        try {
            await schema.validateAt(name, { [name]: value });
            setErrors((prevErrors) => ({ ...prevErrors, [name]: "" }));
        } catch (error) {
            setErrors((prevErrors) => ({ ...prevErrors, [name]: error.message }));
        }
    };

    const onSubmit = async (e) => {
        e.preventDefault();
        try {
            await schema.validate(formData, { abortEarly: false });
            dispatch(verifyOtp({
                otp: formData.otp,
                userId: id
            }, navigate));
        } catch (validationErrors) {
            const newErrors = {};
            validationErrors.inner.forEach((error) => {
                newErrors[error.path] = error.message;
            });
            setErrors(newErrors);
        }
    };

    const handleResendButtonClick = async () => {
        const requestData = {
            userId: id
        };
        dispatch(resendOtp(requestData));
        console.log(requestData)
    }

    return (
        <>
            {(isLoading || resendOtpLoder) ? <Loader /> : (
                <main id="content" className="site-main">
                    <section className="inner-banner-wrap pb-0">
                        <div className="inner-baner-container" style={{ backgroundImage: `url(${innerBannerImg1})` }}>
                            <div className="container">
                                <div className="inner-banner-content">
                                    <h1 className="inner-title">Verify Your Account</h1>
                                </div>
                            </div>
                        </div>
                    </section>
                    <div className="volunteer-wrap">
                        <div className="container">
                            <div className="row mt-5">
                                <div className="col-lg-8 offset-lg-2">
                                    <div className="volunteer-contact-form">
                                        <p className={`fs-3 fst-normal font-monospace`}>Please provide otp sent on your email/number</p>
                                        <form onSubmit={onSubmit}>
                                            <div className="row">
                                                <div className="col-lg-12 col-md-12 col-sm-12 form-group">
                                                    <label className="text-left">OTP <span style={{ color: '#F15B43' }}>*</span></label>
                                                    <input
                                                        className={`form-control ${errors?.otp ? 'is-invalid' : ''}`}
                                                        type="number"
                                                        name="otp"
                                                        value={formData.otp}
                                                        onChange={handleInputChange}
                                                        autoComplete="off"
                                                    />
                                                    {errors?.otp && (
                                                        <div className="invalid-feedback">
                                                            {errors.otp}
                                                        </div>
                                                    )}
                                                </div>
                                                <div className="d-flex">
                                                    <div className="submit-area col-lg-7 me-1">
                                                        <button type="submit" className="button-round">
                                                            Submit
                                                        </button>
                                                    </div>
                                                    <div className="submit-area">
                                                        <button className="button-round" onClick={handleResendButtonClick}>
                                                            Resend OTP
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </main>
            )}
        </>
    )
}
export default ValidateAccount