import React, { useState } from 'react'
import { Outlet } from 'react-router-dom'
import Header from "../Components/Header";
import Footer from "../Components/Footer";

function AuthLayout() {

    const [isNavVisible, setIsNavVisible] = useState(false);
    
    const toggleNav = () => {
        setIsNavVisible(!isNavVisible);
      };
    
      const mobiletoggleNav = () => setIsNavVisible(false);
    return (
        <>
            <div id="page" className="full-page">
                <Header mobiletoggleNav={mobiletoggleNav} toggleNav={toggleNav} isNavVisible={isNavVisible} />
                <Outlet />
                <Footer />
            </div>
        </>
    )
}

export default AuthLayout