import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import innerBannerImg1 from "../Assets/Images/Event-bg-01-01.jpg";
import unboundImg3 from '../Assets/Images/unbound-img3.jpg';
import unboundImg23 from '../Assets/Images/unbound-img23.jpg';
import ClientSlider from '../Components/Slider/ClientSlider';
import { Helmet } from 'react-helmet';

function About() {

    return (
        <>
            <main id="content" className="site-main">
                <Helmet>
                    <title>About Nangal by Cycle | Our Mission & Vision</title>
                    <meta name="description" content="Learn about Nangal by Cycle, our mission to promote rural culture and sustainability, and our vision for a better community through cycling events and initiatives." />
                </Helmet>
                <section className="inner-banner-wrap">
                    <div className="inner-baner-container" style={{ backgroundImage: `url(${innerBannerImg1})` }}>
                        <div className="container">
                            <div className="inner-banner-content">
                                <h1 className="inner-title">Who we are</h1>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="about-section about-page-section">
                    <div className="container">
                        <div className="section-head">
                            <div className="row align-items-end">
                                <div className="col-lg-6">
                                    <div className="sub-title">INTRODUCTION OF US</div>
                                    <h2>At Nangal by Cycle, we believe that empowered communities are the cornerstone of positive change. </h2>
                                </div>
                                <div className="col-lg-6">
                                    <div className="section-disc">
                                        <p>Whether you're seeking volunteer opportunities, looking to contribute as a blood donor, sharing local insights through blogs, or accessing vital services, you've come to the right place.</p>
                                        <p>Furthermore, our website serves as a one-stop resource for accessing a wide range of essential services, including healthcare, education, and support networks. We've curated a comprehensive directory to ensure that every member of our community has access to the resources they need to thrive, no matter their circumstances.</p>
                                        <p>At Nangal by Cycle, we are committed to bringing people together, igniting passion for service, and uplifting our communities through empowerment, support, and positive change. </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="iconbox-container-bg">
                            <div className="iconbox-item-bg">
                                <div className="iconbox-content-bg">
                                    <i aria-hidden="true" className="fas fa-hand-holding-usd"></i>
                                    <h4>No poverty</h4>
                                </div>
                            </div>
                            <div className="iconbox-item-bg">
                                <div className="iconbox-content-bg">
                                    <i aria-hidden="true" className="fas fa-book-reader"></i>
                                    <h4>Best education</h4>
                                </div>
                            </div>
                            <div className="iconbox-item-bg">
                                <div className="iconbox-content-bg">
                                    <i aria-hidden="true" className="fas fa-fill-drip"></i>
                                    <h4>Clean water</h4>
                                </div>
                            </div>
                            <div className="iconbox-item-bg">
                                <div className="iconbox-content-bg">
                                    <i aria-hidden="true" className="fas fa-heart"></i>
                                    <h4>Good health</h4>
                                </div>
                            </div>
                            <div className="iconbox-item-bg">
                                <div className="iconbox-content-bg">
                                    <i aria-hidden="true" className="fas fa-hamburger"></i>
                                    <h4>Nutrition</h4>
                                </div>
                            </div>
                        </div>
                        <div className="about-video-wrap">
                            <div className="about-video-content">
                                <h3>Help is our main goal !!</h3>
                                <p>Join us on this incredible journey of community-driven transformation. Explore our website today and discover how you can be a part of something greater – a movement that celebrates the power of collective action and the strength of a united community. </p>
                            </div>
                            <div className="video-image" style={{ backgroundImage: `url(${unboundImg3})` }}>
                                <div className="video-button">
                                    <Link to={"#"} id="video-container" data-video-id="IUN664s7N-c">
                                        <i className="fas fa-play"></i>
                                    </Link>
                                </div>
                                <div className="overlay"></div>
                            </div>
                        </div>
                    </div>
                    <div className="client-section">
                        <div className="container">
                            <div className="client-wrap client-slider text-center">
                                <ClientSlider />
                            </div>
                        </div>
                    </div>
                </section>
            </main>
        </>
    )
}

export default About