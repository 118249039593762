import React, { useState } from 'react'
import AdminHeader from '../Components/admin/AdminHeader'
import { Outlet } from 'react-router-dom'
import AdminSideBar from '../Components/admin/AdminSideBar'
import '../index.css';
import MobileSideBar from './MobileSideBar';

function AdminLayout() {
    let [isShow, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    return (
        <>
            <AdminHeader handleShow={handleShow} />
            <div className="container-fluid" id="main">
                <div className="row">
                    <AdminSideBar />
                    <MobileSideBar isShow={isShow} handleClose={handleClose} />
                    <div className="col-md-9 col-lg-9 col-12 admin_container col main admin_layout">
                        <Outlet />
                        <footer className="container-fluid" >
                            <p className="text-right small">©2024-2025 Company</p>
                        </footer>
                    </div>
                </div>
            </div>

        </>
    )
}

export default AdminLayout