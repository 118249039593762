import React, { useEffect, useState } from "react";
import innerBannerImg1 from "../../Assets/Images/Event-bg-01-01.jpg";
import { Link } from "react-router-dom";
import { getPaginatedBlogs } from "../../Slice/blogs";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";
import Loader from "../../Components/Loader";
import { BsCalendar2DateFill } from "react-icons/bs";
import { FaUser } from "react-icons/fa";
import { Helmet } from "react-helmet";
import { rewriteUrl } from "../../Components/constant/Constant";
import { numberToString } from "../../utils/encrypt";

function BlogArchive() {
  const isPublished = true;
  const isApproved = true;
  const dispatch = useDispatch();
  const { blogsList, isLoading, blogsCount } = useSelector((state) => state.blog);

  const [state, setState] = useState({
    search: "",
    page: 1,
    pageSize: 10,
  });

  useEffect(() => {
    dispatch(getPaginatedBlogs(state.search, state.page, state.pageSize, null, isPublished, isApproved));
  }, [dispatch, state.page, state.pageSize]);

  const handleSearch = () => {
    dispatch(getPaginatedBlogs(state.search, state.page, state.pageSize, null, isPublished, isApproved));
  };

  const formatDate = (day) => moment(day).format("MMMM DD, YYYY");

  const truncateWithEllipsis = (str, maxLength) => {
    return str.length > maxLength ? str.slice(0, maxLength) + "..." : str;
  };

  const handleViewMore = () => {
    setState((prevState) => ({
      ...prevState,
      pageSize: prevState.pageSize + state.pageSize,
    }));
  };

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <main id="content" className="site-main">
          <Helmet>
            <title>Nangal By Cycle Blog | Cycling Tips, Local Insights & Travel Stories</title>
            <meta name="description" content="Explore our blog for cycling tips, Nangal travel guides, local insights, and inspiring stories from the cycling community. Stay updated with Nangal By Cycle." />
          </Helmet>
          <section className="inner-banner-wrap">
            <div
              className="inner-baner-container"
              style={{ backgroundImage: `url(${innerBannerImg1})` }}
            >
              <div className="container">
                <div className="inner-banner-content">
                  <h1 className="inner-title">Blogs</h1>
                </div>
              </div>
            </div>
          </section>
          <form className="container main-contact-info-item " style={{ maxWidth: '1000px' }}>
            <div className="row justify-content-center">
              <div className="col-lg-6 col-md-8 col-sm-10">
                <div className="form-group mb-2">
                  <input
                    type="text"
                    className="form-control bg-white"
                    value={state.search}
                    onChange={(e) =>
                      setState({ ...state, search: e.target.value })
                    }
                    placeholder="Find Blog"
                  />
                </div>
              </div>
              <div className="col-lg-2 col-md-4 col-sm-6">
                <div className="form-group mb-2">
                  <button
                    type="button"
                    className="btn btn-primary w-100 form-control btn-lg btn-block"
                    style={{ background: "#f15b44" }}
                    onClick={handleSearch}
                  >
                    Search Blog
                  </button>
                </div>
              </div>
            </div>
          </form>
          {blogsList && blogsList.length > 0 ?
            <div className="archive-section blog-archive">
              <div className="container">
                <div className="row">
                  <div className="col-lg-8 primary right-sidebar">
                    <div className="grid blog-inner row">
                      {blogsList.map((element) => (
                        <div className="grid-item col-md-6" key={element?.blog_id}>
                          <article className="post">
                            {element?.thumbnail_url && (
                              <figure
                                className="feature-image"
                                style={{
                                  maxWidth: "500px",
                                  maxHeight: "233px",
                                  overflow: "hidden",
                                }}
                              >
                                <img
                                  src={element.thumbnail_url}
                                  alt="unboundImg22"
                                />
                              </figure>
                            )}
                            <div className="entry-content">
                              <h3>
                                <Link to={`/blog/${rewriteUrl(element?.title)}-${numberToString(element?.blog_id)}`}>
                                  {truncateWithEllipsis(element?.title, 33)}
                                </Link>
                              </h3>
                              <div className="entry-meta">
                                <span className="byline">
                                  <Link to={`/blog/${rewriteUrl(element?.title)}-${numberToString(element?.blog_id)}`}>
                                    <FaUser className="me-2" style={{ color: '#F15B44' }} />
                                    {element?.author_name}
                                  </Link>
                                </span>
                                <span className="posted-on">
                                  <Link to={`/blog/${rewriteUrl(element?.title)}-${numberToString(element?.blog_id)}`}>
                                    <BsCalendar2DateFill className="me-2" style={{ color: '#F15B44' }} />
                                    {formatDate(element?.published_on)}
                                  </Link>
                                </span>
                              </div>
                            </div>
                          </article>
                        </div>
                      ))}
                    </div>
                    {((state.page * state.pageSize) < blogsCount) && (
                      <div div className="text-center" >
                        <button onClick={handleViewMore} className="button-round">View More</button>
                      </div>
                    )}
                  </div>
                  <div className="col-lg-4 secondary">
                    <div className="sidebar">
                      {blogsList && blogsList.length > 0 && (
                        <aside className="widget widget_latest_post widget-post-thumb">
                          <h3 className="widget-title">Recent Post</h3>
                          <ul>
                            {blogsList.slice(0, 4)?.map((element) => (
                              <li key={element?.blog_id}>
                                {element?.thumbnail_url && (
                                  <figure
                                    className="post-thumb"
                                    style={{
                                      maxWidth: "100px",
                                      maxHeight: "67px",
                                      overflow: "hidden",
                                    }}
                                  >
                                    <img
                                      src={element?.thumbnail_url}
                                      alt="unboundImg2"
                                    />
                                  </figure>
                                )}
                                <div className="post-content">
                                  <h5>
                                    <Link to={`/blog/${rewriteUrl(element?.title)}-${numberToString(element?.blog_id)}`}>
                                      {element?.title}
                                    </Link>
                                  </h5>
                                  <div className="entry-meta">
                                    <span className="posted-on">
                                      <Link to={`/blog/${rewriteUrl(element?.title)}-${numberToString(element?.blog_id)}`}>
                                        {formatDate(element?.published_on)}
                                      </Link>
                                    </span>
                                  </div>
                                </div>
                              </li>
                            ))}
                          </ul>
                        </aside>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            :
            <div className="text-center">
              <h3>No items found</h3>
            </div>
          }
        </main>
      )}
    </>
  );
}

export default BlogArchive;
