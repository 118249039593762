import moment from 'moment';
import React, { useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form';
import { Link, useNavigate, useParams } from 'react-router-dom';
import Loader from '../../Components/Loader';
import { yupResolver } from '@hookform/resolvers/yup';
import { useDispatch, useSelector } from 'react-redux';
import { getHeroDetail, updateHero } from '../../Slice/heroSlice';
import { heroSchema } from '../../utilities/formSchemas';
import { getUserInfoFromToken } from '../../Components/constant/Constant';
import ReactQuill from 'react-quill';

function UpdateHero() {
  const { heroId } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const userInfo = getUserInfoFromToken();
  const [photoUrl, setPhotoUrl] = useState("");
  const [file, setFile] = useState(null);
  const [isDelay, setIsDelay] = useState(true);
  const { heroDetails, isLoading } = useSelector((state) => state.hero);

  useEffect(() => {
    dispatch(getHeroDetail(heroId));
  }, [dispatch, heroId]);

  useEffect(() => {
    if (heroDetails?.photo_url) {
      setPhotoUrl(heroDetails.photo_url);
      fetch(heroDetails.photo_url)
        .then((res) => res.blob())
        .then((blob) => {
          const file = new File([blob], `photo_url${heroDetails.hero_Id}.jpg`, { type: blob.type });
          setFile(file);
        });
    }
  }, [heroDetails]);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsDelay(false);
    }, 1000);

    return () => clearTimeout(timer);
  }, []);

  const formatDate = (date) => moment(date).format("YYYY-MM-DD");

  const {
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(heroSchema),
  });

  const onSubmit = (data) => {
    const formData = new FormData();
    formData.append("name", data?.name);
    formData.append("recognition_title", data?.recognition_title);
    formData.append("recognition_description", data?.recognition_description);
    formData.append("recognition_date", data?.recognition_date );
    formData.append("photo_url", data?.photo_url);
    dispatch(updateHero(heroId, userInfo.userId, formData, navigate, reset, setPhotoUrl));
  };

  return (
    <>
      {
        isLoading || isDelay ? (
          <Loader />
        ) : (
          <div id="content">
            <div className="container-fluid mt-2">
              <div className="row text-center mb-2">
                <h1 className="h2">Update Hero Details</h1>
              </div>
              <div className="row">
                <div className="volunteer-contact-form">
                  <form onSubmit={handleSubmit(onSubmit)} className="volunteer-form">
                    <div className="row">
                      <div className="col-lg-6 col-md-6 col-sm-6 col-6 form-group">
                        <label className="text-left">Name <span style={{ color: '#F15B43' }}>*</span></label>
                        <Controller
                          name="name"
                          control={control}
                          render={({ field: { value, onChange } }) => (
                            <input
                              className={`input_fixed_width ${errors?.name ? "valid_error" : ""}`}
                              type="text"
                              value={value}
                              onChange={onChange}
                              autoComplete="false"
                            />
                          )}
                          defaultValue={heroDetails?.name}
                        />
                        {errors?.name && (
                          <div className={`text-left invalid_col`}>
                            {errors?.name?.message}
                          </div>
                        )}
                      </div>
                      <div className="col-lg-6 col-md-6 col-sm-6 col-6 form-group">
                        <label className="text-left">Achievement Title</label>
                        <Controller
                          name="recognition_title"
                          control={control}
                          render={({ field: { value, onChange } }) => (
                            <input
                              className={`input_fixed_width ${errors?.recognition_title ? "valid_error" : ""}`}
                              type="text"
                              value={value}
                              onChange={onChange}
                              autoComplete="false"
                            />
                          )}
                          defaultValue={heroDetails?.recognition_title}
                        />
                      </div>
                      {/* 
                        <Controller
                          name=""
                          control={control}
                          render={({ field: { value, onChange } }) => (
                            <input
                              
                              value={value}
                              onChange={onChange}
                              autoComplete="false"
                            />
                          )}
                          defaultValue={heroDetails?.recognition_description}
                        />
                        {errors?.recognition_description && (
                          <div className={`text-left invalid_col`}>
                            {errors?.recognition_description?.message}
                          </div>
                        )}
                      </div> */}
                      <div className="col-lg-6 col-md-6 col-sm-6 col-12 form-group">
                        <label className="text-left">Achievement Date </label>
                        <Controller
                          name="recognition_date"
                          control={control}
                          render={({ field: { value, onChange } }) => (
                            <input
                              className={`input_fixed_width ${errors?.recognition_date ? "valid_error" : ""}`}
                              type="date"
                              value={value}
                              onChange={e => onChange(e.target.value)}
                              max={new Date().toISOString().split('T')[0]}
                            />
                          )}
                          defaultValue={formatDate(heroDetails?.recognition_date)}
                        />
                      </div>
                      <div className="col-lg-6 col-md-6 col-sm-6 col-12 form-group form-group-file">
                        <div className="mb-2 profile-photo-class">Hero Profile <span style={{ color: '#F15B43' }}>*</span></div>
                        <label htmlFor="photo_url" className={`input_fixed_width ${errors?.recognition_date ? "valid_error" : ""}`} style={{ lineHeight: 3 }}>Upload Photo</label>
                        <Controller
                          name="photo_url"
                          control={control}
                          render={({ field: { onChange, onBlur } }) => (
                            <>
                              <input
                                id="photo_url"
                                type="file"
                                onChange={(e) => {
                                  const file = e.target.files[0];
                                  if (file && file.type.startsWith("image/")) {
                                    const photoUrl = URL.createObjectURL(file);
                                    setPhotoUrl(photoUrl);
                                    onChange(file);
                                  }
                                }}
                                onBlur={onBlur}
                                accept=".jpg,.jpeg,.png"
                              />
                              {photoUrl && (
                                <div className="preview-image-container">
                                  <img
                                    className="preview-image"
                                    src={photoUrl}
                                    alt="Preview"
                                  />
                                </div>
                              )}
                            </>
                          )}
                          defaultValue={file}
                        />
                        {errors?.photo_url && (
                          <div className={`text-left invalid_col`}>
                            {errors?.photo_url?.message}
                          </div>
                        )}
                      </div>
                      <div className="col-lg-12 col-md-12 col-sm-12 col-12 form-group">
                        <label className="text-left">Achievement Description <span style={{ color: '#F15B43' }}>*</span></label>
                        <Controller
                          name="recognition_description"
                          control={control}
                          render={({ field: { value, onChange } }) => (
                            <ReactQuill
                              className={`${errors?.recognition_description ? "valid_error" : ""}`}
                              modules={{
                                toolbar: {
                                  container: [
                                    [
                                      { header: "1" },
                                      { header: "2" },
                                      { header: [3, 4, 5, 6] },
                                      { font: [] },
                                    ],
                                    [{ size: [] }],
                                    [
                                      "bold",
                                      "italic",
                                      "underline",
                                      "strike",
                                      "blockquote",
                                    ],
                                    [{ list: "ordered" }, { list: "bullet" }],
                                    ["link", "video"],
                                    ["link", "image", "video"],
                                    ["clean"],
                                    ["code-block"],
                                  ],
                                  handlers: {},
                                },
                              }}
                              value={value}
                              onChange={onChange}
                            />
                          )}
                          defaultValue={heroDetails?.recognition_description}
                        />
                        {errors?.recognition_description && (
                          <div className={`text-left invalid_col`}>
                            {errors?.recognition_description?.message}
                          </div>
                        )}
                      </div>
                      <div className="submit-area col-lg-12 col-12">
                        <Link to={"/admin/heroes"} className="button-round button-back">
                          Back to List
                        </Link>
                        <button type="submit" className="button-round">
                          Update Hero
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        )
      }
    </>
  )
}

export default UpdateHero