import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, useForm } from "react-hook-form";
import Loader from "../../Loader";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { ROLES, facebookrgx, getUserInfoFromToken, instagramrgx, linkedinrgx, twitterrgx, youtubergx } from "../../constant/Constant";
import { addLink, userLinks } from "../../../Slice/socialMediaSlice";

const schema = yup.object({
    facebook: yup.string().transform((value, originalValue) => originalValue === '' ? null : value).nullable().matches(facebookrgx, "Invalid URL").trim(),
    youtube: yup.string().transform((value, originalValue) => originalValue === '' ? null : value).nullable().matches(youtubergx, "Invalid URL").trim(),
    linkedin: yup.string().transform((value, originalValue) => originalValue === '' ? null : value).nullable().matches(linkedinrgx, "Invalid URL").trim(),
    instagram: yup.string().transform((value, originalValue) => originalValue === '' ? null : value).nullable().matches(instagramrgx, "Invalid URL").trim(),
    twitter: yup.string().transform((value, originalValue) => originalValue === '' ? null : value).nullable().matches(twitterrgx, "Invalid URL").trim(),
}).required();

const SocialMediaLinks = () => {
    const dispatch = useDispatch();
    const userInfo = getUserInfoFromToken();
    const [isDelay, setIsDelay] = useState(true);
    const isAdmin = userInfo?.roleName?.includes(ROLES.Admin);
    const backPath = isAdmin ? "/admin/all-events" : "/user/events";
    const userId = userInfo ? userInfo.userId : null;
    const { userlinks, isLoading } = useSelector((state) => state.socialMedia);

    useEffect(() => {
        dispatch(userLinks(userId));
    }, [dispatch, userId]);

    useEffect(() => {
        const timer = setTimeout(() => {
            setIsDelay(false);
        }, 1000);

        return () => clearTimeout(timer);
    }, []);

    const {
        handleSubmit,
        control,
        formState: { errors },
        watch,
    } = useForm({
        resolver: yupResolver(schema),
    });

    const watchAllFields = watch();
    const isAnyFieldFilled = Object.values(watchAllFields).some(value => !!value);

    const onSubmit = async (data) => {
        const requestData = {
            youtube: data?.youtube,
            twitter: data?.twitter,
            linkedin: data?.linkedin,
            facebook: data?.facebook,
            instagram: data?.instagram,
        }
        dispatch(addLink(userId, requestData))
    };

    return (
        <>
            {isLoading || isDelay ? (
                <Loader />
            ) : (
                <>
                    <div id='content'>
                        <div className="volunteer-contact-form">
                            <h3>Add Social Media Links</h3>
                            <form onSubmit={handleSubmit(onSubmit)} className="volunteer-form">
                                <div className="col-lg-12 col-md-12 col-sm-12 col-12 form-group">
                                    <label className="text-left">Twitter</label>
                                    <Controller
                                        name="twitter"
                                        control={control}
                                        render={({ field: { value, onChange } }) => (
                                            <input
                                                className="input_fixed_width"
                                                type="text"
                                                value={value}
                                                onChange={onChange}
                                                autoComplete="false"
                                            />
                                        )}
                                        defaultValue={userlinks?.twitter || ""}
                                    />
                                    {errors?.twitter && (
                                        <p style={{ color: "red", textAlign: 'left' }}>
                                            {errors?.twitter?.message}
                                        </p>
                                    )}
                                </div>
                                <div className="col-lg-12 col-md-12 col-sm-12 col-12 form-group">
                                    <label className="text-left">Instagram</label>
                                    <Controller
                                        name="instagram"
                                        control={control}
                                        render={({ field: { value, onChange } }) => (
                                            <input
                                                className="input_fixed_width"
                                                type="text"
                                                value={value}
                                                onChange={onChange}
                                                autoComplete="false"
                                            />
                                        )}
                                        defaultValue={userlinks?.instagram || ""}
                                    />
                                    {errors?.instagram && (
                                        <p style={{ color: "red", textAlign: 'left' }}>
                                            {errors?.instagram?.message}
                                        </p>
                                    )}
                                </div>
                                <div className="col-lg-12 col-md-12 col-sm-12 col-12 form-group">
                                    <label className="text-left">Facebook</label>
                                    <Controller
                                        name="facebook"
                                        control={control}
                                        render={({ field: { value, onChange } }) => (
                                            <input
                                                className="input_fixed_width"
                                                type="text"
                                                value={value}
                                                onChange={onChange}
                                                autoComplete="false"
                                            />
                                        )}
                                        defaultValue={userlinks?.facebook || ""}
                                    />
                                    {errors?.facebook && (
                                        <p style={{ color: "red", textAlign: 'left' }}>
                                            {errors?.facebook?.message}
                                        </p>
                                    )}
                                </div>
                                <div className="col-lg-12 col-md-12 col-sm-12 col-12 form-group">
                                    <label className="text-left">YouTube</label>
                                    <Controller
                                        name="youtube"
                                        control={control}
                                        render={({ field: { value, onChange } }) => (
                                            <input
                                                className="input_fixed_width"
                                                type="text"
                                                value={value}
                                                onChange={onChange}
                                                autoComplete="false"
                                            />
                                        )}
                                        defaultValue={userlinks?.youtube || ""}
                                    />
                                    {errors?.youtube && (
                                        <p style={{ color: "red", textAlign: 'left' }}>
                                            {errors?.youtube?.message}
                                        </p>
                                    )}
                                </div>
                                <div className="col-lg-12 col-md-12 col-sm-12 col-12 form-group">
                                    <label className="text-left">Linkedin</label>
                                    <Controller
                                        name="linkedin"
                                        control={control}
                                        render={({ field: { value, onChange } }) => (
                                            <input
                                                className="input_fixed_width"
                                                type="text"
                                                value={value}
                                                onChange={onChange}
                                                autoComplete="false"
                                            />
                                        )}
                                        defaultValue={userlinks?.linkedin || ""}
                                    />
                                    {errors?.linkedin && (
                                        <p style={{ color: "red", textAlign: 'left' }}>
                                            {errors?.linkedin?.message}
                                        </p>
                                    )}
                                </div>
                                <div className="submit-area col-lg-12 col-12">
                                    <Link to={backPath} className="button-round button-back">
                                        Back to List
                                    </Link>
                                    <button type="submit" className="button-round" disabled={!isAnyFieldFilled} >
                                        Add Link
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </>
            )}
        </>
    );
};

export default SocialMediaLinks