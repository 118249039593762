import React, { useEffect } from "react";
import innerBannerImg1 from "../../Assets/Images/Event-bg-01-01.jpg";
import { useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { getEvent, getPaginatedEvents } from "../../Slice/events";
import Loader from "../../Components/Loader";
import moment from "moment";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import { Carousel } from "react-bootstrap";
import { stringToNumber } from "../../utils/encrypt";

function EventSingle() {
  const { title } = useParams();
  console.log(title)
  const lastIndex = title.lastIndexOf('-');
  const eventId = title.substring(lastIndex + 1);
  const intID = stringToNumber(eventId);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { event, events, isLoading } = useSelector((state) => state.event);
  const state = {
    page: 1,
    pageSize: 9,
  };

  useEffect(() => {
    dispatch(getEvent(intID));
    dispatch(getPaginatedEvents(state.page, state.pageSize));
  }, []);

  const calculateTimeLeft = () => {
    const difference = +new Date(event?.startDateTime) - +new Date();
    let timeLeft = {};

    if (difference > 0) {
      timeLeft = {
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((difference / 1000 / 60) % 60),
        seconds: Math.floor((difference / 1000) % 60),
      };
    }
    return timeLeft;
  };

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  useEffect(() => {
    const timer = setTimeout(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    return () => clearTimeout(timer);
  });

  const formatTime = (time) => moment(time).format("hh:mm A");
  const formatDate = (day) => moment(day).format("D MMMM, YYYY");

  const isEventLive = new Date(event?.startDateTime) <= new Date();
  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <main id="content" className="site-main">
          <Helmet>
            <title>Charity Events by Nangal by Cycle | Join & Support Us</title>
            <meta name="description" content="Discover upcoming charity events hosted by Nangal by Cycle. Join us to make a difference and support our mission of promoting rural culture and sustainability." />
          </Helmet>
          <section className="inner-banner-wrap">
            <div
              className="inner-baner-container"
              style={{ backgroundImage: `url(${innerBannerImg1})` }}
            >
              <div className="container">
                <div className="inner-banner-content">
                  <h1 className="inner-title">Event details</h1>
                </div>
              </div>
            </div>
          </section>
          <>
            <section className="event-page-section">
              <div className="container">
                <div className="row">
                  <div className="col-lg-8 primary right-sidebar">
                    {event ? (
                      <div className="event-detail-container">
                        <h2>{event?.title}</h2>
                        <figure className="event-feature-img">
                          <Carousel indicators={false} controls={false} slide={true} interval={3000}>
                            {/* Display the main image */}
                            {event?.imageUrl && (
                              <Carousel.Item>
                                <img
                                  src={event?.imageUrl}
                                  alt="Main Event Thumbnail"
                                />
                              </Carousel.Item>
                            )}
                            {event.images && event.images.length > 0 &&
                              event.images.map((image, index) => (
                                <Carousel.Item key={index}>
                                  <img
                                    src={image.imageUrl}
                                    alt={`Event Image ${index + 1}`}
                                  />
                                </Carousel.Item>
                              ))
                            }
                          </Carousel>
                        </figure>
                        {!isEventLive && (
                          <div className="time-counter-wrap">
                            <div className="time-counter" >
                              <div className="counter-time" style={{ zIndex: 10 }}>
                                <span className="counter-days">
                                  {timeLeft?.days ? timeLeft?.days : 0}
                                </span>
                                <span className="label-text">Days</span>
                              </div>
                              <div className="counter-time" style={{ zIndex: 10 }}>
                                <span className="counter-hours">
                                  {timeLeft?.hours ? timeLeft?.hours : 0}
                                </span>
                                <span className="label-text">Hours</span>
                              </div>
                              <div className="counter-time" style={{ zIndex: 10 }}>
                                <span className="counter-minutes">
                                  {timeLeft?.minutes ? timeLeft?.minutes : 0}
                                </span>
                                <span className="label-text">Minutes</span>
                              </div>
                              <div className="counter-time" style={{ zIndex: 10 }}>
                                <span className="counter-seconds">
                                  {timeLeft?.seconds ? timeLeft?.seconds : 0}
                                </span>
                                <span className="label-text">Seconds</span>
                              </div>
                            </div>
                            <div className="event-text">
                            </div>
                          </div>
                        )}
                      </div>
                    ) : (
                      <div>Loading event...</div>
                    )}
                    <div className="icon-box-wrap">
                      <h2>Event Description</h2>
                      <div className="react_quill_editor" dangerouslySetInnerHTML={{ __html: event?.description }} />
                    </div>
                  </div>
                  <div className="col-lg-4 secondary">
                    <div className="sidebar">
                      <div className="widget widget-bg widget-detail">
                        <h4 className="bg-title">Event Details</h4>
                        <ul>
                          <li className="d-flex">
                            <h5>Starting time:</h5>
                            <span>
                              {formatTime(event?.startDateTime)}
                            </span>
                          </li>
                          <li className="d-flex">
                            <h5>Event date:</h5>
                            <span>{formatDate(event?.startDateTime)}</span>
                          </li>
                          <li className="d-flex">
                            <h5>Phone number:</h5>
                            <span>{event?.contact ? event.contact : 'XXXXXXXXXX'}</span>
                          </li>
                          <li className="d-flex">
                            <h5>Location:</h5>
                            <span>{event?.location}</span>
                          </li>
                        </ul>
                      </div>
                      {events.length > 1 && (
                        <div className="widget widget-bg icon-list-content text-center">
                          <h4 className="bg-title">Upcoming Events</h4>
                          <ul>
                            {events
                              .filter((element) => element.title !== title)
                              .slice(0, 4)
                              .map((element) => (
                                <li key={element.eventId}>
                                  <Link
                                    to={`/event-single/${element?.eventId}`}
                                    onClick={() => navigate(`/event-single/${element?.eventId}`)}
                                  >
                                    <i className="icon icon-arrow-right-circle"></i>
                                    {element?.title}
                                  </Link>
                                </li>
                              ))
                            }
                          </ul>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </>
        </main>
      )}
    </>
  );
}

export default EventSingle;
