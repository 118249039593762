import { Link } from 'react-router-dom';
import innerBannerImg1 from '../../Assets/Images/Event-bg-01-01.jpg';
import { Helmet } from 'react-helmet';

export default function BecomeAnNBCMMember() {
        return (
                <main id="content" className="site-main">
                        <Helmet>
                                <title>Experience Nangal by Cycle - Your Skilled Adventure Awaits</title>
                                <meta name="description" content="Discover the beauty of Nangal on a bicycle. Our expert guides offer skilled services to ensure a memorable journey. Connect with us today for an adventure tailored to your needs." />
                        </Helmet>
                        <section className="inner-banner-wrap pb-0">
                                <div className="inner-baner-container"
                                        style={{ backgroundImage: `url(${innerBannerImg1})` }}
                                >
                                        <div className="container">
                                                <div className="inner-banner-content">
                                                        <h1 className="inner-title">Become an NBC Member</h1>
                                                </div>
                                        </div>
                                </div>
                        </section>
                        <div className="volunteer-wrap">
                                <div className="container mt-5">
                                        <div className="event-content">
                                                <h3>Join our vibrant community and gain access to range of activities and services designed to enrich our local area. As an NBC member, you can:</h3>
                                                <ul style={{ listStyleType: "square" }} className="m- 0">
                                                        <li className="mb-3">Participate in community activities, cycling, and other sports events.</li>
                                                        <li className="mb-3">Offer your skilled services and connect with those in need.</li>
                                                        <li className="mb-3">Write blogs and share your thoughts on various topics.</li>
                                                        <li className="mb-3">Upload and share your favorite nature photographs taken.
                                                        </li>
                                                        <li className="mb-3">Stay informed about local events and health awareness initiatives.</li>
                                                        <li className="mb-3">Learn about best practices for health and financial freedom.</li>
                                                </ul>
                                        </div>
                                        <div>
                                                <p>Our platform is designed to support and connect all members of our community. Register today and start contributing to the growth and well-being of our area!</p>

                                        </div>
                                        <div className="text-center">
                                                <Link to={"/auth/skills"} type="button" className="button-round">Register as an NBC Member</Link>
                                        </div>
                                </div>
                        </div>
                </main >
        )
}
