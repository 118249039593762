import React from 'react';
import innerBannerImg1 from '../../Assets/Images/Event-bg-01-01.jpg';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { updateRegisterRoleVolunteer } from '../../Slice/master';
import { Helmet } from 'react-helmet';

function BecomeVolunteer() {

    const dispatch = useDispatch();
    const handleClick = () => {
        dispatch(updateRegisterRoleVolunteer());
    }
    return (
        <>
            <main id="content" className="site-main">
                <Helmet>
                    <title>Volunteer Opportunities at Nangal By Cycle | Make a Difference</title>
                    <meta name="description" content="Join Nangal By Cycle as a volunteer. Help promote eco-friendly tourism, lead bicycle tours, and support our community initiatives in Nangal." />
                </Helmet>
                <section className="inner-banner-wrap pb-0">
                    <div className="inner-baner-container"
                        style={{ backgroundImage: `url(${innerBannerImg1})` }}
                    >
                        <div className="container">
                            <div className="inner-banner-content">
                                <h1 className="inner-title">Become a Volunteer</h1>
                            </div>
                        </div>
                    </div>
                </section>
                <div className="volunteer-wrap">
                    <div className="container">
                        <div className="section-head mt-5 mb-3">
                            <div className="row align-items-end">
                                <div className="col-lg-12">
                                    <div className="sub-title mb-2">Volunteering with Nangal By Cycle</div>
                                    <h2>Why Volunteer at Nangal By Cycle</h2>
                                </div>
                                <div className="col-lg-12">
                                    <div className="section-disc">
                                        <p>Are you ready to make difference in your community while enriching your own life? Join us
                                            in becoming volunteer for social and sports events organized by local authorities. As
                                            someone who has experienced the profound impact of volunteerism firsthand, I can attest to
                                            the transformative power it holds.

                                            During my college years, I was an active member of the National Social Society, where I
                                            learned the value of giving back and serving others. Today, even amidst my busy
                                            professional life, I make it priority to volunteer whenever I can. Whether it’s lending
                                            hand at World Expo 2020 or participating in initiatives like COP28, each experience
                                            reaffirms the importance of community engagement and collective action.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="event-content">
                            <h3>But why should you consider volunteering? Here are some compelling reasons:</h3>
                            <ul style={{ listStyleType: "square" }} className="m- 0">
                                <li className="mb-3"><b>Personal Growth : </b> Volunteering provides invaluable opportunities for
                                    personal growth and development. From honing leadership skills to fostering empathy and
                                    compassion, each volunteer experience offers valuable lessons that can shape your character and
                                    worldview.</li>
                                <li className="mb-3"><b>Community Connection : </b> Connection: By volunteering, you become an integral
                                    part of your community’s fabric. You forge meaningful connections with fellow volunteers and
                                    members of the community, creating a sense of belonging and camaraderie that is truly rewarding.
                                </li>
                                <li className="mb-3"><b>Making an Impact : </b> Every act of volunteerism, no matter how small, has the
                                    potential to make a significant impact. Whether you’re helping organize a local sports event or
                                    participating in a cleanup drive, your contribution matters and contributes to the betterment of
                                    society.</li>
                                <li className="mb-3"><b>Building Networks : </b> Volunteering allows you to expand your social and
                                    professional networks. You’ll meet people from diverse backgrounds and industries, opening doors
                                    to new opportunities and collaborations that can enrich your personal and professional life.
                                </li>
                                <li className="mb-3"><b>Sense of Fulfillment : </b> There’s a profound sense of fulfillment that comes
                                    from knowing you’ve made a positive difference in someone’s life or contributed to the success
                                    of a community event. It’s a feeling that money can’t buy and one that leaves a lasting
                                    impression on both you and those you serve.</li>
                            </ul>
                            <div className="section-disc">
                                <p>So why not embark on this meaningful journey of volunteerism today? Together, let’s create a
                                    community where compassion, generosity, and solidarity reign supreme. Join us in shaping a
                                    brighter future for our small town and beyond. Become a volunteer and be the change you wish to
                                    see in the world.</p>
                            </div>
                        </div>
                        <div className="text-center">
                            <Link onClick={handleClick} to={"/auth/register"} type="button" className="button-round">Register as a Volunteer</Link>
                        </div>
                    </div>
                </div>
            </main >
        </>
    )
}

export default BecomeVolunteer