import * as yup from "yup";
import { emailrgx } from "../Components/constant/Constant";
import { pinCodergx } from "../Components/constant/Constant";

export const volunteerSchema = yup.object({
  volunteerFullName: yup.string().required("Name is required").trim(),
  volunteerDOB: yup
    .date()
    .typeError("Invalid Date")
    .required("Date of Birth is required")
    .max(new Date(), "Date of Birth cannot be in the future")
    .test('is-at-least-18', 'Age must be at least 18 years old', function (value) {
      const eighteenYearsAgo = new Date();
      eighteenYearsAgo.setFullYear(eighteenYearsAgo.getFullYear() - 18);
      return value <= eighteenYearsAgo;
    }),
  volnteerGender: yup.string().required("Gender is required").trim(),
  currentProfession: yup.string().required("Current Profession is required"),
  // volunteerInterest: yup
  //   .string()
  //   .required("Interests/Area of Expertise is required"),
  password: yup.string().required("Password is required").trim(),
  cpassword: yup
    .string()
    .required("Confirm Password is required")
    .oneOf([yup.ref("password"), null], "Passwords must match"),
  volunteerEmail: yup
    .string()
    .required("Email is required")
    .matches(emailrgx, "Invalid Email")
    .trim(),
  volunteerContact: yup
    .string()
    .required("Phone Number is required")
    .max(12)
    .min(10),
  addressLine1: yup.string().required("Field is required").trim(),
  addressLine2: yup.string().trim(),
  village: yup.string().required("Village/City is required"),
  pincode: yup.string().required("Pin Code is required").matches(pinCodergx, "Pin Code must be 6 digits number"),
  state: yup.string().required("State is required"),
  preferredContact: yup
    .string()
    .required("Please select the Contact Preference"),
  //availability: yup.string(),
  //additionalComments: yup.string(),
  userProfile: yup
    .mixed()
    .required('Profile photo is required')
    .test("fileType", "Invalid file type", (value) => {
      if (!value) return true; // Allow null values
      return ["image/jpeg", "image/png", "image/jpg"].includes(value.type);
    }),
  agreeToTerms: yup.boolean()
    .required('Required')
    .oneOf([true], 'You must accept the terms and conditions')
});

export const skilledPersonSchema = yup.object({
  skilledPersonFullName: yup.string().required("Name is required").trim(),
  skilledPersonDOB: yup
    .date()
    .typeError("Invalid Date")
    .required("Date of Birth is required")
    .max(new Date(), "Date of Birth cannot be in the future")
    .test('is-at-least-18', 'Age must be at least 18 years old', function (value) {
      const eighteenYearsAgo = new Date();
      eighteenYearsAgo.setFullYear(eighteenYearsAgo.getFullYear() - 18);
      return value <= eighteenYearsAgo;
    }),
  skilledPersonGender: yup.string().required("Gender is required").trim(),
  currentProfession: yup.string().required("Current Profession is required"),
  password: yup.string().required("Password is required").trim(),
  cpassword: yup
    .string()
    .required("Confirm Password is required")
    .oneOf([yup.ref("password"), null], "Passwords must match"),
  skilledPersonEmail: yup
    .string()
    .required("Email is required")
    .matches(emailrgx, "Invalid Email")
    .trim(),
  skilledPersonContact: yup
    .string()
    .required("Phone Number is required")
    .max(12)
    .min(10),
  addressLine1: yup.string().required("Field is required").trim(),
  addressLine2: yup.string().trim(),
  village: yup.string().required("Village/City is required"),
  pincode: yup.string().required("Pin Code is required").matches(pinCodergx, "Pin Code must be 6 digits"),
  state: yup.string().required("State is required"),
  preferredContact: yup
    .string()
    .required("Please select the Contact Preference"),
  userProfile: yup
    .mixed()
    .required('Profile photo is required')
    .test("fileType", "Invalid file type", (value) => {
      if (!value) return true;
      return ["image/jpeg", "image/png", "image/jpg"].includes(value.type);
    }),
  agreeToTerms: yup.boolean()
    .required('Required')
    .oneOf([true], 'You must accept the terms and conditions')
});

export const donorSchema = yup.object({
  donorFullName: yup.string().required("Name is required").trim(),
  donorGender: yup.string().required("Please select the gender").trim(),
  donorDOB: yup
    .date()
    .typeError("Invalid Date")
    .required("Date of birth is required")
    .max(new Date(), "Date of Birth cannot be in the future")
    .test('is-at-least-18', 'Age must be at least 18 years old', function (value) {
      const eighteenYearsAgo = new Date();
      eighteenYearsAgo.setFullYear(eighteenYearsAgo.getFullYear() - 18);
      return value <= eighteenYearsAgo;
    }),
  bloodType: yup.string().required("Blood Type is required"),
  medicalHistory: yup.string(),
  donorEmail: yup
    .string()
    .required("Email is required")
    .matches(emailrgx, "Invalid Email")
    .trim()
    .trim(),
  donorContact: yup
    .string()
    .required("Phone Number is required")
    .max(12)
    .min(10),
  addressLine1: yup.string().required("Field is required").trim(),
  addressLine2: yup.string(),
  village: yup.string().required("Village/City is required"),
  pincode: yup.string().required("Pin Code is required").matches(pinCodergx, "Pin Code must be 6 digits"),
  donorProfile: yup
    .mixed()
    .required('Profile photo is required')
    .test("fileType", "Invalid file type", (value) => {
      if (!value) return true; // Allow null values
      return ["image/jpeg", "image/png", "image/jpg"].includes(value.type);
    }),
  state: yup.string().required("State is required"),
  preferredContact: yup.string().required("Please select the Contact Preference"),
  agreeToTerms: yup.boolean()
    .required('Required')
    .oneOf([true], 'You must accept the terms and conditions')
});


export const updateVolSchema = yup.object({
  fullName: yup.string().required("Name is required").trim(),
  email: yup.string().required("Email is required").trim(),
  dob: yup
    .date()
    .typeError("Invalid Date")
    .required("Date of Birth is required")
    .max(new Date(), "Date of Birth cannot be in the future")
    .test('is-at-least-18', 'Age must be at least 18 years old', function (value) {
      const eighteenYearsAgo = new Date();
      eighteenYearsAgo.setFullYear(eighteenYearsAgo.getFullYear() - 18);
      return value <= eighteenYearsAgo;
    }),
  contact: yup.string().required("Phone Number is required"),
  gender: yup.string().required("Gender is required").trim(),
  profession: yup.string().required("Current Profession is required"),
  interests: yup.string().required("Interests/Area of Expertise is required"),
  addressLine1: yup.string().required("Field is required").trim(),
  addressLine2: yup.string().nullable(),
  state: yup.string().required("State is required"),
  pincode: yup.string().required("Pin Code is required").matches(pinCodergx, "Pin Code must be 6 digits"),
  village: yup.string().required("Village/City is required"),
  availability: yup.string().nullable(),
  preferredContact: yup
    .string()
    .required("Please select the Contact Preference"),
});

export const updateUserSchema = yup.object({
  fullName: yup.string().required("Name is required").trim().min(3, "Name must be at least 3 characters"),
  dob: yup
    .date()
    .typeError("Invalid Date")
    .required("Date of Birth is required")
    .max(new Date(), "Date of Birth cannot be in the future")
    .test('is-at-least-18', 'Age must be at least 18 years old', function (value) {
      const eighteenYearsAgo = new Date();
      eighteenYearsAgo.setFullYear(eighteenYearsAgo.getFullYear() - 18);
      return value <= eighteenYearsAgo;
    }),
  gender: yup.string().required("Gender is required").trim(),
  profession: yup.string().required("Current Profession is required"),
  interests: yup.string().trim(),
  email: yup
    .string()
    .required("Email is required")
    .matches(emailrgx, "Invalid Email")
    .trim(),
  contact: yup.string().required("Phone Number is required").max(12).min(10),
  addressLine1: yup.string().required("Field is required").trim(),
  addressLine2: yup.string().nullable(),
  village: yup.string().required("Village/City is required"),
  pincode: yup.string().required("Pin Code is required").matches(/^[0-9]{6}$/, "Pin Code must be 6 digits")
  ,
  state: yup.string().required("State is required"),
  preferredContact: yup
    .string()
    .required("Please select the Contact Preference"),
  additionalComments: yup.string(),
  userProfile: yup.mixed()
    .required("User Profile is required")
    .test("fileType", "Invalid file type", (value) => {
      if (!value) return true;
      return ["image/jpeg", "image/png", "image/jpg"].includes(value.type);
    }),
});

export const heroSchema = yup.object({
  name: yup.string().required("Hero's Name is required").trim(),
  recognition_title: yup.string().trim(),
  recognition_description: yup.string().required("Description is required"),
  recognition_date: yup.string(),
  photo_url: yup.mixed()
    .required("Photo is required")
    .test("fileType", "Invalid file type", (value) => {
      if (!value) return true;
      return ["image/jpeg", "image/png", "image/jpg"].includes(value.type);
    }),
}).required();