import React from 'react'
import Slider from 'react-slick';
import sanjeevAgarwalaImg from '../../Assets/Images/Sanjeev Agarwala.jpg';
import unboundImg12 from '../../Assets/Images/Anurag Yadav_Shortlisted Pic.JPG';
import unboundImg13 from '../../Assets/Images/unbound-img13.jpg';

const settings = {
    dots: true,
    infinite: true,
    speed: 2000,
    arrows: false,
    slidesToShow: 1,
    autoplay: true,
    responsive: [
        { breakpoint: 768, settings: { slidesToShow: 1 } },
        { breakpoint: 479, settings: { slidesToShow: 1 } },
    ],
};

function HomeTestimonialSlider() {
    return (
        <>
            <Slider {...settings}>
                <div className="testimonial-item text-center">
                    <div className="testimonial-content">
                        <div className="testimonial-icon">
                            <i aria-hidden="true" className="icon icon-quote"></i>
                        </div>
                        <p>Dear Rohit,<br />
                            It does not take long to say that this is an incredible initiative and super concept. The web site is also fantastic and leaves no room for improvement.  I see that your accomplished children(co-founders) have designed and coded this site.  With such talent, they have a great future similar to their Father.<br />
                            In summary, I believe this is what people look for…. “Purpose of Life”…. And I think you found one.<br />
                            My congratulations to you on this achievement and wish you well in your endeavours.
                        </p>
                    </div>
                    <figure className="testimonial-img">
                        <img src={sanjeevAgarwalaImg} alt="Sanjeev Agarwala" />
                    </figure>
                    <h3>Sanjeev Agarwala </h3>
                    <h5>Founder and CEO at A R I A Consultancy<br />
                        Dubai - UAE
                    </h5>
                </div>
                <div className="testimonial-item text-center">
                    <div className="testimonial-content">
                        <div className="testimonial-icon">
                            <i aria-hidden="true" className="icon icon-quote"></i>
                        </div>
                        <p>I am truly inspired by your community support initiatives, which reflect a commendable approach to local engagement. Your efforts in volunteer and blood donor registration will undoubtedly save lives, while providing platforms for local blogs and service listings strengthens community connectivity. Promoting health awareness and best practices is crucial for regional well-being, and your work in this area is vital for societal growth.
                            <br />At Gleneagles Healthcare India, we share this dedication through initiatives like our 'Clean Hands' and Cervical Cancer awareness campaigns. Our 'Saksham - Entrepreneurship for Women' project supports marginalized women in starting businesses, and our 'Poshan & Promoting Menstrual Health and Hygiene' initiative has benefited over 2,000 children and 1,520 schoolgirls.
                        </p>
                    </div>
                    <figure className="testimonial-img">
                        <img src={unboundImg12} alt="unboundImg12" />
                    </figure>
                    <h3>Anurag Yadav</h3>
                    <h5>Chief Executive Officer<br />
                        Gleneagles Healthcare India
                    </h5>
                </div>
                <div className="testimonial-item text-center">
                    <div className="testimonial-content">
                        <div className="testimonial-icon">
                            <i aria-hidden="true" className="icon icon-quote"></i>
                        </div>
                        <p>After my family benefited from Nangal By Cycle services, I knew I wanted to pay it forward. Their caring staff helped us through a difficult time, and I'm grateful to have the opportunity to help others through my donations.</p>
                    </div>
                    <figure className="testimonial-img">
                        <img src={unboundImg13} alt="unboundImg13" />
                    </figure>
                    <h3>Dheeraj Ramrana</h3>
                </div>
            </Slider>
        </>
    )
}

export default HomeTestimonialSlider