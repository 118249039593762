import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { BiSolidDonateBlood } from "react-icons/bi";
import { RiTeamFill } from "react-icons/ri";
import { IoPersonAddSharp } from "react-icons/io5";
import innerBannerImg1 from "../../Assets/Images/Event-bg-01-01.jpg";


export default function RegisterPage() {
        const navigate = useNavigate();
        const [selectedOption, setSelectedOption] = useState('');

        const handleOptionChange = (e) => {
                setSelectedOption(e.target.id);
        }
        const handleClick = () => {
                if (selectedOption === 'donor_register') {
                        navigate('/auth/register', { state: { role: 'donor' } });
                } else if (selectedOption === 'volunteer_register') {
                        navigate('/auth/register', { state: { role: 'volunteer' } });
                }
                else if (selectedOption === 'nbc_member') {
                        navigate('/auth/skills');
                }
        }

        return (
                <>
                        <section className="inner-banner-wrap">
                                <div
                                        className="inner-baner-container"
                                        style={{ backgroundImage: `url(${innerBannerImg1})` }}
                                >
                                        <div className="container">
                                                <div className="inner-banner-content">
                                                        <h1 className="inner-title main_title">Register As</h1>
                                                </div>
                                        </div>
                                </div>
                        </section>
                        <div className="container register_member_container">
                                <div className="row w-100 m-0">
                                        <div className="col-md-4 responsive_card">
                                                <div class="card overflow-visible mx-sm-1 p-3 form_hover" style={{ border: '2px solid #CB4B36' }}>
                                                        <label htmlFor="donor_register" className="pe-2 text-light mb-3 member_label register_form">
                                                                <input type="radio" id="donor_register" className="form-check-input radio_style d-none" name="register" onClick={handleClick} onChange={handleOptionChange} />
                                                                <div className="card border-2 p-3 my-card" >
                                                                        <BiSolidDonateBlood className='text-center fs-3' style={{ color: '#CB4B36' }} />
                                                                </div>
                                                                <div className="text-info text-center mt-5">
                                                                        <h3 className='title_text'>Blood Donor</h3>
                                                                </div>
                                                        </label>
                                                </div>
                                        </div>
                                        <div className="col-md-4 responsive_card">
                                                <div className="card overflow-visible mx-sm-1 p-3 form_hover" style={{ border: '2px solid #CB4B36' }}>
                                                        <label htmlFor="volunteer_register" className="pe-2 text-light mb-3 member_label register_form">
                                                                <input type="radio" id="volunteer_register" className="form-check-input radio_style d-none" name="register" onClick={handleClick} onChange={handleOptionChange} />
                                                                <div className="card border-2 p-3 my-card">
                                                                        <RiTeamFill className='text-center fs-3' style={{ color: '#CB4B36' }} />
                                                                </div>
                                                                <div className="text-info text-center mt-5">
                                                                        <h3 className='title_text'>Volunteer</h3>
                                                                </div>
                                                        </label>
                                                </div>
                                        </div>
                                        <div className="col-md-4">
                                                <div className="card overflow-visible mx-sm-1 p-3 form_hover" style={{ border: '2px solid #CB4B36' }}>
                                                        <label htmlFor="nbc_member" className="pe-2 text-light mb-3 member_label register_form">
                                                                <input type="radio" id="nbc_member" className="form-check-input radio_style d-none" name="register" onClick={handleClick} onChange={handleOptionChange} />
                                                                <div className="card border-2 p-3 my-card">
                                                                        <IoPersonAddSharp className='text-center fs-3' style={{ color: '#CB4B36' }} />
                                                                </div>
                                                                <div className="text-info text-center mt-5">
                                                                        <h3 className='title_text'>NBC Member</h3>
                                                                </div>
                                                        </label>
                                                </div>
                                        </div>
                                </div>
                        </div>
                </>
        )
}
