import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { getUserInfoFromToken } from "../../constant/Constant";
import { getAllCategories } from "../../../Slice/master";
import ReactSelect from "react-select";
import { getPersonSkillbyid, getPersonSkills, updatePersonSkillById } from "../../../Slice/personSpecializedSkill";
import { useNavigate, useParams } from 'react-router-dom';
import Loader from "../../Loader";

const schema = yup.object({
  category: yup.string().required("Mandatory*").trim(),
  description: yup.string().required("Mandatory*").trim(),
});

const UpdateSpecializedSkill = () => {

  const [selectedOption, setSelectedOption] = useState(null);
  const [isDelay, setIsDelay] = useState(true);
  const [updating, setUpdating] = useState(false);
  const userInfo = getUserInfoFromToken();
  const user = userInfo?.userId
  const { id } = useParams();

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsDelay(false);
    }, 1000);
  })

  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    const reloadData = async () => {
      dispatch(getAllCategories())
      dispatch(getPersonSkillbyid(id));
      dispatch(getPersonSkills(user));
    };
    reloadData();
  }, []);

  const { categories, isLoading: categoriesLoading } = useSelector((state) => state.masterSlice);
  console.log(categories)

  const { skill, personSpecializedSkills, isLoading: skillsLoading } = useSelector((state) => state.PersonSkills);
  console.log(personSpecializedSkills)

  // Create a set of category names from personSpecializedSkills
  const personSpecializedCategoryNames = new Set(personSpecializedSkills.map(skill => skill.categoryName));

  // Filter out categories that are already in personSpecializedSkills
  const filteredCategories = categories?.filter(category => !personSpecializedCategoryNames.has(category.categoryName));

  // Map the filtered categories to options
  const CategoriesOptions = filteredCategories?.map((category) => ({
    value: category.categoryName,
    label: category.categoryName,
  }));

  // Add "Other" option
  CategoriesOptions.push({ value: "other", label: "Other" });

  console.log(CategoriesOptions);

  const handleSelectChange = (selected) => {
    if (selected?.value === "other") {
      setSelectedOption("other");
    } else {
      setSelectedOption(selected?.value);
    }
  };
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmit = async (data) => {
    const requestData = {
      category: data?.category,
      description: data?.description,
    };
    dispatch(updatePersonSkillById(id, requestData));
    setUpdating(false);
  };

  return (
    <>
      {(categoriesLoading || skillsLoading || updating || isDelay) ? (
        <Loader />
      ) : (
        <div id="content">
          <div className="row justify-content-center my-4">
            <div className="columns col-lg-7 col-md-7">
              <div className="volunteer-contact-form">
                <h3>Update Specialized Category:</h3>
                <form onSubmit={handleSubmit(onSubmit)} className="volunteer-form">
                  <div className="col-lg-12 col-md-12 col-sm-12 col-12 form-group mt-4">
                    <label htmlFor="" className="text-left">
                      Specialized Skills <span style={{ color: "#F15B43" }}>*</span>
                    </label>
                    {selectedOption === "other" ? (
                      <Controller
                        name="category"
                        control={control}
                        render={({ field: { value, onChange } }) => (
                          <input
                            className="input_fixed_width"
                            type="text"
                            placeholder="Enter Skill"
                            value={value}
                            onChange={(e) => onChange(e.target.value)}
                          />
                        )}
                      />
                    ) : (
                      <Controller
                        name="category"
                        control={control}
                        render={({ field: { value, onChange } }) => (
                          <ReactSelect
                            className="selectcustom"
                            placeholder="Select Skill"
                            options={CategoriesOptions}
                            value={CategoriesOptions.find(
                              (option) => option.value === value
                            )}
                            onChange={(selected) => {
                              if (selected?.value !== "other") {
                                onChange(selected?.value);
                              } else {
                                onChange("");
                              }
                              handleSelectChange(selected);
                            }}
                            isClearable
                            isSearchable
                            styles={{
                              control: (provided, state) => ({
                                ...provided,
                                border: errors?.village
                                  ? "1px solid red"
                                  : "1px solid #B8BDC9",
                                backgroundColor: "white",
                                minHeight: 45,
                                height: 45,
                                boxShadow: state.isFocused
                                  ? "0 0 0 2px transparent"
                                  : provided.boxShadow,
                                "&:hover": {
                                  border: errors?.village
                                    ? "1px solid red"
                                    : "1px solid #B8BDC9",
                                },
                                display: "flex",
                              }),
                              valueContainer: (provided) => ({
                                ...provided,
                                height: 45,
                                display: "flex",
                                alignItems: "center",
                                padding: "0 15px",
                              }),
                              input: (provided) => ({
                                ...provided,
                                margin: 0,
                                padding: 0,
                              }),
                              indicatorsContainer: (provided) => ({
                                ...provided,
                                height: 45,
                                display: "flex",
                                alignItems: "center",
                              }),
                              placeholder: (provided) => ({
                                ...provided,
                                display: "flex",
                                alignItems: "center",
                              }),
                            }}
                          />
                        )}
                        defaultValue={skill?.categoryName}
                      />
                    )}
                    {errors.category && (
                      <p style={{ color: "red", textAlign: 'left' }}>
                        {errors.category.message}
                      </p>
                    )}
                  </div>
                  <div className="col-lg-12 col-md-12 col-sm-12 col-12 form-group">
                    <label className="text-left">Skill description <span style={{ color: "#F15B43" }}>*</span></label>
                    <Controller
                      name="description"
                      control={control}
                      render={({ field: { value, onChange } }) => (
                        <textarea
                          type="text"
                          value={value}
                          className={`input_fixed_width line_height_textarea ${errors?.description ? 'valid_error' : ''}`}
                          onChange={onChange}
                        />
                      )}
                      defaultValue={skill?.description}
                    />
                    {errors?.description && (
                      <div style={{ color: "red" }} className="text-left">
                        {errors?.description?.message}
                      </div>
                    )}
                  </div>
                  <div className="submit-area col-lg-12 col-12">
                    <button type="submit" className="button-round">
                      update
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  )
}

export default UpdateSpecializedSkill