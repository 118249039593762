import React from "react";
import * as yup from "yup";
import innerBannerImg1 from "../Assets/Images/Event-bg-01-01.jpg";
import unboundImg5 from "../Assets/Images/unbound-img5.jpg";
import { addrequest } from "../Slice/contactRequest";
import { useDispatch, useSelector } from "react-redux";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import PhoneInput from "react-phone-input-2";
import Loader from "../Components/Loader";
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";

const schema = yup
  .object({
    name: yup.string().required("Name is required").trim(),
    email: yup.string().required("Email is required").trim(),
    subject: yup.string().required("Subject is required").trim(),
    phone: yup
      .number()
      .typeError("contact must be a number")
      .required("Phone Number is required"),
    description: yup.string().required("Description is required").trim(),
  })
  .required();

function Contact() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isLoading } = useSelector((state) => state.contact);

  const {
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmit = async (data) => {
    const requestData = {
      name: data?.name,
      email: data?.email,
      subject: data?.subject,
      phone: data?.phone,
      description: data?.description,
    };
    dispatch(addrequest(requestData, reset, navigate));
  };

  return (
    <>
      <main id="content" className="site-main">
        <Helmet>
          <title>Contact Nangal By Cycle | Book Your Bicycle Adventure Today</title>
          <meta name="description" content="Get in touch with Nangal By Cycle for tour bookings, rental inquiries, or custom cycling experiences. Start planning your eco-friendly Nangal adventure now." />
        </Helmet>
        <section className="inner-banner-wrap">
          <div
            className="inner-baner-container"
            style={{ backgroundImage: `url(${innerBannerImg1})` }}
          > 
            <div className="container">
              <div className="inner-banner-content">
                <h1 className="inner-title">Contact us</h1>
              </div>
            </div>
          </div>
        </section>
        <div className="contact-page-section">
          <div className="contact-form-inner">
            <div className="container">
              <div className="row">
                <div className="col-md-6">
                  <div className="contact-detail-container">
                    <div className="section-heading">
                      <div className="sub-title">CONTACT US</div>
                      <h2>Feel free to contact & reach us !!</h2>
                    </div>
                    <div className="contact-details-list">
                      <ul>
                        <li>
                          <span className="icon">
                            <i className="fas fa-envelope-open-text"></i>
                          </span>
                          <div className="details-content">
                            <h3>Email address :</h3>
                            <span>
                              <a
                                href="mailto:info@nangalbycycle.com?subject=Contact "
                                className="__cf_email__"
                                data-cfemail="1871767e77587c7775797176367b7775"
                              >
                                info@nangalbycycle.com
                              </a>
                            </span>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  {isLoading ? <Loader /> : (
                    <div
                      className="contact-from-wrap"
                      style={{ backgroundImage: `url(${unboundImg5})` }}>
                      <form
                        onSubmit={handleSubmit(onSubmit)}
                        className="contact-from"
                      >
                        <p>
                          <Controller
                            name="name"
                            control={control}
                            render={({ field: { value, onChange } }) => (
                              <input
                                placeholder="Your Name*"
                                type="text"
                                value={value}
                                onChange={onChange}
                                autoComplete="false"
                              />
                            )}
                            defaultValue=""
                          />
                          {errors?.name && (
                            <p style={{ color: "red", whiteSpace: "nowrap" }}>
                              {" "}
                              {errors?.name?.message}
                            </p>
                          )}
                        </p>
                        <p>
                          <Controller
                            name="email"
                            control={control}
                            render={({ field: { value, onChange } }) => (
                              <input
                                placeholder="Your Email*"
                                type="email"
                                value={value}
                                onChange={onChange}
                                autoComplete="false"
                              />
                            )}
                            defaultValue=""
                          />
                          {errors?.email && (
                            <p style={{ color: "red", whiteSpace: "nowrap" }}>
                              {" "}
                              {errors?.email?.message}
                            </p>
                          )}
                        </p>
                        <p>
                          <Controller
                            name="subject"
                            control={control}
                            render={({ field: { value, onChange } }) => (
                              <input
                                placeholder="Subject*"
                                type="text"
                                value={value}
                                onChange={onChange}
                                autoComplete="false"
                              />
                            )}
                            defaultValue=""
                          />
                          {errors?.subject && (
                            <p style={{ color: "red", whiteSpace: "nowrap" }}>
                              {" "}
                              {errors?.subject?.message}
                            </p>
                          )}
                        </p>
                        <p>
                          <Controller
                            name="phone"
                            control={control}
                            render={({ field: { value, onChange } }) => (
                              <PhoneInput
                                country={"in"}
                                value={value}
                                onChange={(phone) => onChange(phone)}
                              />
                            )}
                            defaultValue=""
                          />
                          {errors?.phone && (
                            <p style={{ color: "red", whiteSpace: "nowrap" }}>
                              {" "}
                              {errors?.phone?.message}
                            </p>
                          )}
                        </p>
                        <p className="width-full">
                          <Controller
                            name="description"
                            control={control}
                            render={({ field: { value, onChange } }) => (
                              <textarea
                                rows={8}
                                placeholder="Enter Your Message*"
                                type="text"
                                value={value}
                                onChange={onChange}
                              />
                            )}
                            defaultValue=""
                          />
                          {errors?.description && (
                            <p style={{ color: "red", whiteSpace: "nowrap" }}> {errors?.description?.message}</p>
                          )}
                        </p>
                        <p className="width-full">
                          <input type="submit" name="submit" value="Submit Now" />
                        </p>
                      </form>
                      <div className="overlay"></div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
}

export default Contact;
