import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import AdminLayout from '../Layouts/AdminLayout';
import UseLayout from '../Layouts/UserLayout';

const AuthGuard = ({ allowedRoles }) => {

  const user = JSON.parse(localStorage.getItem("user"));
  const role = user?.roleName;
  const location = useLocation();

  // Check if any of the user's roles match the allowedRoles
  const hasAllowedRole = role?.some(role => allowedRoles.includes(role));

  const layout = role?.includes("admin") ? <AdminLayout /> : <UseLayout />;

  return hasAllowedRole ? (
    layout
  ) : user ? (
    <Navigate to="/Un-Authorized" state={{ from: location }} replace />
  ) : (
    <Navigate to="/Un-Authorized" state={{ from: location }} replace />
  );
};

export default AuthGuard;