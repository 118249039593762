import React, { useEffect, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Loader from '../../Loader';
import { deletedonor } from '../../../Slice/bloodDonation';
import { BloodGroupOptions } from '../../../constants';
import { confirmAlert } from 'react-confirm-alert';
import { Table } from 'antd';
import { approveBloodRequirement, deleteRequirement, disApproveBloodRequirement, getPaginatedRequests, getRequirementById } from '../../../Slice/bloodRequirement';
import { getUserInfoFromToken, ROLES } from '../../constant/Constant';
import { GiCheckMark } from 'react-icons/gi';
import { FcCancel } from 'react-icons/fc';

const BloodRequirementList = () => {
    const dispatch = useDispatch();
    const userInfo = getUserInfoFromToken();
    const [selectedDonor, setSelectedDonor] = useState(null);
    const { bloodRequirementList, bloodRequirementCount, isLoading } = useSelector((state) => state.bloodRequirement);
    const [isDonorViewModalOpen, setDonorViewModalOpen] = useState(false);
    const [state, setState] = useState({
        search: "",
        page: 1,
        pagesize: 5,
        selectedBloodGroup: "",
    });

    useEffect(() => {
        dispatch(getPaginatedRequests(state.search, state.page, state.pagesize, state.selectedBloodGroup));
    }, [dispatch, state.search, state.page, state.pagesize, state.selectedBloodGroup]);

    const optionsBloodGroup = useMemo(() => {
        return BloodGroupOptions?.map((option) => ({
            value: option.value,
            label: option.label,
        }));
    }, []);

    const handleApproveClick = (id) => {
        const data = {
            reqId: id,
            userId: userInfo.userId
        }
        dispatch(approveBloodRequirement(data));
    }

    const handleDisApproveCLick = (id) => {
        const data = {
            reqId: id,
            userId: userInfo.userId
        }
        dispatch(disApproveBloodRequirement(data));
    }

    const onShowSizeChange = (current, pageSize) => {
        setState({ ...state, page: 1, pagesize: pageSize });
    };

    const itemRender = (current, type, originalElement) => {
        if (type === 'prev') {
            return <button className="btn btn-sm btn-primary">Previous</button>;
        }
        if (type === 'next') {
            return <button className="btn btn-sm btn-primary">Next</button>;
        }
        return originalElement;
    };

    const columns = [
        {
            title: 'Name',
            dataIndex: 'name',
            sorter: (a, b) => a.name.length - b.name.length,
        },
        {
            title: 'Blood Group',
            dataIndex: 'blood_type',
            sorter: (a, b) => a.blood_type.length - b.blood_type.length,
        },
        {
            title: 'Phone Number',
            dataIndex: 'contact',
            sorter: (a, b) => a.contact.length - b.contact.length,
        },
        {
            title: 'Location',
            dataIndex: 'location',
            sorter: (a, b) => a.location.length - b.location.length,
        },
        {
            title: 'Status',
            render: (record) => {
                // if (record.is_delete_requested === 1) {
                //     return 'Delete Requested';
                // }
                if (record.isApproved === 1) {
                    return 'Approved';
                } else if (record.isApproved !== 1) {
                    return 'Unapproved';
                } else {
                    return 'Created';
                }
            },
        },
        {
            title: "Action",
            render: (text, record) => (
                <div className="d-flex justify-content-around" data-popper-placement="bottom-end">

                    {userInfo.roleName.includes(ROLES.Admin) && record.isApproved !== 1 && (
                        <Link to={"#"} title="Approve" className="dropdown-item px-2 text-success" onClick={() => handleApproveClick(record.req_id)}>
                            <GiCheckMark />
                        </Link>
                    )}
                    {userInfo.roleName.includes(ROLES.Admin) && record.isApproved === 1 && (
                        <Link to={"#"} title="DisApprove" className="dropdown-item px-2 text-success" onClick={() => handleDisApproveCLick(record.req_id)}>
                            <FcCancel />
                        </Link>
                    )}
                    <Link to={`/admin/updatebloodrequest/${record.req_id}`}
                        className="dropdown-item px-2 text-warning" onClick={() => {
                            handleUpdateClick(record.req_id)
                        }}>
                        <i className="fa fa-pencil m-r-5" />
                    </Link>
                    <Link
                        to={`#`}
                        title="View Profile"
                        className="dropdown-item px-2 text-primary"
                        onClick={() => handleViewClick(record)}
                    >
                        <i className={`fa fa-user`}></i>
                    </Link>
                    <Link className="dropdown-item px-2 text-danger" to="#" onClick={() => {
                        handleDeleteClick(record.req_id);
                    }}>
                        <i className="fa fa-trash m-r-5" />
                    </Link>
                </div>
            ),
        },
    ]
    const handleDeleteClick = (id) => {
        confirmAlert({
            title: "Confirm to delete",
            message: "Are you sure to do this.",
            buttons: [
                {
                    label: "Yes",
                    onClick: () => handleDelete(id),
                },
                {
                    label: "No",
                },
            ],
        });
    };

    const handleDelete = (id) => {
        dispatch(deleteRequirement(id));
    };

    const handleUpdateClick = (id) => {
        dispatch(getRequirementById(id));
    };

    const handleViewClick = (donor) => {
        setSelectedDonor(donor);
        setDonorViewModalOpen(true);
    };

    return (
        <div className="container-fluid mt-2">
            <div className='row justify-content-between align-items-center all_donor_header mb-2'>
                <div className='col-auto'>
                    <h1 className='h2'>Blood Requirement Request List</h1>
                </div>
                <div className="col-auto">
                    <Link to={"/admin/addbloodrequest"} className={`button-round border_radius`} type="button">
                        <i className={`fa fa-plus`} aria-hidden="true"></i> Add Request
                    </Link>
                </div>
            </div>
            <div className='row'>
                <div className="col-md-3 col-lg-3 col-sm-3 mb-2 mb-lg-2">
                    <select className="form-control" style={{ height: "2.4em" }} onChange={(e) => setState({ ...state, selectedBloodGroup: e.target.value })}>
                        {optionsBloodGroup?.map((option) => (
                            <option key={option.value} value={option.value} disabled={option.isDisabled} selected={option.isSelect}>
                                {option.label}
                            </option>
                        ))}
                    </select>
                </div>
                <div className="col-md-3 col-lg-3 col-sm-3 mb-2 mb-lg-2">
                    <input type="text" className="form-control" placeholder="Search" value={state.search} onChange={(e) => setState({ ...state, search: e.target.value })} />
                </div>
                {isLoading ? (
                    <Loader />
                ) : (
                    <Table
                        pagination={{
                            current: state.page,
                            pageSize: state.pagesize,
                            total: bloodRequirementCount,
                            showTotal: (total, range) =>
                                `Showing ${range[0]} to ${range[1]} of ${total} entries`,
                            showSizeChanger: true,
                            onShowSizeChange: onShowSizeChange,
                            itemRender: itemRender,
                            onChange: (page, pageSize) =>
                                setState({ ...state, page, pagesize: pageSize }),
                        }}
                        bordered
                        style={{ overflowX: "auto" }}
                        columns={columns}
                        dataSource={bloodRequirementList}
                        rowKey={(record) => record.donorId}
                    />
                )}
            </div>
            {/* <DonorModalView isDonorViewModalOpen={isDonorViewModalOpen} toggleDonorViewModal={() => setDonorViewModalOpen(!isDonorViewModalOpen)} selectedDonor={selectedDonor} /> */}
        </div>
    );
}

export default BloodRequirementList