import unboundLogo from '../../Assets/Images/unbound-logo.png';
import { Link, useNavigate } from 'react-router-dom';
import { Dropdown } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { logoutUser } from '../../Slice/authLogin';

function AdminHeader({ handleShow }) {

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const handleLogOutClick = () => {
        dispatch(logoutUser(navigate));
    }

    return (
        <>
            <nav className={`navbar navbar-expand-sm navbar-dark bg-dark position-sticky top-0`} style={{ zIndex: 1000 }}>
                <div className="container-fluid site-identity">
                    <button className='btn' onClick={handleShow} id='btn_toggle'>
                        <i className="h4 text-white fa-solid fa-bars"></i>
                    </button>
                    <Link to="#">
                        <img className="white-logo" src={unboundLogo} alt="logo" />
                    </Link>
                    <div className='d-flex gap-2'>
                        <Dropdown>
                            <Dropdown.Toggle variant="primary" id="dropdownBasic">
                                Admin
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                                <Dropdown.Item><Link style={{ color: "black" }} to={"/admin/user-profile"}><i className="fas fa-user"></i> Profile</Link></Dropdown.Item>
                                <Dropdown.Item><Link style={{ color: "black" }} to={"/password/change-password"}><i className="fas fa-screwdriver-wrench"></i> Setting</Link></Dropdown.Item>
                                <Dropdown.Divider />
                                <Dropdown.Item variant="danger" href="#" onClick={handleLogOutClick}>
                                    <i className="fas fa-arrow-right-from-bracket"></i> Logout
                                </Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                    </div>
                </div>
            </nav>
        </>
    )
}

export default AdminHeader;