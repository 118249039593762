import React, { useEffect, useState } from 'react';
import innerBannerImg1 from "../Assets/Images/Event-bg-01-01.jpg";
import { FaSearch } from "react-icons/fa";
import { getAllCategories } from '../Slice/master';
import { useDispatch, useSelector } from 'react-redux';
import { getAllPersonHavingSpecializedSkill } from '../Slice/personSpecializedSkill';
import Loader from "../Components/Loader";

const SpecializedSkilledPersons = () => {
  const [state, setState] = useState({
    search: "",
    selectedSkill: "",
    pageSize: 8,
    page: 1,
  });

  const [isDelay, setIsDelay] = useState(true);

  const dispatch = useDispatch();

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsDelay(false);
    }, 1000);

    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    dispatch(getAllCategories());
  }, [dispatch]);

  useEffect(() => {
    dispatch(getAllPersonHavingSpecializedSkill(state.search, state.page, state.pageSize, state.selectedSkill));
  }, [dispatch, state.search, state.page, state.pageSize, state.selectedSkill]);

  const { categories } = useSelector((state) => state.masterSlice);
  const { personHavingSpecializedSkills, isLoading } = useSelector((state) => state.PersonSkills);

  const CategoriesOptions = categories?.map((category) => ({
    value: category.categoryName,
    label: category.categoryName,
  }));

  const handleSearch = () => {
    setState({ ...state, page: 1 });
    dispatch(getAllPersonHavingSpecializedSkill(state.search, state.page, state.pageSize, state.selectedSkill));
  };

  const handleSelectedSkill = (e) => {
    const newSkill = e.target.value;  
    setState({ ...state, selectedSkill: newSkill, page: 1 });  
    dispatch(getAllPersonHavingSpecializedSkill(state.search, state.page, state.pageSize, newSkill));  
  };

  const handleViewMore = () => {
    setState(prevState => ({
      ...prevState,
      pageSize: prevState.pageSize + 8
    }));
  };

  return (
    <>
      {isLoading && isDelay ? (
        <Loader />  
      ) : (
        <main id="content" className="site-main">
          <section className="inner-banner-wrap">
            <div
              className="inner-baner-container"
              style={{ backgroundImage: `url(${innerBannerImg1})` }}
            >
              <div className="container">
                <div className="inner-banner-content">
                  <h1 className="inner-title">Skilled Services</h1>
                </div>
              </div>
            </div>
            <br />
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                padding: "20px",
                backgroundColor: "#f8f9fa",
                borderRadius: "8px",
                boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                width: "100%",
                maxWidth: "600px",
                margin: "0 auto",
                gap: "10px",
              }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  width: "70%",
                  backgroundColor: "#fff",
                  borderRadius: "4px",
                  padding: "10px",
                  boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                }}
              >
                <FaSearch style={{ marginRight: "10px", color: "#f15b43" }} />
                <input
                  type="text"
                  placeholder="Search..."
                  value={state.search}
                  onChange={(e) =>
                    setState(prevState => ({
                      ...prevState,
                      search: e.target.value
                    }))
                  }
                  style={{
                    flex: 1,
                    border: "none",
                    outline: "none",
                    paddingLeft: "10px",
                    fontSize: "16px",
                  }}
                />
              </div>

              <select
                value={state.selectedSkill}
                onChange={handleSelectedSkill}
                style={{
                  padding: "10px",
                  fontSize: "16px",
                  borderRadius: "4px",
                  border: "1px solid #ced4da",
                  backgroundColor: "#fff",
                }}
              >
                <option value="">Filter by</option>
                {CategoriesOptions?.map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </select>

              <button
                onClick={handleSearch}
                style={{
                  backgroundColor: "#f15b43",
                  color: "#fff",
                  border: "none",
                  padding: "10px 20px",
                  borderRadius: "4px",
                  cursor: "pointer",
                  transition: "background-color 0.3s ease",
                }}
                onMouseEnter={(e) => (e.target.style.backgroundColor = "#c04a35")}
                onMouseLeave={(e) => (e.target.style.backgroundColor = "#f15b43")}
              >
                Search
              </button>
            </div>
            <br />
            <div
              style={{
                display: "flex",
                flexWrap: "wrap",
                justifyContent: "center",
                gap: "75px",
                margin: "20px auto",
                maxWidth: "1200px",
              }}
            >
              {personHavingSpecializedSkills.map((skill, index) => (
                <div
                  key={index}
                  style={{
                    border: "1px solid #ddd",
                    borderRadius: "8px",
                    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                    backgroundColor: "#fff",
                    width: "300px", 
                    overflow: "hidden",
                    fontFamily: "Arial, sans-serif",
                  }}
                >
                  <div
                    style={{
                      backgroundColor: "#f15b43",
                      color: "#fff",
                      padding: "15px",
                      fontSize: "18px",
                      fontWeight: "bold",
                    }}
                  >
                    {skill.categoryName}
                  </div>
                  <div
                    style={{
                      padding: "15px",
                      color: "#333",
                    }}
                  >
                    {skill.description}
                  </div>
                  <div
                    style={{
                      backgroundColor: "#f9f9f9",
                      borderTop: "1px solid #ddd",
                      padding: "15px",
                      textAlign: "right",
                      fontSize: "14px",
                      color: "#666",
                    }}
                  >
                    {skill.personName}
                  </div>
                </div>
              ))}
            </div>
            {personHavingSpecializedSkills.length > 0 && (
              <div style={{ textAlign: 'center', margin: '20px 0' }}>
                <button
                  onClick={handleViewMore}
                  style={{
                    backgroundColor: "#f15b43",
                    color: "#fff",
                    border: "none",
                    padding: "10px 20px",
                    borderRadius: "4px",
                    cursor: "pointer",
                    transition: "background-color 0.3s ease",
                  }}
                  onMouseEnter={(e) => (e.target.style.backgroundColor = "#c04a35")}
                  onMouseLeave={(e) => (e.target.style.backgroundColor = "#f15b43")}
                >
                  View More
                </button>
              </div>
            )}
          </section>
        </main>
      )}
    </>
  );
};

export default SpecializedSkilledPersons;